import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef, ElementRef } from 'react';

type RadioRowContentElement = ElementRef<'div'>;
type RadioRowContentProps = ComponentPropsWithoutRef<'div'>;

const RadioRowContent = forwardRef<
  RadioRowContentElement,
  RadioRowContentProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <div
      {...props}
      className={clsx(
        'text-grey-700 p w-full space-y-4 bg-white px-6 pb-6 sm:px-10 sm:pb-10',
        props.className,
      )}
      ref={forwardedRef}
    >
      {children}
    </div>
  );
});

RadioRowContent.displayName = 'RadioRowContent';

export { RadioRowContent };
