import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
} from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';
import { FunctionComponent } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { CvcIcon } from '@pm/icons';
import {
  CreditCardFormFieldsProps,
  CreditCardFormProps,
  NewCreditCardFormWrapper,
} from './NewCreditCardFormWrapper';
import { StyledField } from '../StyledField';
import { Checkbox } from '../Checkbox';
import { SubmitButton } from '../SubmitButton';
import { TermsOfService } from '../TermsOfService/TermsOfService';

const isProd = import.meta.env.VITE_ENVIRONMENT === 'production';

const STRIPE_ELEMENT_STYLES = { style: { base: { fontSize: '16px' } } };

const stripePromise = loadStripe(
  isProd
    ? 'pk_live_51Jn9TVHmfhkgjy6M947UNfbwRPYh63dDjx5DLOknC3GCKhUGvsxp6KpOLKu7s6kM4Wo01RHQrloo9E9Di0xjRcR800hjn6N4rZ'
    : 'pk_test_51Jn9TVHmfhkgjy6Mk6qMPm5DVXQdmvryn1hZBp7UzWFlsFK3s8o86GFwJYiGqqcmg9wYzF480LJuLiEzU6sAzC0L00NQfXsWBk',
);

export const CreditCardForm: FunctionComponent<CreditCardFormProps> = (
  props,
) => {
  const { showDefaultCheckbox, ...wrapperProps } = props;
  return (
    <Elements stripe={stripePromise}>
      <NewCreditCardFormWrapper
        {...wrapperProps}
        renderForm={(props: CreditCardFormFieldsProps) => (
          <CreditCardFormFields
            {...props}
            showDefaultCheckbox={showDefaultCheckbox}
          />
        )}
      ></NewCreditCardFormWrapper>
    </Elements>
  );
};

const CreditCardFormFields = ({
  handleChange,
  cardErrorMessage,
  cardNumberValid,
  cardExpiryValid,
  cardCvcValid,
  formSubmitted,
  isProvider,
  showDefaultCheckbox,
}: CreditCardFormFieldsProps & { showDefaultCheckbox?: boolean }) => {
  const { t } = useTranslation('payment-methods', {
    keyPrefix: 'Stripe',
  });

  return (
    <div className="space-y-5">
      <div className="space-y-3">
        <div>
          <label className="text-xs font-medium">
            {t('FieldLabels.CardNumber')}
          </label>
          <div className="relative mt-1">
            <CardNumberElement
              options={STRIPE_ELEMENT_STYLES}
              className="rounded-lg bg-white border p-3 leading-6"
              onChange={handleChange}
            />
          </div>
        </div>

        <div className="flex gap-4">
          <div className="flex-1 space-y-1">
            <label className="mb-0 text-xs font-medium">
              {t('FieldLabels.Expiration')}
            </label>
            <CardExpiryElement
              options={STRIPE_ELEMENT_STYLES}
              className="w-full rounded-lg border bg-white p-3 leading-6"
              onChange={handleChange}
            />
          </div>
          <div className="flex-1 space-y-1">
            <label htmlFor="cardCVC" className="mb-0 text-xs font-medium">
              {t('FieldLabels.CVC')}
            </label>

            <div className="relative flex">
              <CardCvcElement
                options={STRIPE_ELEMENT_STYLES}
                className="w-full rounded-lg border bg-white p-3 leading-6"
                onChange={handleChange}
              />
              <CvcIcon className="absolute right-3 self-center" />
            </div>
          </div>
        </div>

        {cardErrorMessage && (
          <p className="text-critical-dark mb-2 text-sm">{cardErrorMessage}</p>
        )}

        <div className="space-y-1">
          <label htmlFor="postalCode" className="text-xs font-medium">
            {t('FieldLabels.PostalCode')}
          </label>
          <StyledField
            id="postalCode"
            name="postalCode"
            borderVariant="light"
            className="p-3 leading-6 text-base"
            inputStyle=""
            placeholder={t('FieldLabels.PostalCode')}
          />
        </div>
      </div>

      {showDefaultCheckbox && (
        <Checkbox name="isDefault" className="ml-3">
          {t('FieldLabels.SetAsDefault')}
        </Checkbox>
      )}

      <SubmitButton
        disabled={
          !cardNumberValid || !cardExpiryValid || !cardCvcValid || formSubmitted
        }
      >
        {isProvider
          ? t('FieldLabels.ProviderSubmitButton')
          : t('FieldLabels.Save')}
      </SubmitButton>

      {!isProvider && (
        <TermsOfService
          link="https://talkwithfrida.com/terms-of-use"
          service="Stripe"
        />
      )}
    </div>
  );
};
