// https://github.com/rails/rails/blob/main/activestorage/app/javascript/activestorage/direct_upload.js
import { FileChecksum } from './file_checksum';
import { BlobRecord } from './blob_record';
import { BlobUpload } from './blob_upload';

let id = 0;

export class DirectUpload {
  id: number;
  file: File;
  url: string;
  delegate: $TSFixMe;
  customHeaders: object;

  constructor(file: File, url: string, delegate: $TSFixMe, customHeaders = {}) {
    this.id = ++id;
    this.file = file;
    this.url = url;
    this.delegate = delegate;
    this.customHeaders = customHeaders;
  }

  create(callback: (error: Error | null, blob?: $TSFixMe) => void) {
    FileChecksum.create(this.file, (error: Error, checksum: string) => {
      if (error) {
        callback(error);
        return;
      }

      const blob = new BlobRecord(
        this.file,
        checksum,
        this.url,
        this.customHeaders,
      );
      notify(this.delegate, 'directUploadWillCreateBlobWithXHR', blob.xhr);

      blob.create((error: Error) => {
        if (error) {
          callback(error);
        } else {
          const upload = new BlobUpload(blob);
          notify(this.delegate, 'directUploadWillStoreFileWithXHR', upload.xhr);
          upload.create((error: Error) => {
            if (error) {
              callback(error);
            } else {
              callback(null, blob.toJSON());
            }
          });
        }
      });
    });
  }
}

function notify(object: $TSFixMe, methodName: string, ...messages: $TSFixMe) {
  if (object && typeof object[methodName] == 'function') {
    return object[methodName](...messages);
  }
}
