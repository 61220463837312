import {
  FunctionComponent,
  ChangeEvent,
  useState,
  useEffect,
  ReactNode,
  useId,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import {
  getDay,
  getMonth,
  getYear,
  makeDateString,
} from '../../utilities/dateUtilities';
import { StyledInput } from '../forms/StyledInput';

interface FormDate {
  day: string;
  month: string;
  year: string;
}

export interface InputProps {
  name: string;
  value: string;
  placeholder: string;
  showError?: boolean;
  label?: ReactNode;
  maxLength?: number;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  subtext?: string;
  required?: boolean;
}

export const Input: FunctionComponent<InputProps> = ({
  name,
  value,
  placeholder,
  label,
  maxLength,
  onChange,
  showError = false,
  subtext,
  required = false,
}) => {
  const id = useId();

  return (
    <div className="flex-grow space-y-2">
      {label && (
        <label htmlFor={id} className="text-grey-500 text-sm font-medium">
          {label}
        </label>
      )}
      <StyledInput
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        type="text"
        maxLength={maxLength}
        inputMode="numeric"
        onChange={onChange}
        required={required}
        aria-invalid={showError ? true : undefined}
        aria-label={name}
        data-testid={name}
      />
      {subtext && (
        <div className="text-grey-500 text-xs font-medium">{subtext}</div>
      )}
    </div>
  );
};

export interface Props {
  name: string;
  label?: string;
  initialDate?: string | null;
  monthFormat?: string;
  fieldLabels?: boolean;
  dayHint?: string;
  monthHint?: string;
  yearHint?: string;
  showSubtextHint?: boolean;
  bottomMargin?: boolean;
  required?: boolean;
}

const DEFAULT_FORMAT = {
  day: 'DD',
  month: 'MM',
  year: 'YYYY',
};

export const HookFormDatepicker: FunctionComponent<Props> = ({
  name,
  label,
  initialDate,
  monthFormat = null,
  fieldLabels = false,
  dayHint,
  monthHint,
  yearHint,
  showSubtextHint = false,
  bottomMargin = false,
  required = false,
}) => {
  const { t } = useTranslation('global');
  const { setValue, formState, getFieldState } = useFormContext();
  const initialDateState =
    initialDate || (formState.defaultValues && formState.defaultValues[name]);
  const inputFormats = {
    ...DEFAULT_FORMAT,
    month: monthFormat || DEFAULT_FORMAT.month,
  };

  const formDate: FormDate = {
    month: initialDateState?.length
      ? getMonth(initialDateState, monthFormat)
      : '',
    day: initialDateState?.length ? getDay(initialDateState) : '',
    year: initialDateState?.length ? getYear(initialDateState) : '',
  };

  const [date, setDate] = useState(formDate);
  const { error } = getFieldState(name, formState);
  const showError = error !== undefined;

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxLength =
      inputFormats[event.target.name as keyof typeof inputFormats].length;

    setDate((date) => ({
      ...date,
      [event.target.name]: event.target.value.substring(0, maxLength),
    }));
  };

  useEffect(() => {
    // TODO: We may want to set the date value to the same type of object, as
    // below it will either set to a Date() type or FormDate object. This would
    // mean that date input validation would be done in this component instead
    // of the parent components.
    if (
      date.day &&
      date.month &&
      date.year?.length === inputFormats.year.length &&
      Number(date.year)
    ) {
      const newDate = new Date(
        `${makeDateString(
          date.year,
          date.month,
          date.day,
          monthFormat || undefined,
        )}`.replace(/-/g, '/'),
      );
      const newDateDay = newDate.getDate();
      const isInvalid = newDateDay !== Number(date.day);

      const saveDate = isInvalid ? new Date('') : newDate;

      setValue(name, saveDate, { shouldTouch: true, shouldValidate: true });
    } else {
      setValue(name, date);
    }
  }, [date, setValue, name, monthFormat, inputFormats.year.length]);

  return (
    <fieldset className="space-y-3">
      {label && (
        <legend
          className={clsx('text-grey-500 text-sm font-medium', {
            "after:ml-1 after:text-red-500 after:content-['*']": required,
          })}
        >
          {label}
        </legend>
      )}
      <div className="grid grid-cols-3 grid-rows-1 place-content-center gap-4">
        <Input
          name="month"
          value={date.month}
          label={fieldLabels ? t('Month') : ''}
          maxLength={inputFormats.month.length}
          placeholder={monthHint ? monthHint : inputFormats.month}
          showError={showError}
          onChange={handleChange}
          subtext={showSubtextHint ? monthHint : ''}
          required={required}
        />
        <Input
          name="day"
          value={date.day}
          label={fieldLabels ? t('Day') : ''}
          maxLength={inputFormats.day.length}
          placeholder={inputFormats.day}
          showError={showError}
          onChange={handleChange}
          subtext={showSubtextHint ? dayHint : ''}
          required={required}
        />
        <Input
          name="year"
          value={date.year}
          label={fieldLabels ? t('Year') : ''}
          maxLength={inputFormats.year.length}
          placeholder={inputFormats.year}
          showError={showError}
          onChange={handleChange}
          subtext={showSubtextHint ? yearHint : ''}
          required={required}
        />
        {showError && (
          <p
            className="col-span-3 text-sm font-medium text-red-500"
            data-testid="error-message"
          >
            {error?.message}
          </p>
        )}
      </div>
      {bottomMargin && <div className="mb-4"></div>}
    </fieldset>
  );
};
