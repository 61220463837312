import { forwardRef, type ElementRef, type ReactNode } from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Accordion from '@radix-ui/react-accordion';

export type AccordionItemProps = {
  children: ReactNode;
  title?: string;
  value: string;
};

type AccordionItemContentProps = {
  children: ReactNode;
};

type AccordionTriggerElement = ElementRef<typeof Accordion.Trigger>;

type AccordionContentElement = ElementRef<typeof Accordion.Content>;
type AccordionContentProps = Accordion.AccordionContentProps;

const AccordionContent = forwardRef<
  AccordionContentElement,
  AccordionContentProps
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <Accordion.Content
      className="text-grey-700 space-y-4 pt-2"
      {...props}
      ref={forwardedRef}
    >
      {children}
    </Accordion.Content>
  );
});

AccordionContent.displayName = 'AccordionContent';

const AccordionTrigger = forwardRef<
  AccordionTriggerElement,
  Accordion.AccordionTriggerProps
>(({ children, className, ...props }, forwardedRef) => (
  <Accordion.Header>
    <Accordion.Trigger
      className="text-grey-700 group flex w-full justify-between text-start font-medium leading-normal"
      {...props}
      ref={forwardedRef}
    >
      {children}
      <ChevronDownIcon
        className="group-rdx-state-open:rotate-180 duration-50 h-6 w-6 transition-transform"
        aria-hidden
      />
    </Accordion.Trigger>
  </Accordion.Header>
));

AccordionTrigger.displayName = 'AccordionTrigger';

export const AccordionItem = ({
  children,
  title,
  value,
}: AccordionItemProps) => {
  return (
    <Accordion.Item value={value}>
      <AccordionTrigger>{title}</AccordionTrigger>
      <AccordionItemContent>
        <AccordionContent>{children}</AccordionContent>
      </AccordionItemContent>
    </Accordion.Item>
  );
};

export const AccordionItemContent = ({
  children,
}: AccordionItemContentProps) => {
  return <div className="first:pt-0 last:pb-0">{children}</div>;
};

export const AccordionRoot = Accordion.Root;
