import { type QuestionnaireDateInputQuestion as GQLQuestionnaireDateInputQuestion } from '@pm/graphql';
import { HookFormDateInput } from '../Datepicker/HookFormDateInput';

type QuestionnaireDateInputQuestionProps = GQLQuestionnaireDateInputQuestion;

export const QuestionnaireDateInputQuestion = ({
  title,
  description,
  id,
}: QuestionnaireDateInputQuestionProps) => (
  <HookFormDateInput
    label={title ?? ''}
    description={description ?? ''}
    name={id}
  />
);
