import {
  FunctionComponent,
  PropsWithChildren,
  RefObject,
  createRef,
  useState,
  useEffect,
  KeyboardEvent,
} from 'react';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import clsx from 'clsx';

export type VerificationCodeProps = {
  name: string;
  digits: number; // number of inputs fields
  // TODO: this would be cool:
  // autoSubmit?: boolean; // if true, submission will be triggered in the parent form when the last digit is populated & valid
};

export const VerificationCode: FunctionComponent<
  PropsWithChildren<VerificationCodeProps>
> = ({ name, digits }) => {
  const { formState, getFieldState, getValues, setValue } = useFormContext();
  const { error, isTouched } = getFieldState(name, formState);
  const [refs] = useState<Array<RefObject<HTMLInputElement>>>(
    Array.from(Array(digits).keys()).map(() => createRef()),
  );

  useEffect(() => {
    // autofocus to first field
    refs[0]?.current?.focus();
  }, [refs]);

  const updateCode = (digit: number, value: string) => {
    const newValue = getValues(name).split('');
    newValue[digit] = value;
    setValue(name, newValue.join(''), {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  const onKeyDown = (digit: number, e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Backspace') {
      e.currentTarget.value = '';
      updateCode(digit, e.currentTarget.value);

      const sibling = e.currentTarget.previousElementSibling;
      if (sibling && sibling instanceof HTMLInputElement) {
        sibling.focus();
      }
    }
  };

  // https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  const gridStyle = [
    'grid-cols-none',
    'grid-cols-1',
    'grid-cols-2',
    'grid-cols-3',
    'grid-cols-4',
    'grid-cols-5',
    'grid-cols-6',
    'grid-cols-7',
    'grid-cols-8',
    'grid-cols-9',
    'grid-cols-10',
    'grid-cols-11',
    'grid-cols-12',
  ];

  return (
    <div>
      <div className={`grid gap-1 ${gridStyle[digits]}`}>
        {Array.from(Array(digits).keys()).map((digit) => (
          <input
            key={digit}
            type="number"
            ref={refs[digit]}
            min={0}
            max={9}
            onKeyDown={(e) => onKeyDown(digit, e)}
            onChange={(e) => {
              const regex = /^\d+\.?\d*$/;
              const numStr = e.target.value;

              if (numStr.length <= 1) {
                if (numStr.length === 1) {
                  if (regex.test(numStr)) {
                    updateCode(digit, numStr);

                    refs[digit + 1]?.current?.focus();
                  }
                }
              } else {
                e.target.value = e.target.value.slice(0, 1);
              }
            }}
            className={clsx(
              'border-grey-500 h-24 rounded-md border p-0 text-center text-5xl focus:ring focus:ring-opacity-50',
              error && isTouched
                ? 'border-critical-dark text-critical-dark bg-red-50'
                : 'border-grey-300 bg-grey-50',
            )}
          />
        ))}
      </div>
      <ErrorMessage
        name={name}
        as="p"
        className="text-critical-dark mt-3 text-sm"
      />
    </div>
  );
};
