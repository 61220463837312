import { type QuestionnaireTextAreaQuestion as GQLQuestionnaireTextAreaQuestion } from '@pm/graphql';
import { CardText } from '../CardText';
import { HookFormTextArea } from '../StyledField/HookFormTextArea';
import { QuestionnaireSection } from './QuestionnaireSection';
import { QuestionnaireMarkdown } from '../QuestionnaireMarkdown/QuestionnaireMarkdown';

type QuestionnaireTextAreaQuestionProps = GQLQuestionnaireTextAreaQuestion;

export const QuestionnaireTextAreaQuestion = ({
  id,
  title,
  description,
  maxLength,
  supportingText,
}: QuestionnaireTextAreaQuestionProps) => (
  <QuestionnaireSection>
    <CardText className="text-grey-900 text-xl">{title}</CardText>
    {description && (
      <CardText>
        <QuestionnaireMarkdown>{description}</QuestionnaireMarkdown>
      </CardText>
    )}
    <HookFormTextArea
      name={id}
      characterCount={maxLength}
      supportingText={supportingText}
    />
  </QuestionnaireSection>
);
