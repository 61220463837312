import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeading,
  useToggle,
} from '@pm/core';
import React, { useRef } from 'react';

type ConfirmHandlers = {
  confirm: () => Promise<boolean>;
};

type ConfirmationProps = {
  ariaLabel: string;
  headingText: string;
  bodyText: string;
  affirmText: string;
  cancelText?: string;
  children: ({ confirm }: ConfirmHandlers) => React.ReactNode;
};

/**
 * Wrapper for components that require confirmation with a modal before proceeding.
 *
 * @example
 * <ConfirmWithModal>
 *   {({ confirm }) => (
 *     <form>
 *      onSubmit={async (formValues) => {
 *        const confirmed = await confirm();
 *
 *        if (confirmed) {
 *          handleSubmit();
 *        } else {
 *          handleCancel();
 *        }
 *      }}
 *    </form>
 *   )}
 * </ConfirmWithModal>
 **/
export const ConfirmWithModal = ({
  ariaLabel,
  headingText,
  bodyText,
  affirmText,
  cancelText,
  children,
}: ConfirmationProps) => {
  const [showConfirmationModal, handlers] = useToggle();
  const resolveConfirmationPromise = useRef<(value: boolean) => void>();
  const confirmPromise = useRef<Promise<boolean>>();

  const confirm = async () => {
    handlers.on();
    confirmPromise.current = new Promise<boolean>((resolve) => {
      resolveConfirmationPromise.current = resolve;
    });

    return await confirmPromise.current;
  };

  const handleConfirm = () => {
    resolveConfirmationPromise.current?.(true);
    handlers.off();
  };

  const handleClose = () => {
    resolveConfirmationPromise.current?.(false);
    confirmPromise.current = undefined;
    handlers.off();
  };

  return (
    <>
      {children({ confirm })}
      <Modal
        isOpen={showConfirmationModal}
        onDismiss={handleClose}
        ariaLabel={ariaLabel}
        narrow
      >
        <ModalHeading onClose={handleClose}>{headingText}</ModalHeading>
        <ModalBody>{bodyText}</ModalBody>
        <ModalFooter>
          {cancelText && (
            <Button variant="secondary" onClick={handleClose}>
              {cancelText}
            </Button>
          )}
          <Button onClick={handleConfirm}>{affirmText}</Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

ConfirmWithModal.displayName = 'ConfirmWithModal';
