import { createContext, useContext, useState, useCallback } from 'react';
import {
  type BannerIntentType,
  Banner as BannerComponent,
} from '../../components/Banner/Banner';

type Banner = {
  id: string;
  content: string;
  actions?: React.ReactNode;
};

type BannerContextType = {
  banners: Banner[];
  info: ({ ...args }: AddBannerArgs) => void;
};

type AddBannerArgs = Omit<Banner, 'id'>;

type BannerWrapperProps = {
  children: React.ReactNode;
};

const BannerContext = createContext<BannerContextType | undefined>(undefined);

type BannerContainerProps = {
  banners: Banner[];
};

// this container should be placed in the app wherever we
// want the banners to be rendered (probably layout component)
export const BannerContainer = ({ banners }: BannerContainerProps) => {
  return (
    <div>
      {banners.map(({ id, ...args }) => (
        <BannerComponent key={id} {...args} />
      ))}
    </div>
  );
};

export const BannerWrapper = ({ children }: BannerWrapperProps) => {
  const [banners, setBanners] = useState<Banner[]>([]);

  const addBanner = useCallback(
    ({ ...args }: AddBannerArgs & { intent: BannerIntentType }) => {
      const id = crypto.randomUUID();
      setBanners((prevBanners) => [...prevBanners, { ...args, id }]);
    },
    [setBanners],
  );

  // figure out how to pass this callback down to the banner itself
  // so the X icon can trigger it:
  // const removeBanner = useCallback(
  //   (id: string) =>
  //     setBanners((prevBanners) =>
  //       prevBanners.filter((banner: Banner) => banner.id !== id),
  //     ),
  //   [setBanners],
  // );

  const info = ({ ...args }: AddBannerArgs) => {
    addBanner({ ...args, intent: 'info' });
  };

  return (
    <BannerContext.Provider value={{ banners, info }}>
      {children}
    </BannerContext.Provider>
  );
};

export const useBanner = () => {
  const context = useContext(BannerContext);

  if (!context) {
    throw new Error('useBanner must be used within a BannerWrapper');
  }

  return context;
};
