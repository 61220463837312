import { useLDClient } from '@mikecousins/launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { browserName, deviceType } from 'react-device-detect';
import { useAuth } from '../context/auth/hooks';

export const LDUserLocation = () => {
  const location = useLocation();
  const client = useLDClient();
  const { isLoggedIn } = useAuth();
  const segmentUserId =
    localStorage.getItem('ajs_anonymous_id')?.replaceAll('"', '') ||
    localStorage.getItem('ajs_user_id')?.replaceAll('"', '') ||
    '';

  // update LaunchDarkly user attributes when location changes
  useEffect(() => {
    if (client) {
      const currentUserKey = sessionStorage.getItem('USER_ID');
      const userPayload = {
        location: window.location.href,
        browserName: browserName,
        deviceType: deviceType,
        province: localStorage.getItem('PROVINCE') || '',
        hasReceivedRX: sessionStorage.getItem('HAS_RECEIVED_RX') || '',
        segmentId: segmentUserId,
      };

      if (isLoggedIn && currentUserKey) {
        const updatedUser = {
          key: currentUserKey,
          custom: userPayload,
        };
        client.identify(updatedUser);
      } else {
        const updatedUser = client.getContext();
        updatedUser.custom = userPayload;
        client.identify(updatedUser);
      }
    }
  }, [client, location, isLoggedIn, segmentUserId]);

  return null;
};
