import ReactMarkdown from 'react-markdown';
import { useMarkdownComponents } from '../../hooks/use-markdown-components/use-markdown-components';

type QuestionnaireMarkdownProps = {
  children: string;
};

export const QuestionnaireMarkdown = ({
  children,
}: QuestionnaireMarkdownProps) => {
  const { p, ul, a, em } = useMarkdownComponents({ variant: 'subdued' });

  return (
    <ReactMarkdown
      components={{
        p,
        em,
        ul,
        a,
      }}
    >
      {children}
    </ReactMarkdown>
  );
};
