import { type QuestionnaireNumberInputQuestion as GQLQuestionnaireTextQuestion } from '@pm/graphql';
import { CardText } from '../CardText';
import { HookFormFlowbiteField } from '../StyledField/HookFormFlowbiteField';
import { QuestionnaireSection } from './QuestionnaireSection';
import { QuestionnaireMarkdown } from '../QuestionnaireMarkdown/QuestionnaireMarkdown';

type QuestionnaireNumberInputQuestionProps = GQLQuestionnaireTextQuestion;

export const QuestionnaireNumberInputQuestion = (
  question: QuestionnaireNumberInputQuestionProps,
) => (
  <QuestionnaireSection>
    <CardText className="text-grey-900 text-xl">{question.title}</CardText>
    {question.description && (
      <CardText>
        <QuestionnaireMarkdown>{question.description}</QuestionnaireMarkdown>
      </CardText>
    )}
    <HookFormFlowbiteField name={question.id} bottomMargin={false} />
  </QuestionnaireSection>
);
