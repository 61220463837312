import { LoadingSpinner } from '@pm/core';
import * as Dialog from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';
import { type PropsWithChildren } from 'react';
import { useSpinDelay } from 'spin-delay';
import { twMerge } from 'tailwind-merge';
import { useIsMobile } from '../../hooks/useIsMobile';
import { button } from '../Button/Button';
import { Icon } from '../Icon/Icon';

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/

type ModalProps = Dialog.DialogProps;

/**
 * @example
 * <Modal.Root open={open} onOpenChange={setOpen}>
    <Modal.Trigger>
      <Button>Edit profile</Button>
    </Modal.Trigger>
    <Modal.Content title="Your profile">
      <Modal.Body>
        { ... }
      </Modal.Body>
      <Modal.Actions>
        <Button intent="secondary" onClick={() => setOpen(false)}>Cancel</Button>
        <Button onClick={() => setOpen(false)}>Confirm</Button>
      </Modal.Actions>
    </Modal.Content>
  </Modal.Root>
 */
const Modal = ({ open, onOpenChange, children }: ModalProps) => {
  return (
    <Dialog.Root open={open} onOpenChange={onOpenChange} aria-hidden={true}>
      {children}
    </Dialog.Root>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Trigger
 * -----------------------------------------------------------------------------------------------*/

type ModalTriggerProps = Dialog.DialogTriggerProps & { ariaLabel?: string };

const ModalTrigger = ({ children, ariaLabel }: ModalTriggerProps) => {
  return (
    <Dialog.Trigger asChild aria-label={ariaLabel ?? 'Open Modal'}>
      {children}
    </Dialog.Trigger>
  );
};

const LoadingOverlay = () => {
  return (
    <div className="fixed inset-0 items-center flex justify-center bg-overlay/40 text-primary">
      <LoadingSpinner size="3x" />
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Content
 * -----------------------------------------------------------------------------------------------*/

const modalContent = cva(
  'flex flex-col bg-surface rounded-m outline-none my-auto fixed inset-0 z-50 h-fit max-h-max box-border',
  {
    variants: {
      screenSize: {
        mobile: 'mx-l',
        desktop: 'w-[640px] mx-auto',
      },
    },
  },
);

type ModalContentProps = Dialog.DialogContentProps &
  Omit<VariantProps<typeof modalContent>, 'screenSize'> & {
    title: string;
    loading?: boolean;
    ariaLabel?: string;
    showXinDialog?: boolean;
    ariaDescribedBy?: string;
  };

const ModalContent = ({
  title,
  ariaLabel,
  children,
  ariaDescribedBy,
  loading = false,
  showXinDialog = true,
}: ModalContentProps) => {
  const showLoadingIndicator = useSpinDelay(loading, {
    delay: 300,
    minDuration: 250,
    ssr: false,
  });
  const { isMobile } = useIsMobile();

  return (
    <Dialog.Portal>
      <Dialog.Overlay
        aria-label="Modal Overlay"
        className="fixed inset-0 bg-overlay/40"
      />
      <Dialog.Content
        aria-modal
        onInteractOutside={(event) => event.preventDefault()}
        aria-describedby={ariaDescribedBy ?? undefined}
        className={modalContent({
          screenSize: isMobile ? 'mobile' : 'desktop',
        })}
      >
        {showLoadingIndicator && <LoadingOverlay />}
        <div className="flex items-center justify-between p-m">
          <Dialog.Title
            className="text-heading-s"
            aria-label={ariaLabel ?? 'Modal Title'}
          >
            {title}
          </Dialog.Title>
          {showXinDialog && (
            <Dialog.Close
              aria-label="Close Modal"
              className={button({ intent: 'ghost', size: 'small' })}
            >
              <Icon name="close" size="large" />
            </Dialog.Close>
          )}
        </div>
        <div className="overflow-hidden flex flex-col">{children}</div>
      </Dialog.Content>
    </Dialog.Portal>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Body
 * -----------------------------------------------------------------------------------------------*/

const modalBody = cva('border-t p-m max-h-[80vh] overflow-y-auto');

const ModalBody = ({ children }: PropsWithChildren<{}>) => {
  return <div className={modalBody()}>{children}</div>;
};

/* -------------------------------------------------------------------------------------------------
 * Actions
 * -----------------------------------------------------------------------------------------------*/
const modalActions = cva('border-t p-m box-border flex justify-end');

type ModalActionsProps = PropsWithChildren<{
  ariaLabel?: string;
}>;

const ModalActions = ({ children }: ModalActionsProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div className={modalActions()}>
      <div
        className={twMerge(
          'flex gap-x-m',
          isMobile ? 'w-full flex-col' : 'w-1/2',
        )}
      >
        {children}
      </div>
    </div>
  );
};

export {
  ModalActions as Actions,
  ModalBody as Body,
  ModalContent as Content,
  Modal as Root,
  ModalTrigger as Trigger,
  type ModalProps,
};
