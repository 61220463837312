import { PaymentMethodBrandsTypeEnum } from '@pm/graphql';

import {
  AmexIcon,
  MastercardIcon,
  VisaIcon,
  DiscoverIcon,
  JcbIcon,
  UnionPayIcon,
  DinersClubIcon,
  CardIcon,
} from '@pm/icons';

type StripeCreditCardIconProps = {
  brandType: PaymentMethodBrandsTypeEnum | null;
};

export const StripeCreditCardIcon = ({
  brandType,
}: StripeCreditCardIconProps) => {
  switch (brandType) {
    case PaymentMethodBrandsTypeEnum.Amex:
      return <AmexIcon />;
    case PaymentMethodBrandsTypeEnum.Diners:
      return <DinersClubIcon />;
    case PaymentMethodBrandsTypeEnum.Discover:
      return <DiscoverIcon />;
    case PaymentMethodBrandsTypeEnum.Jcb:
      return <JcbIcon />;
    case PaymentMethodBrandsTypeEnum.Mastercard:
      return <MastercardIcon />;
    case PaymentMethodBrandsTypeEnum.Unionpay:
      return <UnionPayIcon />;
    case PaymentMethodBrandsTypeEnum.Visa:
      return <VisaIcon />;
    case PaymentMethodBrandsTypeEnum.Unknown:
      return <CardIcon />;
    default:
      return <CardIcon />;
  }
};
