import { AgreementClientPartsFragment } from '@pm/graphql';
import { AgreementFragments } from './agreement.constants';

export function isClientAgreement(
  agreement?: AgreementFragments,
): agreement is AgreementClientPartsFragment {
  return agreement?.__typename === 'AgreementClient';
}

export function isFreddieAsyncAgreement(
  agreement?: AgreementFragments,
): boolean {
  return agreement?.__typename === 'AgreementFreddieAsync';
}

export function isFreddieDoxyPepAsyncAgreement(agreement?: AgreementFragments) {
  return agreement?.__typename === 'AgreementFreddieDoxyPepAsync';
}

export function getMostRecentAgreementVersion(
  agreements: AgreementFragments[],
): string | null {
  if (agreements.length === 0) {
    return null;
  }

  const sortedAgreements = agreements.sort((a, b) =>
    b.version.localeCompare(a.version),
  );

  return sortedAgreements[0].version;
}

/**
 * Ensures agreements are sorted in the order they should be displayed.
 */
export function toSortedAgreements(
  agreements: AgreementFragments[],
): AgreementFragments[] {
  const agreementOrder: AgreementFragments['__typename'][] = [
    'AgreementClient',
    'AgreementPrivacy',
    'AgreementTermsOfUse',
    'AgreementFreddieAsync',
  ];

  const clonedAgreements = [...agreements];
  return clonedAgreements.sort((a, b) => {
    const aIndex = agreementOrder.indexOf(a.__typename);
    const bIndex = agreementOrder.indexOf(b.__typename);

    return aIndex - bIndex;
  });
}
