import clsx from 'clsx';
import { HTMLAttributeAnchorTarget, ReactNode } from 'react';
import { Link, To } from 'react-router-dom';
import { ButtonVariantProps, buttonClasses } from '../Button/Button.styles';
import { ButtonContent } from '../Button/ButtonContent';
import { composeExternalLinkAttributes } from '../../utilities/dom';

type BaseProps<
  Target extends HTMLAttributeAnchorTarget = HTMLAttributeAnchorTarget,
> = ButtonVariantProps & {
  children?: ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  external?: boolean;
  target?: Target;
  state?: unknown;
  wide?: boolean;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
};

type ExternalLinkProps<
  Target extends HTMLAttributeAnchorTarget = HTMLAttributeAnchorTarget,
> = BaseProps<Target> & {
  to: string | undefined;
  external: true;
  rel?: string;
};

type InternalLinkProps<
  Target extends HTMLAttributeAnchorTarget = HTMLAttributeAnchorTarget,
> = BaseProps<Target> & {
  to: To;
  external?: never;
  rel?: never;
};

type ButtonLinkProps<
  Target extends HTMLAttributeAnchorTarget = HTMLAttributeAnchorTarget,
> = InternalLinkProps<Target> | ExternalLinkProps<Target>;

export const ButtonLink = <
  Target extends HTMLAttributeAnchorTarget = HTMLAttributeAnchorTarget,
>({
  children,
  to,
  onClick,
  disabled = false,
  external,
  leftIcon,
  rightIcon,
  state,
  ...props
}: ButtonLinkProps<Target>) => {
  const contentProps = { rightIcon, leftIcon, children };
  const classes = clsx(buttonClasses(props), 'cursor-pointer');

  const { rel, target } = composeExternalLinkAttributes({
    external,
    ...props,
  });

  const sharedProps = {
    className: classes,
    target,
    rel,
    onClick: !disabled
      ? onClick
      : (e: React.MouseEvent<HTMLElement>) => e.preventDefault(),
    'aria-disabled': disabled || to === undefined,
    'data-variant': props.variant,
  } as const;

  return (
    <>
      {!external ? (
        <Link {...sharedProps} to={to} state={state}>
          <ButtonContent {...contentProps} />
        </Link>
      ) : (
        <a {...sharedProps} href={to}>
          <ButtonContent {...contentProps} />
        </a>
      )}
    </>
  );
};
