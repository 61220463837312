import { Trans } from 'react-i18next';

type TermsOfServiceProps = {
  link: string;
  service: 'Stripe' | 'Affirm';
};

export const TermsOfService = ({ service, link }: TermsOfServiceProps) => {
  return (
    <div className="text-grey-500 text-xs">
      {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
      {/* @ts-ignore */}
      <Trans i18nKey={`${service}.TermsOfService`} ns={'payment-methods'}>
        <a
          href={link}
          target="_blank"
          rel="noreferrer"
          className="text-brand-primary-500 underline"
        >
          {' '}
        </a>
      </Trans>
    </div>
  );
};
