/// <reference types="tsconfig/segment" />
import { JSONValue } from './types';
import { GtagHandler } from './utils/gtag';

type EventProperties = Record<string, JSONValue>;

const gtagHandler = new GtagHandler(import.meta.env.VITE_GA_MEASUREMENT_ID);
export const GA_INTEGRATION_ID = 'Google Analytics 4';

/**
 * Used for tracking events in analytics.
 * @param eventName Name of the event to be submitted to analytics
 * @param properties Properties to be submitted with the event
 *
 * @note No need to pass a userId property as it is captured automatically by the analytics library when we identify
 * the user
 */
export function trackAnalyticsEvent(
  eventName: string,
  properties?: EventProperties,
) {
  if (!window.analytics) {
    return;
  }

  const {
    client_id: clientId,
    session_id: sessionId,
    session_number: sessionNumber,
  } = gtagHandler.gtagAttributes;

  window.analytics.track(
    eventName,
    {
      ...properties,
      clientId,
    },
    {
      integrations: {
        [GA_INTEGRATION_ID]: {
          clientId,
          sessionId,
          sessionNumber,
        },
      },
    },
  );
}
