import { type VariantProps, cva } from 'class-variance-authority';
import { ComponentProps, useId } from 'react';

const checkboxGroup = cva('', {
  variants: {
    space: {
      xxs: 'space-y-xxs',
      s: 'space-y-s',
    },
  },
  defaultVariants: {
    space: 's',
  },
});

type CombinedProps = VariantProps<typeof checkboxGroup> &
  Omit<ComponentProps<'fieldset'>, 'className'>;

type CheckboxGroupProps = CombinedProps & {
  label?: React.ReactNode;
  children: React.ReactNode;
};

export const CheckboxGroup = ({
  label,
  children,
  space = 's',
  ...restProps
}: CheckboxGroupProps) => {
  const generatedLabelId = useId();
  const labelId = label ? generatedLabelId : undefined;

  return (
    <fieldset
      {...restProps}
      className={checkboxGroup({ space })}
      aria-labelledby={labelId ?? restProps['aria-labelledby']}
    >
      {label && <legend id={labelId}>{label}</legend>}
      {children}
    </fieldset>
  );
};
