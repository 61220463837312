import { FunctionComponent } from 'react';
import { ArrowLeftIcon } from '@heroicons/react/20/solid';
import clsx from 'clsx';
import { Link, To } from 'react-router-dom';

type ButtonProps = {
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
  lastPath?: never;
};

type LinkProps = {
  lastPath?: To;
  onClick?: never;
  disabled?: never;
  className?: string;
};

type Props = ButtonProps | LinkProps;

export const BackArrow: FunctionComponent<Props> = ({
  className,
  disabled = false,
  onClick,
  lastPath: to,
}) => {
  const actionHandlerClass = clsx(
    'inline-block hover:cursor-pointer cursor-pointer self-start',
    className,
  );
  const iconClass = clsx('h-6 w-6 text-grey-700');

  const ariaLabel = 'Back Button';

  if (to) {
    return (
      <Link to={to} aria-label={ariaLabel} className={actionHandlerClass}>
        <ArrowLeftIcon className={iconClass} />
      </Link>
    );
  }

  return (
    <button
      className={actionHandlerClass}
      onClick={onClick}
      disabled={disabled}
      aria-label={ariaLabel}
      type="button"
    >
      <ArrowLeftIcon className={iconClass} />
    </button>
  );
};
