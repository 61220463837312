import { QuestionnaireQuestionFragment } from '@pm/graphql';
import { Fragment } from 'react';
import { QuestionnaireCheckboxQuestion } from './QuestionnaireCheckboxQuestion';
import { QuestionnaireDateInputQuestion } from './QuestionnaireDateInputQuestion';
import { QuestionnaireNumberInputQuestion } from './QuestionnaireNumberInputQuestion';
import { QuestionnaireRadioGroupQuestion } from './QuestionnaireRadioGroupQuestion';
import { QuestionnaireTextAreaQuestion } from './QuestionnaireTextAreaQuestion';
import { QuestionnaireTextInputQuestion } from './QuestionnaireTextInputQuestion';

const QUESTION_MAPPING: Partial<
  Record<
    NonNullable<QuestionnaireQuestionFragment['__typename']>,
    (props: $TSFixMe) => JSX.Element
  >
> = {
  QuestionnaireRadioQuestion: QuestionnaireRadioGroupQuestion,
  QuestionnaireTextInputQuestion: QuestionnaireTextInputQuestion,
  QuestionnaireNumberInputQuestion: QuestionnaireNumberInputQuestion,
  QuestionnaireTextAreaQuestion: QuestionnaireTextAreaQuestion,
  QuestionnaireDateInputQuestion: QuestionnaireDateInputQuestion,
  QuestionnaireCheckboxQuestion: QuestionnaireCheckboxQuestion,
};

interface QuestionsMapperProps {
  questions: QuestionnaireQuestionFragment[];
}

export const QuestionsMapper = ({ questions = [] }: QuestionsMapperProps) => {
  if (questions === null) {
    return null;
  }

  return (
    <Fragment>
      {questions.map((question) => {
        if (question === null || question.__typename === undefined) {
          return null;
        }

        const Component =
          QUESTION_MAPPING[
            question.__typename as keyof typeof QUESTION_MAPPING
          ];

        if (!Component) {
          return null;
        }

        return <Component key={question.id} {...question} />;
      })}
    </Fragment>
  );
};
