import { IconButton } from '../IconButton/IconButton';
import { cva, type VariantProps } from 'class-variance-authority';
import { type ReactElement } from 'react';
import { Icon } from '../Icon/Icon';
import { BannerIcon } from './BannerIcon';

export const variants = {
  intent: {
    info: 'bg-info-subtle',
    warning: 'bg-warning-subtle',
    negative: 'bg-negative-subtle',
  },
};

const banner = cva('pt-m pl-m flex min-h-[48px]', {
  variants,
  defaultVariants: { intent: 'info' },
});
const bannerIconWrapper = cva('h-full');
const bannerBody = cva('pl-m pr-xxs block mr-auto text-wrap text-body-s');
const bannerActions = cva('pl-xs flex items-start max-w-[44px]');

export type BannerIntentType = VariantProps<typeof banner>['intent'];

type BannerProps = VariantProps<typeof banner> & {
  content: string;
  iconNode?: ReactElement<typeof Icon>;
  onDismiss?: () => void;
  children?: ReactElement;
};

/**
 * A banner is a message that is displayed at the top of the page to provide
 * important information to the user. It can be dismissed by the user by default,
 * unless the intent is negative/critical.
 * 
 * If no onDismiss callback is provided, no dismissal button is shown.
 *
 * Usage:
```tsx
  <Banner
    intent="info"
    content="Please review your account"
    onDismiss={() => setShowBanner(false)}
  >
    <BannerAction text="Review account" callback={() => navigate('/account')} />
  </Banner>
```
 */
export const Banner = ({
  content,
  intent,
  iconNode,
  onDismiss,
  children,
}: BannerProps) => {
  const canDismiss = onDismiss && intent !== 'negative';

  return (
    <div className={banner({ intent })}>
      <div className={bannerIconWrapper()}>
        {iconNode ?? <BannerIcon intent={intent} />}
      </div>
      <div className="flex-col items-start">
        <span className={bannerBody()}>{content}</span>
        {canDismiss && (
          <IconButton
            intent="ghost"
            size="large"
            onClick={() => {
              onDismiss?.();
            }}
          >
            <Icon name="close" size="large" />
          </IconButton>
        )}
        <div className={bannerActions()}>{children}</div>
      </div>
    </div>
  );
};
