import {
  ApolloCache,
  DefaultContext,
  MutationTuple,
} from '@apollo/client/index.js';
import {
  CreatePasswordInput,
  CreatePasswordMutation,
  Exact,
  LoginInput,
  LoginMutation,
  RecoverPasswordInput,
  RecoverPasswordMutation,
  ResetPasswordInput,
  ResetPasswordMutation,
  SignupPatientInput,
  SignupPatientMutation,
} from '@pm/graphql';
import { TokenResponse } from '@react-oauth/google';
import { createContext, Dispatch, SetStateAction } from 'react';

type AuthContextType = {
  isLoggedIn: boolean;
  error: boolean;
  setError: Dispatch<SetStateAction<boolean>>;
  providerLogin: (oauth_response: TokenResponse) => void;
  logout: () => void;
  userId?: string;
  useLogin: () => MutationTuple<
    LoginMutation,
    Exact<{ input: LoginInput }>,
    DefaultContext,
    ApolloCache<unknown>
  >;
  useSignup: (
    login?: boolean,
  ) => MutationTuple<
    SignupPatientMutation,
    Exact<{ input: SignupPatientInput }>,
    DefaultContext,
    ApolloCache<unknown>
  >;
  useResetPassword: () => MutationTuple<
    ResetPasswordMutation,
    Exact<{ input: ResetPasswordInput }>,
    DefaultContext,
    ApolloCache<unknown>
  >;
  useRecoverPassword: () => MutationTuple<
    RecoverPasswordMutation,
    Exact<{ input: RecoverPasswordInput }>,
    DefaultContext,
    ApolloCache<unknown>
  >;
  useCreatePassword: () => MutationTuple<
    CreatePasswordMutation,
    Exact<{ input: CreatePasswordInput }>,
    DefaultContext,
    ApolloCache<unknown>
  >;
};

export const AuthContext = createContext<AuthContextType | undefined>(
  undefined,
);
