import { PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
};

export const ModalBody = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  const computedClassName = clsx(
    'space-y-6 py-4 px-6 text-base font-normal text-grey-700',
    className,
  );
  return <div className={computedClassName}>{children}</div>;
};
