import { ReactNode } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';

const inputLabel = cva('select-none text-label self-start');

type InputLabelProps = React.HTMLProps<HTMLLabelElement> &
  VariantProps<typeof inputLabel> & {
    children: ReactNode;
  };

export const InputLabel = ({ children, ...props }: InputLabelProps) => (
  <label {...props} className={inputLabel()}>
    {children}
  </label>
);
