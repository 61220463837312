import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import * as LabelPrimitive from '@radix-ui/react-label';
import {
  forwardRef,
  useId,
  type ComponentPropsWithoutRef,
  type ElementRef,
} from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../../Icon/Icon';
import { WithChildren } from '../../../types/component.types';

type CheckboxProps = Omit<ComponentPropsWithoutRef<'button'>, 'className'> & {
  label?: string;
  indeterminate?: boolean;
  defaultChecked?: boolean;
  onChange?: CheckboxPrimitive.CheckboxProps['onCheckedChange'];
} & Partial<WithChildren>;

export const Checkbox = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(
  (
    {
      id: idProp,
      children,
      label,
      indeterminate,
      defaultChecked,
      disabled,
      onChange,
      ...props
    },
    forwardedRef,
  ) => {
    const _id = useId();
    const id = idProp || _id;

    return (
      <div className="flex items-center">
        <div
          className={twMerge(
            'flex justify-center items-center py-xs px-xxs gap-xs min-h-[40px] min-w-[40px]',
            !disabled && 'rounded-circle hover:bg-secondary-hover',
          )}
        >
          <CheckboxPrimitive.Root
            {...props}
            id={id}
            defaultChecked={defaultChecked}
            onClick={disabled ? (e) => e.preventDefault() : props.onClick}
            onCheckedChange={(state) => onChange && onChange(state)}
            ref={forwardedRef}
            className={twMerge(
              'justify-center rounded-xs h-[18px] w-[18px] active:h-[24px] active:w-[24px] focus:ring focus:ring-focus',
              disabled
                ? 'bg-surface-disabled rdx-state-unchecked'
                : 'rdx-state-checked:bg-primary rdx-state-unchecked:bg-surface rdx-state-unchecked:border-2 rdx-state-unchecked:border-strong',
            )}
            aria-disabled={disabled}
          >
            <CheckboxPrimitive.Indicator
              className={twMerge(
                'w-l',
                disabled ? 'text-content-disabled' : 'text-surface',
              )}
            >
              <Icon name={indeterminate ? 'remove' : 'check'} size="medium" />
            </CheckboxPrimitive.Indicator>
          </CheckboxPrimitive.Root>
        </div>
        {label && !children && (
          <LabelPrimitive.Label
            htmlFor={id}
            className={twMerge('text-label', !disabled && 'cursor-pointer')}
          >
            {label}
          </LabelPrimitive.Label>
        )}
        {children}
      </div>
    );
  },
);
Checkbox.displayName = 'Checkbox';
