import { Icon } from '../Icon/Icon';
import { type ToastIntentType } from './Toast';

export const ToastIcon = ({ intent }: { intent: ToastIntentType }) => {
  switch (intent) {
    case 'info':
      return <Icon name="info" color="text-content-info" />;
    case 'positive':
      return <Icon name="check_circle" color="text-content-positive" />;
    case 'warning':
      return <Icon name="warning" color="text-content-warning" />;
    case 'negative':
      return <Icon name="cancel" color="text-content-negative" />;
  }
};
