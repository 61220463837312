import React from 'react';
import { UnstyledableDiv } from '../../types/component.types';
import { Button } from '../Button/Button';
import { Icon } from '../Icon/Icon';
import { twMerge } from 'tailwind-merge';
import { Content } from './SidePanelContent';
import { Text } from '../Text/Text';

type SidePanelHeaderElement = React.ElementRef<'div'>;
type SidePanelHeaderProps = UnstyledableDiv & {
  onClose: () => void;
};

/**
 * Wrapper for the header section of SidePanel.
 */
export const Header = React.forwardRef<
  SidePanelHeaderElement,
  SidePanelHeaderProps
>(({ children, onClose, ...props }, forwardedRef) => {
  return (
    <Content {...props} shouldFillContainer={false} ref={forwardedRef}>
      <div
        className={twMerge(
          'flex items-center w-full',
          children ? 'justify-between' : 'justify-end',
        )}
      >
        {children && (
          <Text as="h1" size="headingS">
            {children}
          </Text>
        )}
        <div className="ml-auto">
          <Button
            intent="ghost"
            size="small"
            onClick={onClose}
            aria-label="Close panel"
          >
            <Icon name="close" size="large" />
          </Button>
        </div>
      </div>
    </Content>
  );
});

Header.displayName = 'SidePanelHeader';
