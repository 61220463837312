// TODO: This component is almost an exact copy of `HookFormComboRadio`, except
// that it expects the field value to be an array of strings instead of a string.
// This was done to make work for the `QuestionnaireCheckboxQuestion` easier.
// Please use this component instead of `HookFormComboRadio`.
import { useEffect, useState, type ReactNode } from 'react';
import { useController } from 'react-hook-form';
import { ToggleButton } from '../ToggleButton';
import { StyledInput } from '../forms/StyledInput';

const NONE_OPTION_VALUE = 'none';

export type FormattedOption = {
  text: ReactNode;
  value: string;
  selected?: boolean;
};

export type ComboRadioFieldProps = {
  name: string;
  options: FormattedOption[];
  freeTextButton?: string;
  freeTextPlaceholder?: string;
  cols?: number;
};

const optionIsSelected = (option: string, selectedOptions: string[]) =>
  selectedOptions.includes(option);

const getFormattedOptions = (
  optionsToFormat: FormattedOption[],
  fieldValue: string[],
): FormattedOption[] => {
  return (optionsToFormat as FormattedOption[]).map((option) => {
    return {
      ...option,
      selected: optionIsSelected(option.value, fieldValue),
    };
  });
};

const getExtraneousValues = (
  fieldValues: string[],
  options: FormattedOption[],
) => {
  return fieldValues.find((fieldValue) =>
    options.every((option) => option.value !== fieldValue),
  );
};

export const HookFormMultiSelectCheckbox = ({
  name,
  options,
  freeTextButton,
  freeTextPlaceholder,
  cols = 2,
}: ComboRadioFieldProps) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, defaultValue: [] });
  const fieldValue = field.value;
  const [freeTextSelected, setFreeTextSelected] = useState(
    getExtraneousValues(fieldValue, options) != null,
  );
  const [freeTextValue, setFreeTextValue] = useState(
    getExtraneousValues(fieldValue, options) ?? '',
  );

  useEffect(() => {
    const extraneousValue = getExtraneousValues(fieldValue, options);
    if (extraneousValue != null) {
      setFreeTextSelected(true);
      setFreeTextValue(extraneousValue);
    }
  }, [fieldValue, options]);

  const formattedOptions = getFormattedOptions(options, fieldValue);

  const updateFieldValue = (
    updatedOptions: FormattedOption[],
    freeTextFieldValue = '',
  ) => {
    const selectedValues = updatedOptions
      .filter((option) => option.selected && option.value !== freeTextButton)
      .map((option) => option.value);

    if (freeTextFieldValue !== '') {
      selectedValues.push(freeTextFieldValue);
    }

    field.onChange(selectedValues);
  };

  const handleOptionClick = (
    option: FormattedOption,
    freeTextInputValue: string,
    index: number,
  ) => {
    const updatedOptions = Array.from(formattedOptions);
    updatedOptions[index].selected = !option.selected;

    const noneOption = updatedOptions.find(
      (updatedOption) =>
        updatedOption.value.toLowerCase() === NONE_OPTION_VALUE,
    );
    const noneOptionClicked = option === noneOption;

    if (noneOptionClicked) {
      updatedOptions.map((option) => {
        if (option !== noneOption && option.selected) {
          option.selected = false;
        }
        setFreeTextSelected(false);
        freeTextInputValue = '';
      });
    } else if (noneOption?.selected) {
      noneOption.selected = false;
    }

    const currentOption = updatedOptions.find(
      (optionState) => optionState.value === option.value,
    );

    if (!currentOption) {
      return;
    }

    updateFieldValue(updatedOptions, freeTextInputValue);
  };

  const handleFreeTextChange = (updatedValue: string) => {
    const updatedOptions = Array.from(formattedOptions);
    updateFieldValue(updatedOptions, updatedValue);
    setFreeTextValue(updatedValue);
  };

  return (
    <div className="w-full">
      <div
        role="radiogroup"
        className={`mx-auto grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-${cols}`}
      >
        {formattedOptions.map((option, index) =>
          option.value === freeTextButton ? (
            <div key={index + name} className="space-y-2">
              <ToggleButton
                vertical
                text={option.text}
                selected={freeTextSelected}
                onClick={() => {
                  handleOptionClick(option, '', index);
                  setFreeTextSelected(!freeTextSelected);
                  setFreeTextValue('');
                }}
              />
              {freeTextSelected && (
                <StyledInput
                  placeholder={freeTextPlaceholder}
                  value={freeTextValue}
                  onChange={(event) => handleFreeTextChange(event.target.value)}
                  data-testid="freeTextInput"
                />
              )}
            </div>
          ) : (
            <ToggleButton
              vertical
              key={index + name}
              text={option.text}
              selected={option.selected ?? false}
              onClick={() => handleOptionClick(option, freeTextValue, index)}
            />
          ),
        )}
      </div>

      {error && (
        <p className="text-critical-dark mt-2 text-sm font-medium">
          {error.message}
        </p>
      )}
    </div>
  );
};
