import * as LabelPrimitive from '@radix-ui/react-label';
import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { cva } from 'class-variance-authority';
import { ElementRef, forwardRef, useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { Text } from '../../Text/Text';
import { TextFieldProps } from '../TextField/TextField';

const radioItem = cva(
  'w-[20px] h-[20px] shrink-0 rounded-circle outline-none cursor-default border-2 border-strong focus:shadow-[0_0_0_6px] focus:shadow-content-disabled/20 hover:shadow-[0_0_0_6px] hover:shadow-content-disabled/20 disabled:hover:shadow-none',
);

const radioIndicator = cva(
  'flex items-center w-full h-full justify-center relative after:content-[""] after:block after:w-[8px] after:h-[8px] after:rounded-[50%]',
);

export type RadioFieldProps = {
  id?: string;
  label?: string;
  value: string;
  disabled?: boolean;
  // TODO: Implement free text?
  // https://github.com/purposemed/core/issues/11841
  freeText?: TextFieldProps;
} & RadioPrimitive.RadioGroupItemProps;

/**
 * A checkable button—known as a radio button—where no more than one of the buttons can be checked at a time.
 * When used alone, `RadioField` will render just a button and when used within a form it will render the native HTML input radio.
 */
export const RadioField = forwardRef<
  ElementRef<typeof RadioPrimitive.Item>,
  RadioFieldProps
>(
  (
    { id: idProp, value, label, disabled, freeText, ...primitiveProps },
    forwardedRef,
  ) => {
    const generatedId = useId();
    const id = idProp || generatedId;

    return (
      <div className="flex items-center gap-xs">
        <RadioPrimitive.Item
          {...primitiveProps}
          className={twMerge(
            radioItem(),
            disabled
              ? 'cursor-not-allowed border-default'
              : 'rdx-state-checked:border-accent cursor-pointer',
          )}
          value={value}
          id={id}
          aria-disabled={disabled}
          onClick={
            disabled ? (e) => e.preventDefault() : primitiveProps.onClick
          }
          ref={forwardedRef}
        >
          <RadioPrimitive.Indicator
            className={twMerge(
              radioIndicator(),
              disabled ? 'after:bg-surface-disabled' : 'after:bg-primary',
            )}
            aria-disabled={disabled}
          />
        </RadioPrimitive.Item>
        {label && (
          <LabelPrimitive.Label
            htmlFor={id}
            className={twMerge(
              disabled ? 'cursor-not-allowed' : 'cursor-pointer',
            )}
          >
            <Text
              size="label"
              color={disabled ? 'contentDisabled' : 'contentDefault'}
            >
              {label}
            </Text>
          </LabelPrimitive.Label>
        )}
      </div>
    );
  },
);

RadioField.displayName = 'RadioField';
