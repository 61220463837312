import { FC } from 'react';
import {
  MAX_HEALTH_CARE_NUMBER_LENGTH,
  healthCardNumberFormatForProvince,
} from '../../utilities/healthCardNumberFormatter';
import { HookFormFlowbiteField } from './HookFormFlowbiteField';
import { useTranslation } from 'react-i18next';

type Props = React.HTMLProps<HTMLInputElement> & {
  name: string;
  provinceOfCoverageId?: string;
  placeholder?: string;
  required?: boolean;
  dataCy?: string;
};

export const HookFormHealthCardNumberInput: FC<Props> = ({
  name,
  provinceOfCoverageId,
  dataCy,
  ...props
}) => {
  const { t } = useTranslation('settings');
  const healthCardNumberFormat =
    healthCardNumberFormatForProvince(provinceOfCoverageId);

  return (
    <HookFormFlowbiteField
      {...props}
      name={name}
      data-cy={dataCy}
      maxLength={MAX_HEALTH_CARE_NUMBER_LENGTH}
      subtext={
        healthCardNumberFormat
          ? t('HealthCardNumberFormatExample', {
              example: healthCardNumberFormat,
            })
          : null
      }
    />
  );
};
