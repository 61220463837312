/**
 * Can be used to override the browser back button and call a function
 * Ex: used in the questionnaires to override the browser back button
 * and call our mutation to go back to the previous step
 */

export const overrideBrowserBack = (doSomethingElse?: () => void) => {
  window.history.pushState(null, '', window.location.href);

  window.onpopstate = function () {
    doSomethingElse ? doSomethingElse() : null;
  };
};
