import { useGetCountriesQuery } from '@pm/graphql';
import { useTranslation } from 'react-i18next';

export const useCanadianProvinces = () => {
  const { data } = useGetCountriesQuery();
  if (!data) return undefined;

  const canada = data.countries.find((country) => country.code === 'CA');
  return canada?.provinces;
};

export const useSupportedCanadianProvinces = () =>
  useCanadianProvinces()?.filter((province) => province.supported);

export const useCanadianProvincesOptions = () => {
  const { t } = useTranslation('global');
  const { data, loading } = useGetCountriesQuery();
  const canada = data?.countries.find((country) => country.code === 'CA');
  const provinces =
    canada?.provinces.map(({ id, name }) => {
      const provinceName = name as ProvinceName;

      return {
        key: id,
        displayValue: t(`Provinces.${provinceName}`),
      };
    }) ?? [];

  return {
    data: provinces,
    loading,
  };
};

export const useUnsupportedCanadianProvinces = () =>
  useCanadianProvinces()?.filter((province) => !province.supported);

export type ProvinceName =
  | 'Alberta'
  | 'British Columbia'
  | 'Manitoba'
  | 'New Brunswick'
  | 'Newfoundland and Labrador'
  | 'Northwest Territories'
  | 'Nova Scotia'
  | 'Nunavut'
  | 'Ontario'
  | 'Prince Edward Island'
  | 'Quebec'
  | 'Saskatchewan'
  | 'Yukon';
