import { InlineMessage } from '@pm/ui';
import { useFormContext } from 'react-hook-form';

type FormErrorProps = {
  error: {
    title?: string;
    instructions: React.ReactNode;
  };
};

export const FormError = ({ error }: FormErrorProps) => {
  const {
    formState: { errors },
  } = useFormContext();
  const formHasServerError = errors.root?.serverError !== undefined;

  if (!formHasServerError) {
    return null;
  }

  return <InlineMessage intent="error" {...error} />;
};
