import { FunctionComponent } from 'react';
import { Dialog } from '../Dialog';
import { CardText } from '../CardText';
import { Button } from '../Button';

type Props = {
  open: boolean;
  title: string;
  body: string;
  confirm: string;
  cancel: string;
  onConfirm: () => void;
  onClose: () => void;
};

export const ConfirmationDialog: FunctionComponent<Props> = ({
  open,
  title,
  body,
  confirm,
  cancel,
  onConfirm,
  onClose,
}) => {
  return (
    <Dialog open={open} onClose={onClose} className="mx-4">
      <div className="text-grey-900 mb-2 text-2xl font-medium">{title}</div>
      <CardText className="mb-5">{body}</CardText>
      <Button variant="primary" className="mb-2" onClick={onConfirm}>
        {confirm}
      </Button>
      <Button variant="tertiary" className="" onClick={onClose}>
        {cancel}
      </Button>
    </Dialog>
  );
};
