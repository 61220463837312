import { IconName } from '../..';
import { Icon } from './Icon';

export const IconBox = ({
  icon,
  className,
  iconColor,
}: {
  icon: string;
  className?: string;
  iconColor?: string;
}) => {
  return (
    <div className={className + ' p-xs rounded-m'}>
      <Icon name={icon as IconName} color={iconColor} />
    </div>
  );
};
