import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { cva } from 'class-variance-authority';
import { useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { TextFieldProps } from '../Inputs/TextField/TextField';
import { Text } from '../Text/Text';
import { type CardBaseProps, cardBase } from './base';

const interactiveCard = cva(
  'cursor-pointer hover:shadow-l focus:ring rdx-state-checked:border-accent rdx-state-checked:border-2 aria-disabled:cursor-not-allowed aria-disabled:!border-strong aria-disabled:hover:shadow-none aria-disabled:bg-surface-disabled aria-disabled:text-content-disabled aria-disabled:ring-0',
);

type InteractiveCardProps = CardBaseProps &
  RadioPrimitive.RadioGroupItemProps & {
    disabled?: boolean;
    // TODO: Implement free text?
    // https://github.com/purposemed/core/issues/11841
    freeText?: TextFieldProps;
  };

/**
 * An selectable version of Card. Can be used in forms or as a standalone component.
 *
 * @example
 * ```jsx
 * <InteractiveCard
 *  title="Bob Sacamano"
 *  size="small"
 *  value="bobSacamano"
 *  checked={values.includes("bobSacamano")}
 *  onClick={() => setValues(...)}
 * >
 *  <Text size="bodyS">He sells Russian hats.</Text>
 * </InteractiveCard>
 */
export const InteractiveCard = ({
  title,
  children,
  size,
  value,
  disabled,
  required,
  onClick,
  freeText,
  ...props
}: InteractiveCardProps) => {
  const id = useId();
  const styles = twMerge(cardBase({ size }), interactiveCard());

  return (
    <RadioPrimitive.Item
      {...props}
      id={id}
      aria-disabled={disabled}
      value={value}
      required={required}
      className={styles}
      onClick={disabled ? (e) => e.preventDefault() : onClick}
    >
      {title && <Text size="headingXS">{title}</Text>}
      {children}
    </RadioPrimitive.Item>
  );
};
