import * as ExpandableList from '../ListGroup';
import { LoadingSpinner } from '../LoadingSpinner';
import { InView } from 'react-intersection-observer';
import { formatDateToLongFormYear } from '../../utilities/dateUtilities';
import { useTranslation } from 'react-i18next';

type PrescriptionListItem = {
  id: string;
  prescriptionType: string | null;
  medication: string | null;
  additionalInfo?: string | null;
  sentAt: Date | null;
  pillCountWithDosage?: string;
  pharmacy: string;
};

interface Props {
  prescriptions: PrescriptionListItem[];
  loading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
}

export const PrescriptionsList = ({
  prescriptions,
  loading,
  hasNextPage,
  onLoadMore,
}: Props) => {
  const { i18n } = useTranslation();

  if (prescriptions.length === 0) {
    return null;
  }

  return (
    <>
      <ExpandableList.ListGroup divided>
        {prescriptions.map((prescription) => (
          <ExpandableList.ListGroupItemExpandable key={prescription.id}>
            <ExpandableList.ListGroupItemExpandableTrigger>
              <div className="text-grey-900 font-medium">
                {prescription.medication}
              </div>
              <div className="text-grey-500">
                {prescription.sentAt &&
                  formatDateToLongFormYear(prescription.sentAt, i18n.language)}
                {prescription.prescriptionType && (
                  <span> &#183; {prescription.prescriptionType}</span>
                )}
              </div>
            </ExpandableList.ListGroupItemExpandableTrigger>
            <ExpandableList.ListGroupItemExpandableContent>
              <div className="text-grey-500 space-y-4">
                {prescription.pillCountWithDosage && (
                  <div>{prescription.pillCountWithDosage}</div>
                )}
                {prescription.additionalInfo && (
                  <div>{prescription.additionalInfo}</div>
                )}
                <div>{prescription.pharmacy}</div>
              </div>
            </ExpandableList.ListGroupItemExpandableContent>
          </ExpandableList.ListGroupItemExpandable>
        ))}
      </ExpandableList.ListGroup>
      {hasNextPage && (
        <InView
          as="div"
          className="text-brand-primary-500 flex justify-center pt-4"
          onChange={(inView) => {
            if (inView) {
              onLoadMore();
            }
          }}
        >
          {loading && <LoadingSpinner size="2x" />}
        </InView>
      )}
    </>
  );
};
