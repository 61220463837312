import { VariantProps } from 'class-variance-authority';
import { ComponentPropsWithoutRef, ReactNode, forwardRef } from 'react';
import { WithIcon } from '../../types/component.types';
import { button } from '../Button/Button';

type ButtonProps = VariantProps<typeof button> &
  Omit<ComponentPropsWithoutRef<'button'>, 'className' | 'children'> &
  WithIcon & { children: ReactNode };

/**
 * Icon buttons are default buttons with an icon element.
 *
 * @example
 * ```tsx
 * <IconButton intent="secondary"> <KeyIcon /> </IconButton>
 * ```
 */
export const IconButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      intent,
      size,
      type = 'button',
      children,
      disabled,
      onClick,
      ...buttonProps
    },
    ref,
  ) => {
    return (
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        ref={ref}
        className={button({ intent, purpose: 'icon', size })}
        aria-disabled={disabled}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
        {...buttonProps}
      >
        {children}
      </button>
    );
  },
);

IconButton.displayName = 'IconButton';
