import { ButtonLink, Text, IconBox } from '@pm/ui';

export type TreatmentCardFooterType =
  | 'GHOST_BUTTON'
  | 'ICON_WITH_DESCRIPTION'
  | 'ICON_WITH_LINK'
  | 'PRIMARY_AND_GHOST_BUTTON'; //For future use

export const TreatmentCardFooter = ({
  footerText,
  footerType,
  icon,
  link,
}: {
  footerText: string;
  footerType: TreatmentCardFooterType;
  icon?: string;
  link?: string;
}) => {
  let iconBackgroundColor = 'bg-surface-strong';
  let iconColor = 'text-content-default';
  let footerTextColor = 'text-content-subdued';

  if (icon?.includes('autorenew')) {
    iconBackgroundColor = 'bg-warning-subtle';
    iconColor = 'text-content-warning';
    footerTextColor = 'text-content-warning';
  } else if (icon?.includes('pill')) {
    iconBackgroundColor = 'bg-positive-subtle';
    iconColor = 'text-content-positive';
    footerTextColor = 'text-content-default';
  }

  switch (footerType) {
    case 'GHOST_BUTTON':
      return (
        <div className="flex items-start -ml-xs">
          <ButtonLink to={link ?? '/'} intent="ghost" width="fit" size="small">
            {footerText}
          </ButtonLink>
        </div>
      );
    case 'ICON_WITH_DESCRIPTION':
      return (
        <div className="flex justify-start items-center space-x-xs">
          <IconBox
            icon={icon ?? ''}
            className={iconBackgroundColor}
            iconColor={iconColor}
          />
          <Text size="bodyS" className={footerTextColor}>
            {footerText}
          </Text>
        </div>
      );
    case 'ICON_WITH_LINK':
      return (
        <div className="flex justify-start items-center space-x-xs">
          <IconBox
            icon={icon ?? ''}
            className={iconBackgroundColor}
            iconColor={iconColor}
          />
          <a href={link} className={footerTextColor + ' text-button-s'}>
            {footerText}
          </a>
        </div>
      );
    default:
      return null;
  }
};
