import clsx from 'clsx';

//add more types as needed. this is temporary component,
// will be removed when our new design system is ready
type TagType = 'warning' | 'error';

type BadgeProps = {
  text: string;
  type?: TagType;
};

export const Tag = ({ text, type }: BadgeProps) => {
  return (
    <span
      className={clsx(
        'rounded-full px-2 text-xs font-medium inline-block leading-6',
        {
          'bg-grey-200 text-grey-700': !type,
          'bg-warning-light': type === 'warning',
          'text-warning-dark': type === 'warning',
          'bg-critical-light': type === 'error',
          'text-critical-dark': type === 'error',
        },
      )}
    >
      {text}
    </span>
  );
};

export default Tag;
