import { Navigate, NavigateProps } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useOnMountOnce } from '../../hooks/use-on-mount-once';

type RedirectWithMessageProps = {
  message: string;
  type?: 'error' | 'success';
} & Pick<NavigateProps, 'to'>;

/**
 * Redirect helper component that fires a toast then navigating to the given path. If type is undefined a blank toast will be rendered.
 */
export const RedirectWithMessage = ({
  message,
  to,
  type = 'success',
}: RedirectWithMessageProps) => {
  useOnMountOnce(() => {
    if (!type) {
      toast(message);
    } else {
      toast[type](message);
    }
  });

  return <Navigate to={to} replace />;
};
