import { VariantProps, cva } from 'class-variance-authority';
import { FunctionComponent, ReactNode } from 'react';

const messageName = cva('p-4 align-bottom capitalize', {
  variants: {
    variant: {
      sender: 'text-xl font-black',
      recipient: 'text-grey-400 p-4 align-bottom text-sm leading-8',
    },
  },
});

type MessageNameProps = VariantProps<typeof messageName> & {
  children: ReactNode;
};

const MessageName: FunctionComponent<MessageNameProps> = ({
  children,
  ...props
}) => {
  return <p className={messageName(props)}>{children}</p>;
};
export default MessageName;
