import { CardText } from '../CardText';
import { QuestionnaireLabel } from '../QuestionnaireLabel';
export const QuestionnairePageHeading = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  const titleIsEmpty = title === '';
  const descriptionIsEmpty = description === '';

  return (
    <>
      {!titleIsEmpty && (
        <QuestionnaireLabel variant="bigBoldGreen" label={title} />
      )}
      {!descriptionIsEmpty && (
        <CardText className="whitespace-pre-wrap">{description}</CardText>
      )}
    </>
  );
};
