import { createContext, FunctionComponent, PropsWithChildren } from 'react';
import { Brand, FormattingContext } from '../types';

type EnvironmentContextType = {
  directUploadsUrl: string;
  googleAuthUrl: string;
  apiHost: string;
  environment: string;
  brand: Brand;
  formattingContext: FormattingContext;
};

type Props = Pick<
  EnvironmentContextType,
  'apiHost' | 'environment' | 'brand' | 'formattingContext'
>;

export const EnvironmentContext = createContext<EnvironmentContextType>({
  directUploadsUrl: '',
  googleAuthUrl: '',
  apiHost: '',
  environment: 'staging',
  brand: 'freddie',
  formattingContext: 'provider',
});

export const EnvironmentProvider: FunctionComponent<
  PropsWithChildren<Props>
> = ({ children, apiHost, environment, brand, formattingContext }) => (
  <EnvironmentContext.Provider
    value={{
      directUploadsUrl: `${apiHost}/uploads/direct_uploads`,
      googleAuthUrl: `${apiHost}/auth/google_oauth2/callback`,
      apiHost,
      environment,
      brand,
      formattingContext: formattingContext,
    }}
  >
    {children}
  </EnvironmentContext.Provider>
);
