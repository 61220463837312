import clsx from 'clsx';
import { ReactNode, useId } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { CharacterCount } from '../CharacterCount';
import './flowbiteField.css';
import { StyledInput } from '../forms/StyledInput';

type HookFormFlowbiteFieldProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'onChange'
> & {
  name: string;
  dataCy?: string;
  bottomMargin?: boolean;
  subtext?: ReactNode;
  required?: boolean;
  characterCount?: number;
};

export const HookFormFlowbiteField = ({
  name,
  label,
  placeholder,
  className,
  autoComplete = 'on',
  dataCy,
  bottomMargin = true,
  type = 'text',
  subtext,
  maxLength,
  id: idProp,
  required = false,
  characterCount,
  autoCapitalize,
  spellCheck,
  disabled,
}: HookFormFlowbiteFieldProps) => {
  const _id = useId();
  const id = idProp || _id;
  // TODO: This component should use `useController` instead.
  // When we make this change, forms using this component will
  // need to supply `mode: 'onChange'` to `useForm`. There are
  // a few forms that still have no form validation with the mode
  // changed, so this task is being saved for a later date.
  const { setValue } = useFormContext();
  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <div className="space-y-2">
      {label && (
        <label
          htmlFor={id}
          className={clsx('text-grey-500 text-sm font-medium', {
            "after:ml-1 after:text-red-500 after:content-['*']": required,
          })}
        >
          {label}
        </label>
      )}
      <StyledInput
        {...field}
        type={type}
        id={id}
        disabled={disabled}
        placeholder={placeholder}
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        spellCheck={spellCheck}
        data-cy={dataCy}
        value={field.value ?? ''}
        name={name}
        aria-label={label ? undefined : name} // TODO: Remove this once we properly handle labels as name shouldn't be announced to screen readers
        maxLength={maxLength}
        required={required}
        aria-invalid={!!error}
        className={className}
        onChange={(event) =>
          setValue(name, event.target.value, {
            shouldTouch: true,
            shouldValidate: true,
            shouldDirty: true,
          })
        }
      />
      {subtext && (
        <div className="text-grey-500 pl-1 text-xs font-medium">{subtext}</div>
      )}
      {(error || characterCount) && (
        <div
          className={clsx(
            // TODO: Remove margin in favour of consistent spacing
            'mt-1 flex items-center justify-between',
            bottomMargin && 'mb-4',
          )}
        >
          {error && (
            <p className="text-critical-dark border-none text-sm font-medium">
              {error.message}
            </p>
          )}
          {characterCount && (
            <div className="ml-auto">
              <CharacterCount
                count={field.value?.length ?? 0}
                limit={characterCount}
              />
            </div>
          )}
        </div>
      )}
      {bottomMargin && <div className="mb-4"></div>}
    </div>
  );
};
