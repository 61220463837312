import clsx from 'clsx';
import i18n from 'i18next';
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react';

type CharacterCountElement = ElementRef<'div'>;
export interface CharacterCountProps extends ComponentPropsWithoutRef<'div'> {
  count?: number;
  limit?: number;
}

/**
 * CharacterCount
 *
 * Assistive component that displays the current character count of a text field.
 */
export const CharacterCount = forwardRef<
  CharacterCountElement,
  CharacterCountProps
>(({ count = 0, limit = 0, ...props }, forwardedRef) => {
  const percentageToLimit = count / limit;
  const shouldAnnounceLimit = percentageToLimit > 0.75;

  const announceProps = shouldAnnounceLimit
    ? ({
        role: 'status',
        'aria-live': 'polite',
        'aria-atomic': true,
        'aria-label': characterCountMessage(count, limit),
      } as const)
    : undefined;

  return (
    <div
      {...props}
      {...announceProps}
      className={clsx('sm:text-sm', {
        'text-content-subdued': percentageToLimit <= 1,
        'text-critical-dark': percentageToLimit > 1,
      })}
      ref={forwardedRef}
    >
      <span aria-hidden="true">{`${count} / ${limit}`}</span>
    </div>
  );
});

CharacterCount.displayName = 'CharacterCount';

const characterCountMessage = (count: number, limit: number) => {
  if (count > limit) {
    return i18n.t('Validations.String.LimitExceeded', {
      ns: 'global',
      count: count - limit,
    });
  }

  return i18n.t('Validations.String.NumCharsRemaining', {
    ns: 'global',
    count: limit - count,
  });
};
