import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useMediaQuery } from '../hooks/useMediaQuery';

type MainAppLayoutContextType = {
  sidebarOpen?: boolean;
  setSidebarOpen: (sidebarOpen: boolean) => void;
  isDesktop: boolean;
  mediaQueryLoading: boolean;
};

export const MainAppLayoutContext = createContext<MainAppLayoutContextType>({
  sidebarOpen: false,
  setSidebarOpen: () => {
    return;
  },
  isDesktop: false,
  mediaQueryLoading: true,
});

export const useMainAppLayout = () => useContext(MainAppLayoutContext);

export const MainAppLayoutProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const [sidebarOpen, setSidebarOpen] = useState<boolean>();
  const { matches: isDesktop, loading: mediaQueryLoading } =
    useMediaQuery('(min-width: 960px)');

  useEffect(() => {
    if (sidebarOpen === undefined && !mediaQueryLoading) {
      setSidebarOpen(false);
    }
  }, [isDesktop, mediaQueryLoading, sidebarOpen]);

  return (
    <MainAppLayoutContext.Provider
      value={{
        sidebarOpen,
        setSidebarOpen,
        isDesktop,
        mediaQueryLoading,
      }}
    >
      {children}
    </MainAppLayoutContext.Provider>
  );
};
