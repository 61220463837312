import { Link, LinkProps } from 'react-router-dom';
import { Icon } from './Icon';
import { IconName } from './icons';

export type IconLinkProps = Omit<LinkProps, 'className'> & {
  icon: string;
} & {
  target?: string;
  rel?: string;
  disabled?: boolean;
};

export const IconLink = ({
  icon,
  target,
  rel,
  disabled,
  onClick,
  ...rest
}: IconLinkProps) => {
  return (
    <Link
      {...rest}
      target={target}
      rel={rel}
      aria-disabled={disabled}
      onClick={disabled ? (e) => e.preventDefault() : onClick}
    >
      <Icon name={icon as IconName} />
    </Link>
  );
};
