import { Routes, Route, Navigate } from 'react-router-dom';
import { PrepTreatment } from './PrepTreatment/PrepTreatment';
import { ClinicalRecommendationsPage } from './PrepTreatment/ClinicalRecommendationsPage';
import { StopPrepPage } from './PrepTreatment/StopPrepPage';
import { PausePrepPage } from './PrepTreatment/PausePrepPage';
import { RestartPrepPage } from './PrepTreatment/RestartPrepPage';

// entry route: /treatments/*
export const PrepTreatmentRoutes = () => {
  return (
    <Routes>
      <Route path="/prep/*">
        <Route index element={<PrepTreatment />} />
        <Route path=":action">
          <Route
            path="clinical-recommendations"
            element={<ClinicalRecommendationsPage />}
          />
        </Route>
        <Route path="stop" element={<StopPrepPage />} />
        <Route path="pause" element={<PausePrepPage />} />
        <Route path="restart" element={<RestartPrepPage />} />
      </Route>

      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};
