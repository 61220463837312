import { useSpinDelay } from 'spin-delay';
import { BackArrow } from '../BackArrow';
import { Card } from '../Card';
import { QuestionnaireLoadingIndicator } from './QuestionnaireLoadingIndicator';
import { QuestionnairePageHeading } from './QuestionnairePageHeading';
import { QuestionnaireSection } from './QuestionnaireSection';
import LayoutWithBackButton from '../LayoutWithBackButton/LayoutWithBackButton';
import { overrideBrowserBack } from '../../utilities/backUtilities';
import { QuestionnaireResources } from './QuestionnaireResources';
import { ActionVariantEnum, QuestionnaireQuestionFragment } from '@pm/graphql';
import { useMemo } from 'react';
import { ButtonLink } from '@pm/ui';
import { actionVariantToIntent } from './OutcomePage';

type PageAction = {
  label: string;
  variant: ActionVariantEnum;
  to: string;
};

interface QuestionPageProps {
  children: React.ReactNode;
  title?: string | null;
  description?: string | null;
  onPreviousStep?: () => void;
  isLoading?: boolean;
  actions?: PageAction[];
  questions: QuestionnaireQuestionFragment[];
}

export const QuestionPage = ({
  children,
  title,
  isLoading = false,
  description,
  onPreviousStep,
  actions = [],
  questions = [],
}: QuestionPageProps) => {
  const titleEmpty = title === '' || title == null;
  const descriptionEmpty = description === '' || description == null;
  const showHeading = !titleEmpty || !descriptionEmpty;

  const showLoadingIndicator = useSpinDelay(isLoading, {
    delay: 300,
    minDuration: 250,
    ssr: false,
  });

  const buildBelowPageResources = (
    questions: QuestionnaireQuestionFragment[],
  ) => {
    const allResources: (JSX.Element | null)[] = [];

    questions.map((question) => {
      if (question.resources) {
        allResources.push(
          <QuestionnaireResources
            key={question.id}
            resources={question.resources}
          />,
        );
      }
    });
    return allResources;
  };

  const allResources = useMemo(
    () => buildBelowPageResources(questions),
    [questions],
  );

  //To allow browser/mobile back button functionality
  overrideBrowserBack(onPreviousStep);

  return (
    <div className="space-y-4">
      <LayoutWithBackButton
        buttonNode={
          onPreviousStep ? (
            <BackArrow disabled={isLoading} onClick={onPreviousStep} />
          ) : null
        }
      >
        <Card className="relative select-none space-y-8">
          {showHeading && (
            <QuestionnaireSection>
              <QuestionnairePageHeading
                title={title as string}
                description={description as string}
              />
            </QuestionnaireSection>
          )}
          {children}
          {showLoadingIndicator && <QuestionnaireLoadingIndicator />}
          {actions.length > 0 &&
            actions.map(({ label, variant, to }) => (
              <ButtonLink
                key={label}
                to={to}
                intent={actionVariantToIntent[variant]}
                external={to.startsWith('https')}
              >
                {label}
              </ButtonLink>
            ))}
        </Card>
      </LayoutWithBackButton>
      <div className="-mx-4">{allResources}</div>
    </div>
  );
};
