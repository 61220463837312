import { Text } from '@pm/ui';

export const IconListItem = ({
  textContent,
  icon,
}: {
  textContent?: string;
  icon: JSX.Element;
}) => {
  return (
    <div className="flex flex-row gap-x-m items-center">
      {icon}
      <Text size="bodyL">{textContent}</Text>
    </div>
  );
};
