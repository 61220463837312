import * as React from 'react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import * as Collapsible from '@radix-ui/react-collapsible';
import clsx from 'clsx';
type OmitClassName<T> = Omit<T, 'className'>;
/* -------------------------------------------------------------------------------------------------
 * ListGroup
 * -----------------------------------------------------------------------------------------------*/
type ListGroupElement = React.ElementRef<'div'>;
interface ListGroupProps
  extends OmitClassName<React.ComponentPropsWithoutRef<'div'>> {
  divided?: boolean;
}
export const ListGroup = React.forwardRef<ListGroupElement, ListGroupProps>(
  ({ divided, ...props }, forwardedRef) => {
    return (
      <div
        className={clsx(
          'border-grey-200 w-full rounded-lg border bg-white shadow-md',
          { 'divide-grey-200 divide-y': divided },
        )}
        {...props}
        ref={forwardedRef}
      />
    );
  },
);
ListGroup.displayName = 'ListGroup';
/* -------------------------------------------------------------------------------------------------
 * ListGroupItemExpandable
 * -----------------------------------------------------------------------------------------------*/
type ListGroupItemExpandableElement = React.ElementRef<typeof Collapsible.Root>;
type ListGroupItemExpandableProps = OmitClassName<Collapsible.CollapsibleProps>;
export const ListGroupItemExpandable = React.forwardRef<
  ListGroupItemExpandableElement,
  ListGroupItemExpandableProps
>((props, forwardedRef) => {
  return <Collapsible.Root {...props} ref={forwardedRef} />;
});
ListGroupItemExpandable.displayName = 'ListGroupItemExpandable';
/* -------------------------------------------------------------------------------------------------
 * ListGroupItemExpandableTrigger
 * -----------------------------------------------------------------------------------------------*/
type ListGroupItemExpandableTriggerElement = React.ElementRef<
  typeof Collapsible.Trigger
>;
type ListGroupItemExpandableTriggerProps =
  OmitClassName<Collapsible.CollapsibleTriggerProps>;
export const ListGroupItemExpandableTrigger = React.forwardRef<
  ListGroupItemExpandableTriggerElement,
  ListGroupItemExpandableTriggerProps
>(({ children, ...props }, forwardedRef) => {
  return (
    <Collapsible.Trigger
      className="text-grey-900 group flex w-full justify-between px-5 py-4 text-start leading-normal"
      {...props}
      ref={forwardedRef}
    >
      <div>{children}</div>
      <ChevronDownIcon
        className="group-rdx-state-open:rotate-180 duration-50 h-6 w-6 transition-transform"
        aria-hidden
      />
    </Collapsible.Trigger>
  );
});
ListGroupItemExpandableTrigger.displayName = 'ListGroupItemExpandableTrigger';
/* -------------------------------------------------------------------------------------------------
 * ListGroupItemExpandableContent
 * -----------------------------------------------------------------------------------------------*/
type ListGroupItemExpandableContentElement = React.ElementRef<
  typeof Collapsible.Content
>;
type ListGroupItemExpandableContentProps =
  OmitClassName<Collapsible.CollapsibleContentProps>;
export const ListGroupItemExpandableContent = React.forwardRef<
  ListGroupItemExpandableContentElement,
  ListGroupItemExpandableContentProps
>((props, forwardedRef) => {
  return (
    <Collapsible.Content {...props} className="px-5 pb-4" ref={forwardedRef} />
  );
});
ListGroupItemExpandableContent.displayName = 'ListGroupItemExpandableContent';
