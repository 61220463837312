import { Trans, useTranslation } from 'react-i18next';
import { Icon, Text, TextLink } from '@pm/ui';

export const ModalContent = () => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'DoxyPepTreatment.Info',
  });

  return (
    <div className="space-y-xxxl pb-xxxl divide-y-8">
      <div className="space-y-xl">
        <Text size="bodyL">{t('DoxyPepDescription')}</Text>
        <ul className="space-y-m">
          <ValueProp text={t('ValueProp1')} />
          <ValueProp text={t('ValueProp2')} />
          <ValueProp text={t('ValueProp3')} />
          <ValueProp text={t('ValueProp4')} />
        </ul>
      </div>

      <div className="space-y-m pt-xxxl">
        <Text size="headingS">{t('PricingTitle')}</Text>
        <Text size="bodyL">{t('PricingBody1')}</Text>
        <Text size="bodyL">{t('PricingBody2')}</Text>
      </div>

      <div className="space-y-m pt-xxxl">
        <Text size="headingS">{t('StartTreatmentTitle')}</Text>
        <div className="divide-y space-y-m">
          <div className="space-y-xxs">
            <Text size="label" color="contentSubdued">
              {t('StepOne')}
            </Text>
            <Text size="headingXS">{t('ShareDetailsTitle')}</Text>
            <Text size="bodyS" color="contentSubdued">
              {t('ShareDetailsBody')}
            </Text>
          </div>
          <div className="space-y-xxs pt-m">
            <Text size="label" color="contentSubdued">
              {t('StepTwo')}
            </Text>
            <Text size="headingXS">{t('CompleteLabWorkTitle')}</Text>
            <Text size="bodyS" color="contentSubdued">
              {t('CompleteLabWorkBody')}
            </Text>
          </div>
          <div className="space-y-xxs pt-m">
            <Text size="label" color="contentSubdued">
              {t('StepThree')}
            </Text>
            <Text size="headingXS">{t('GetRxTitle')}</Text>
            <Text size="bodyS" color="contentSubdued">
              {t('GetRxBody')}
            </Text>
          </div>
        </div>
      </div>

      <div className="space-y-l pt-xxxl">
        <Text size="headingS">{t('FAQsTitle')}</Text>

        {/* collapsible component 👇🏻 */}
        <div className="divide-y space-y-l">
          <div className="space-y-xs">
            <Text size="bodyL">{t('DoxyPepRightTitle')}</Text>
            <div className="space-y-xxs">
              <Text size="bodyL">{t('DoxyPepRight')}</Text>
              <ul className="list-disc ml-xl space-y-xxxs">
                <li>
                  <Text size="bodyS">{t('DoxyPepRightListItem1')}</Text>
                </li>
                <li>
                  <Text size="bodyS">{t('DoxyPepRightListItem2')}</Text>
                </li>
                <li>
                  <Text size="bodyS">{t('DoxyPepRightListItem3')}</Text>
                </li>
              </ul>
            </div>

            <Text size="bodyL">{t('DoxyPepWrong')}</Text>
            <ul className="list-disc ml-xl space-y-xxxs">
              <li>
                <Text size="bodyS">{t('DoxyPepWrongListItem1')}</Text>
              </li>
              <li>
                <Text size="bodyS">{t('DoxyPepWrongListItem2')}</Text>
              </li>
              <li>
                <Text size="bodyS">{t('DoxyPepWrongListItem3')}</Text>
              </li>
              <li>
                <Text size="bodyS">{t('DoxyPepWrongListItem4')}</Text>
              </li>
              <li>
                <Text size="bodyS">{t('DoxyPepWrongListItem5')}</Text>
              </li>
            </ul>

            <Text size="bodyL">{t('StillUnsure')}</Text>
            <ul className="list-disc ml-xl space-y-xxxs">
              <li>
                <Text size="bodyS">
                  <Trans t={t} i18nKey="StillUnsureListItem">
                    <TextLink to="/conversations" />
                  </Trans>
                </Text>
              </li>
            </ul>
          </div>

          <div className="space-y-xs pt-s">
            {/* collapsible component 👇🏻 */}
            <Text size="bodyL">{t('DoxyPepSafeTitle')}</Text>
            <Text size="bodyS">{t('DoxyPepSafeBody')}</Text>
          </div>

          <div className="space-y-xs pt-s">
            {/* collapsible component 👇🏻 */}
            <Text size="bodyL">{t('WhyNotBothTitle')}</Text>
            <Text size="bodyS">{t('WhyNotBothBody')}</Text>
          </div>
        </div>
      </div>
    </div>
  );
};

const ValueProp = ({ text }: { text: string }) => {
  return (
    <li className="flex gap-s">
      <Icon
        filled
        name="check_circle"
        size="large"
        color="text-content-positive"
        label="check"
      />
      <Text size="bodyS">{text}</Text>
    </li>
  );
};
