const DEFAULT_SCROLL_CONTAINER_ID = 'scroll-container';

type UseResetScrollOptions = {
  scrollContainerId?: string;
  smooth?: boolean;
};

/**
 * Resets the scroll position to 0,0 for the scroll container given. Default scroll container id is `scroll-container`
 */
export const useResetScroll = (options?: UseResetScrollOptions) => {
  const scrollContainer = document.getElementById(
    options?.scrollContainerId || DEFAULT_SCROLL_CONTAINER_ID,
  );

  const resetScroll = () =>
    scrollContainer?.scrollTo({
      top: 0,
      behavior: options?.smooth ? 'smooth' : 'auto',
    });

  return resetScroll;
};
