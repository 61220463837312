import { Icon } from '../Icon/Icon';
import { type BannerIntentType } from './Banner';

export const BannerIcon = ({ intent }: { intent: BannerIntentType }) => {
  switch (intent) {
    case 'info':
      return <Icon name="info" color="text-content-info" />;
    case 'warning':
      return <Icon name="warning" color="text-content-warning" />;
    case 'negative':
      return <Icon name="warning" color="text-content-negative" />;
  }
};
