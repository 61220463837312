import React from 'react';

type CarouselItemElement = React.ElementRef<'div'>;
type CarouselItemProps = React.ComponentPropsWithoutRef<'div'>;

export const CarouselItem = React.forwardRef<
  CarouselItemElement,
  CarouselItemProps
>((props, forwardedRef) => {
  return <div {...props} ref={forwardedRef} />;
});

CarouselItem.displayName = 'CarouselItem';
