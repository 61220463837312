import { Card, Text } from '@pm/ui';
import {
  TreatmentCardFooter,
  TreatmentCardFooterType,
} from './TreatmentCardFooter';
import { IconLink } from '@pm/ui/src/components/Icon/IconLink';

/**
 * For the specific case of treatment cards
 *
 */

type TreatmentCardProps = {
  title?: string;
  description?: string;
  icon?: string;
  link?: string;
  footerText?: string;
  footerType: TreatmentCardFooterType;
  topIconLink?: string;
  topIcon?: string;
};

export const TreatmentCard = ({
  title,
  description,
  icon,
  link,
  footerText = '',
  footerType,
  topIconLink,
  topIcon,
}: TreatmentCardProps) => {
  return (
    <Card size="large">
      <div className="flex flex-row justify-between items-center">
        {title && <Text size="headingXS">{title}</Text>}
        {topIconLink && <IconLink icon={topIcon ?? ''} to={topIconLink} />}
      </div>
      {description && <Text size="bodyS">{description}</Text>}
      <TreatmentCardFooter
        footerText={footerText}
        footerType={footerType}
        icon={icon}
        link={link}
      />
    </Card>
  );
};

export type { TreatmentCardFooterType };
