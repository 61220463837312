import { yupResolver } from '@hookform/resolvers/yup';
import {
  QuestionnaireAnswerFragment,
  QuestionnaireAnswerInput,
  QuestionnaireQuestionFragment,
} from '@pm/graphql';
import { FormSubmitButton } from '@pm/forms';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { QuestionsMapper } from './QuestionsMapper';
import {
  formatAnswerForDataType,
  formatAnswerForInputType,
} from './dataFormats';
import { questionSchemas } from './schemas';

interface QuestionFormProps {
  onSubmit: (data: QuestionnaireAnswerInput[]) => Promise<void>;
  answers: QuestionnaireAnswerFragment[];
  questions: QuestionnaireQuestionFragment[];
  actionText?: string;
}

const defaultQuestionValues = (
  questions: QuestionnaireQuestionFragment[] = [],
  answers: QuestionnaireAnswerFragment[] = [],
) => {
  const defaults: Record<string, string | string[]> = {};

  questions.forEach((question) => {
    const previouslyAnsweredValue = answers.find(
      (answer) => answer.question.id === question.id,
    );

    defaults[question.id] = formatAnswerForInputType(
      previouslyAnsweredValue,
      question.__typename,
    );
  });

  return defaults;
};

export const QuestionForm = ({
  onSubmit,
  actionText,
  answers = [],
  questions = [],
}: QuestionFormProps) => {
  const { resolver, values } = useMemo(() => {
    const schema = Yup.object(questionSchemas(questions));

    return {
      resolver: yupResolver(schema),
      values: defaultQuestionValues(questions, answers),
    };
  }, [questions, answers]);

  const methods = useForm({
    resolver,
    values,
    mode: 'all',
  });

  return (
    <FormProvider {...methods}>
      <form
        className="space-y-xxxl"
        onSubmit={methods.handleSubmit(async (data) => {
          const answers = questions.map((question) => ({
            questionId: question.id,
            values:
              data[question.id] != null
                ? formatAnswerForDataType(
                    data[question.id],
                    question.__typename,
                  )
                : [''],
          }));

          await onSubmit(answers);
        })}
      >
        <QuestionsMapper questions={questions} />
        {actionText && (
          <FormSubmitButton width="full">{actionText}</FormSubmitButton>
        )}
      </form>
    </FormProvider>
  );
};
