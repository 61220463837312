import { Text } from '../Text/Text';
import { type CardBaseProps, cardBase } from './base';

/**
 * Groups related information in a styled container loosely resembling a playing card.
 *
 * @example
 * ```jsx
 * <Card title="Shipping address" size="small">
 *   <Text size="bodyS">View your shipping address.</Text>
 * </Card>
 */
export const Card = ({ children, title, size, state }: CardBaseProps) => {
  return (
    <div className={cardBase({ size, state })}>
      {title && <Text size="headingXS">{title}</Text>}
      {children}
    </div>
  );
};
