import { QuestionnaireTextInputQuestion } from '@pm/graphql';
import i18n from 'i18next';
import { STRING_REQUIRED_SCHEMA } from './sharedSchemas';

export const textInputQuestionSchema = ({
  maxLength,
}: QuestionnaireTextInputQuestion) =>
  STRING_REQUIRED_SCHEMA.max(maxLength, () =>
    i18n.t('Validations.String.Maximum', {
      ns: 'global',
      count: maxLength,
    }),
  );
