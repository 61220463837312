import * as React from 'react';

type FormPostElement = React.ElementRef<'form'>;
type FormPostProps = Omit<React.ComponentPropsWithoutRef<'form'>, 'method'>;

const FormPost = React.forwardRef<FormPostElement, FormPostProps>(
  (props, forwardedRef) => {
    return <form {...props} method="post" ref={forwardedRef} />;
  },
);

FormPost.displayName = 'FormPost';

export { FormPost };
