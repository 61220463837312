import { Phone } from '@pm/graphql';
import { parsePhoneNumber } from 'react-phone-number-input';

export const formatPhoneNumber = (input: string, fallback?: string) => {
  const phoneNumber = parsePhoneNumber(input, 'CA');

  if (!phoneNumber) {
    return fallback;
  }

  if (phoneNumber.countryCallingCode === '1') {
    return phoneNumber.formatNational();
  }

  return phoneNumber.formatInternational();
};

/**
 * The backend doesn't properly support international phone numbers, so we need to fallback to the rawNumber which should be a e164 number
 */
export const getPhoneNumberForInput = (
  phone?: Pick<Phone, 'e164' | 'rawNumber'> | null,
) => {
  return phone?.e164 || phone?.rawNumber || '';
};
