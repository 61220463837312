import { AppointmentFragment, Province } from '@pm/graphql';

export enum Provinces {
  Alberta = 16485158,
  Ontario = 15679057,
  Saskatchewan = 15478973,
  QuebecEn = 19470909,
  QuebecFr = 19470873,
  NovaScotia = 19470931,
  TestAlberta = 18138276,
  TestOntario = 18769958,
  TestSaskatchewan = 18769942,
  TestQuebecEn = 19453340,
  TestQuebecFr = 19454934,
  TestNovaScotia = 19454651,
}

export const clinicianName = (appointment: AppointmentFragment) => {
  let clinicianName = 'Unknown Provider';
  if (appointment.calendar?.clinician) {
    clinicianName =
      appointment.calendar?.clinician.firstName +
      ' ' +
      appointment.calendar?.clinician.lastName;
  }
  return clinicianName;
};

export const provinceName = (appointment: AppointmentFragment) => {
  let provinceName = 'Unknown Province';
  if (appointment.calendar?.province) {
    provinceName = appointment.calendar?.province.name;
    if (provinceName === 'Quebec') {
      if (appointment.calendarName?.toLowerCase().includes('french')) {
        provinceName = provinceName + ' (Fr)';
      } else {
        provinceName = provinceName + ' (En)';
      }
    }
  } else if (appointment.appointmentTypeId) {
    provinceName = provinceNameByID(appointment.appointmentTypeId);
  }
  return provinceName;
};

export const provinceNameByID = (province: Provinces) => {
  if (province === Provinces.Ontario) {
    return 'Ontario';
  }

  if (province === Provinces.Alberta) {
    return 'Alberta';
  }

  if (province === Provinces.Saskatchewan) {
    return 'Saskatchewan';
  }

  if (province === Provinces.QuebecEn) {
    return 'Quebec (En)';
  }

  if (province === Provinces.QuebecFr) {
    return 'Quebec (Fr)';
  }

  if (province === Provinces.NovaScotia) {
    return 'Nova Scotia';
  }

  if (province === Provinces.TestAlberta) {
    return 'Test Alberta';
  }

  if (province === Provinces.TestSaskatchewan) {
    return 'Test Saskatchewan';
  }

  if (province === Provinces.TestOntario) {
    return 'Test Ontario';
  }

  if (province === Provinces.TestQuebecEn) {
    return 'Test Quebec (En)';
  }

  if (province === Provinces.TestQuebecFr) {
    return 'Test Quebec (Fr)';
  }

  if (province === Provinces.TestNovaScotia) {
    return 'Test Nova Scotia';
  }
  return 'Unknown Province';
};

export const getProvinceCode = (
  province: 'Alberta' | 'Saskatchewan' | 'Ontario' | 'Quebec' | 'Nova Scotia',
) => {
  switch (province) {
    case 'Alberta':
      return Province.Ab;
    case 'Saskatchewan':
      return Province.Sk;
    case 'Ontario':
      return Province.On;
    case 'Quebec':
      return Province.Qc;
    case 'Nova Scotia':
      return Province.Ns;
  }
};
