import { CoreParticipantFieldsFragment } from '@pm/graphql';
import { useTranslation } from 'react-i18next';

/**
 *  Utility hook for determining whether to render "<Brand> Medical Team" or the participants full name based on the participant role
 *
 * @param brand Brand name to be used in the message
 * @param participant Participant from the conversation either recipient or sender
 * @returns Either "<Brand> Medical Team" depending on the participant role or their first and last name
 */
export const useMessageName = (
  brand: string,
  participant: CoreParticipantFieldsFragment,
) => {
  const { t } = useTranslation('core', {
    keyPrefix: 'hooks.useMessageName',
  });

  return ['CX', 'MOA', 'ADMIN'].includes(participant.role.code)
    ? t('medicalTeam', { brand })
    : participant.preferredName ||
        `${participant.firstName} ${participant.lastName}`;
};
