import { type PropsWithChildren, type ElementRef, forwardRef } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import * as Select from '@radix-ui/react-select';
import { DropdownTrigger } from './DropdownTrigger';
import { type InputBaseProps } from '../base';

const dropdown = cva('flex flex-col bg-surface-strong rounded-s p-xs shadow-l');

export type DropdownProps = PropsWithChildren<VariantProps<typeof dropdown>> &
  InputBaseProps & {
    id?: string;
    name?: string;
    defaultValue?: string;
    placeholder?: string;
    ariaLabel?: string;
    disabled?: boolean;
    value?: string;
    onChange: (value: string) => void;
  };

/**
 * Controlled dropdown component. Supports items, labels and groups of items.
 *
 *
 *
 * @components
 * Content ==> The component that pops out when the select is open.
 * Viewport ==> The scrollable element that contains the dropdown items.
 *
 * @example
 * <Dropdown
 *   name="realHousewives"
 *   defaultValue="Lisa Vanderpump"
 *   placeholder="Select a real housewife..."
 * >
 *   <DropdownGroup label="Real Housewives of Beverly Hills tier list">
 *     {realHousewives.map((housewife) => (
 *       <DropdownItem
 *         key={housewife.id}
 *         value={housewife.name}
 *         text={housewife.name}
 *       />
 *     )}
 *   </DropdownGroup>
 * </Dropdown>
 */
const Dropdown = forwardRef<ElementRef<'button'>, DropdownProps>(
  (
    {
      ariaLabel,
      children,
      disabled,
      onChange,
      intent,
      placeholder,
      value,
      ...props
    },
    forwardedRef,
  ) => (
    <Select.Root
      name={props.name}
      disabled={disabled}
      onValueChange={(result) => {
        onChange(result);
      }}
      value={value}
    >
      <DropdownTrigger
        {...props}
        placeholder={placeholder}
        ref={forwardedRef}
        intent={intent}
        ariaLabel={ariaLabel}
      />
      <Select.Portal>
        <Select.Content className={dropdown()}>
          <Select.Viewport>{children}</Select.Viewport>
        </Select.Content>
      </Select.Portal>
    </Select.Root>
  ),
);

Dropdown.displayName = 'Dropdown';

export { Dropdown };
