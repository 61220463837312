import { useTranslation } from 'react-i18next';
import { MessageListRow } from './MessageListRow';
import { CoreConversationFieldsFragment } from '@pm/graphql';
import { TableHeadingCell } from '../Table/TableHeadingCell';
import { Table } from '../Table';
import { TableBody } from '../Table/TableBody';

type MessageListProps = {
  conversations: (CoreConversationFieldsFragment | undefined | null)[];
};

export const MessageList = ({ conversations }: MessageListProps) => {
  const { t } = useTranslation('messages');

  return (
    <Table>
      <thead>
        <tr>
          <TableHeadingCell>{t('From')}</TableHeadingCell>
          <TableHeadingCell>{t('Subject')}</TableHeadingCell>
          <TableHeadingCell>{t('Date')}</TableHeadingCell>
        </tr>
      </thead>
      <TableBody>
        {conversations.length === 0 && (
          <tr>
            <td colSpan={4} className="text-grey-400 p-4 text-center italic">
              {t('NoMessages')}
            </td>
          </tr>
        )}
        {conversations.map(
          (conversation) =>
            conversation && (
              <MessageListRow
                key={conversation.id}
                conversation={conversation}
                brand={conversation.brand.name}
              />
            ),
        )}
      </TableBody>
    </Table>
  );
};
