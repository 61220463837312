import { ReactNode } from 'react';
import { BackArrow } from '../BackArrow';

interface LayoutWithBackButtonProps {
  children: ReactNode;
  neighborElement?: ReactNode;
}

export interface LayoutWithBackButtonClickProps
  extends LayoutWithBackButtonProps {
  onBackButtonClick: () => void;
  buttonNode?: never;
}

export interface LayoutWithBackButtonNodeProps
  extends LayoutWithBackButtonProps {
  onBackButtonClick?: never;
  buttonNode: ReactNode | null;
}

export const LayoutWithBackButton = ({
  onBackButtonClick,
  buttonNode,
  children,
  neighborElement,
}: LayoutWithBackButtonClickProps | LayoutWithBackButtonNodeProps) => {
  const backButtonNodePassed = buttonNode || buttonNode === null;
  const backButton = backButtonNodePassed ? (
    buttonNode
  ) : (
    <BackArrow onClick={onBackButtonClick} />
  );
  return (
    <div className="flex flex-col space-y-5">
      {neighborElement ? (
        <div className="flex flex-row items-center">
          {backButton}
          {neighborElement}
        </div>
      ) : (
        backButton
      )}
      {children}
    </div>
  );
};

export default LayoutWithBackButton;
