import { FunctionComponent } from 'react';
import { ApolloQueryResult } from '@apollo/client/index.js';
import { Blob } from '@pm/upload';
import { Attachment } from './Attachment';
import type {
  Attachment as AttachmentType,
  Exact,
  GetConversationQuery,
} from '@pm/graphql';

export interface Attachment extends File {
  blob?: Blob;
  key?: string;
  error?: string;
}

type Props = {
  attachments: AttachmentType[];
  handleAttachmentClick: React.MouseEventHandler<HTMLAnchorElement>;
  refetch?: (
    variables?: Partial<Exact<{ id: string }>> | undefined,
  ) => Promise<ApolloQueryResult<GetConversationQuery>>;
};

export const Attachments: FunctionComponent<Props> = ({
  attachments,
  handleAttachmentClick,
  refetch,
}) => (
  <ul className="bg-grey-50 grid grid-cols-1 gap-5 p-2 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4">
    {attachments.map((attachment) => (
      <Attachment
        key={attachment.downloadUrl}
        attachment={attachment}
        refetch={refetch}
        handleAttachmentClick={handleAttachmentClick}
        removeable={attachment?.deleteOperation?.canPerform}
      />
    ))}
  </ul>
);
