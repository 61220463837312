import { useTranslation } from 'react-i18next';
import { Text } from '@pm/ui';
import { type DashboardItem } from '@pm/graphql';
import {
  type TreatmentCardFooterType,
  TreatmentCard,
} from '../TreatmentCard/TreatmentCard';

type ActionItemsProps = { actionItemCards: (DashboardItem | undefined)[] };

export const ActionItems = ({ actionItemCards }: ActionItemsProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview.ActionItems',
  });

  if (!actionItemCards.length) {
    return null;
  }

  return (
    <div className="flex-col space-y-m">
      <Text size="headingS">{t('Title')}</Text>
      {actionItemCards.map((card) => (
        <TreatmentCard
          key={card?.name}
          title={card?.title ?? ''}
          description={card?.description ?? ''}
          icon={card?.footerIcon ?? undefined}
          link={card?.footerLink ?? undefined}
          footerText={card?.footerText ?? ''}
          footerType={card?.footerType as TreatmentCardFooterType}
        />
      ))}
    </div>
  );
};
