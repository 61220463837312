import React from 'react';
import { CarouselContext } from './CarouselContext';
import { CarouselIndicators } from './CarouselIndicators';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';

interface CarouselProps extends React.ComponentPropsWithoutRef<'div'> {
  initialIndex?: number;
}

type CarouselElement = React.ElementRef<'div'>;

export const Carousel = React.forwardRef<CarouselElement, CarouselProps>(
  ({ initialIndex = 0, ...props }, forwardedRef) => {
    const [index, setCarouselIndex] = React.useState(initialIndex);
    const [itemsCount, setItemsCount] = React.useState(initialIndex);

    function next() {
      setCarouselIndex((index) => (itemsCount === index + 1 ? 0 : index + 1));
    }

    function previous() {
      setCarouselIndex((index) => (index - 1 < 0 ? itemsCount - 1 : index - 1));
    }

    function setIndex(index: number) {
      setCarouselIndex(clamp(index, 0, itemsCount - 1));
    }

    function handleItemsCount(count: number) {
      setItemsCount(count);
    }

    return (
      <CarouselContext.Provider
        value={{
          index,
          next,
          previous,
          setIndex,
          internal_setItemsCount: handleItemsCount,
          internal_itemsCount: itemsCount,
        }}
      >
        <div className="flex flex-col gap-4">
          <div className="flex min-h-[18rem] justify-between gap-4 rounded-lg bg-white shadow-md sm:min-h-[15rem]">
            <button
              className="hover:bg-grey-200 flex h-full items-center justify-center"
              onClick={previous}
              type="button"
              aria-label="Previous"
            >
              <ChevronLeftIcon className="text-grey-400 w-10" />
            </button>

            <div className="p-5" {...props} ref={forwardedRef} />

            <button
              className="hover:bg-grey-200  flex h-full items-center justify-center"
              onClick={next}
              type="button"
              aria-label="Next"
            >
              <ChevronRightIcon className="text-grey-400 w-10" />
            </button>
          </div>
          <div className="flex items-center justify-center">
            <CarouselIndicators />
          </div>
        </div>
      </CarouselContext.Provider>
    );
  },
);

Carousel.displayName = 'Carousel';

const clamp = (num: number, min: number, max: number) =>
  Math.min(Math.max(num, min), max);
