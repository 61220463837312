import { trackEvent } from '@pm/analytics';
import {
  ActionVariantEnum,
  GetQuestionnaireResponseQuery,
  QuestionnaireAnswerInput,
  useGoToPreviousStepOnQuestionnaireMutation,
  useSubmitQuestionnaireAnswersMutation,
} from '@pm/graphql';
import { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { usePatientProfile } from '../../hooks/profile';
import { useResetScroll } from '../../hooks/use-reset-scroll/useResetScroll';
import { OutcomePage } from './OutcomePage';
import { QuestionForm } from './QuestionForm';
import { QuestionPage } from './QuestionPage';

const SHARED_REFETCH_QUERIES = ['GetQuestionnaireResponse'];

export const Questionnaire = ({
  actionText,
  finishText,
  patientResponse,
  patientResponseLoading,
}: {
  actionText?: string;
  finishText: string;
  patientResponse?: GetQuestionnaireResponseQuery;
  patientResponseLoading: boolean;
}) => {
  const DASHBOARD_ROUTE = '/';
  const defaultOutcomeActions = [
    {
      label: finishText,
      variant: ActionVariantEnum.Primary,
      to: DASHBOARD_ROUTE,
    },
  ];
  const navigate = useNavigate();
  const resetScroll = useResetScroll();
  const { profile } = usePatientProfile();

  const [submitQuestionAnswers, { loading: answersSubmitting }] =
    useSubmitQuestionnaireAnswersMutation({
      onError(error) {
        console.error('Unable to submit questionnaire response: ', error);
      },
      refetchQueries: SHARED_REFETCH_QUERIES,
    });

  const [gotoPreviousStep, { loading: goToPreviousStepLoading }] =
    useGoToPreviousStepOnQuestionnaireMutation({
      onError(error) {
        console.error('Unable to submit questionnaire response: ', error);
      },
      refetchQueries: SHARED_REFETCH_QUERIES,
    });

  const isLoading =
    patientResponseLoading || answersSubmitting || goToPreviousStepLoading;

  const currentPatientResponse = patientResponse?.questionnaireResponse;
  const { currentStep, canGoBack, definition } = currentPatientResponse || {};
  const actions = currentStep?.actions?.length
    ? currentStep?.actions?.map(({ label, to, variant }) => ({
        label,
        variant,
        to,
      }))
    : null;

  const isOutcomePage =
    currentStep && currentStep.__typename === 'QuestionnaireOutcome';

  useEffect(() => {
    if (currentStep && !isOutcomePage && definition && profile) {
      trackEvent('Questionnaire Page Entered', {
        pageName: currentStep.name,
        questionnaireName: definition.name,
        brandMembership: profile.currentBrandMembership.name,
      });
    }
  }, [currentStep, definition, profile, isOutcomePage]);

  if (!currentPatientResponse || !currentStep || !definition) {
    return null;
  }

  const handleSubmitAnswers = async ({
    stepId,
    answers,
  }: {
    stepId: string;
    answers: QuestionnaireAnswerInput[];
  }) => {
    await submitQuestionAnswers({
      variables: {
        input: {
          answers,
          responseId: currentPatientResponse.id,
          stepId,
        },
      },
    });

    resetScroll();
  };

  if (isOutcomePage) {
    trackEvent(`${definition.name} Completed`, {
      definitionId: definition.id,
      responseId: currentPatientResponse.id,
    });

    if (currentStep.redirectTo) {
      return <Navigate to={currentStep.redirectTo} />;
    }

    return (
      <OutcomePage
        step={currentStep}
        actions={actions ?? defaultOutcomeActions}
      />
    );
  }

  return (
    <QuestionPage
      title={currentStep.title}
      description={currentStep.description}
      isLoading={isLoading}
      questions={currentStep.questions}
      actions={actions ?? []}
      onPreviousStep={() => {
        if (canGoBack) {
          gotoPreviousStep({
            variables: {
              input: {
                responseId: currentPatientResponse.id,
              },
            },
          });
          return;
        }

        navigate(DASHBOARD_ROUTE);
      }}
    >
      <QuestionForm
        actionText={actionText ?? undefined}
        questions={currentStep.questions}
        answers={currentPatientResponse.answers}
        onSubmit={async (answers) =>
          await handleSubmitAnswers({
            stepId: currentStep.id,
            answers: answers,
          })
        }
      />
    </QuestionPage>
  );
};
