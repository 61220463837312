import { ElementRef, forwardRef } from 'react';
import { type InputBaseProps, inputBase } from './base';
import { twMerge } from 'tailwind-merge';

type InputElement = ElementRef<'input'>;
type InputProps = React.HTMLProps<HTMLInputElement> & InputBaseProps;

export const Input = forwardRef<InputElement, InputProps>(
  ({ intent, disabled, name, className, ...props }, forwardedRef) => {
    return (
      <input
        {...props}
        name={name}
        aria-disabled={disabled}
        ref={forwardedRef}
        disabled={disabled}
        aria-invalid={intent === 'error'}
        className={twMerge(inputBase({ size: 'sm', intent }), className)}
      />
    );
  },
);

Input.displayName = 'Input';
