import { ApolloLink } from '@apollo/client/index.js';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';

type ErrorValue = {
  [key: string]: unknown;
};

const filtered_params = [
  'password',
  'email',
  'date_of_birth',
  'password_digest',
  'health_care_number',
  'phone_number',
  'results',
  'first_name',
  'last_name',
  'preferred_name',
  'message_content',
  'access_token',
  'one_time_access_token',
];

// handle errors in graphql that don't end up as exceptions
export const errorLink = onError(({ graphQLErrors, networkError }) => {
  //log user out if error is due to invalid token
  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    localStorage.removeItem('jwt');
    networkError = null; // do not send error to sentry
    window.location.reload();
  }

  if (graphQLErrors) {
    // Ignroe not found errors by default.
    const reportableErrors = graphQLErrors.filter((error) => {
      return error.extensions?.code !== 'not_found';
    });

    reportableErrors.forEach((error) => {
      const value = error.extensions?.value as ErrorValue;
      if (value && typeof value === 'object') {
        Object.entries(value).forEach(([key]) => {
          if (filtered_params.includes(key)) {
            value[key] = 'sensitive';
          }
        });
      }
    });

    reportableErrors.forEach((reportableError) => {
      Sentry.captureException(
        new Error(`GraphQL Error: ${JSON.stringify(reportableError)}`),
      );
    });
  }

  if (networkError) {
    Sentry.captureException(networkError);
  }
});

export const renewLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();
    const {
      response: { headers },
    } = context;

    if (headers) {
      const refreshToken = headers.get('X-New-Token');

      if (refreshToken) {
        localStorage.setItem('jwt', refreshToken);
      }
    }

    return response;
  });
});
