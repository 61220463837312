import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { ElementRef, forwardRef, useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { Icon } from '../../Icon/Icon';
import { TextFieldProps } from '../TextField/TextField';
import { Selector } from './Selector';

type SelectorProps = CheckboxPrimitive.CheckboxProps & {
  body: string;
  header?: string;
  disabled?: boolean;
  onChange?: CheckboxPrimitive.CheckboxProps['onCheckedChange'];
  freeText?: TextFieldProps;
};

export const MultiSelect = forwardRef<
  ElementRef<typeof CheckboxPrimitive.Root>,
  SelectorProps
>(
  (
    {
      disabled,
      value,
      required,
      header,
      body,
      freeText,
      onClick,
      onChange,
      ...props
    },
    forwardedRef,
  ) => {
    const id = useId();

    return (
      <Selector
        disabled={disabled}
        header={header}
        body={body}
        childId={id}
        freeText={freeText}
      >
        <CheckboxPrimitive.Root
          {...props}
          aria-disabled={disabled}
          id={id}
          className={twMerge(
            'shrink-0 justify-center items-center rounded-xs h-[20px] w-[20px] rdx-state-unchecked:border-2 rdx-state-unchecked:border-strong focus-visible:outline-none',
            header && 'mb-auto mt-[2px]',
            disabled
              ? 'bg-surface-disabled cursor-not-allowed'
              : 'rdx-state-checked:bg-primary',
          )}
          onClick={disabled ? (e) => e.preventDefault() : onClick}
          onCheckedChange={(checkedState) => onChange?.(checkedState)}
          ref={forwardedRef}
        >
          <CheckboxPrimitive.Indicator
            className={twMerge(
              'w-l',
              disabled ? 'text-content-disabled' : 'text-surface',
            )}
          >
            <Icon name="check" size="medium" />
          </CheckboxPrimitive.Indicator>
        </CheckboxPrimitive.Root>
      </Selector>
    );
  },
);

MultiSelect.displayName = 'MultiSelect';
