const calc = (m: number) => (n: number) => Math.round(n * m);

/**
 * Makes it easier to convert milliseconds to other units of time.
 */
export const ms = {
  seconds: calc(1e3),
  minutes: calc(6e4),
  hours: calc(36e5),
  days: calc(864e5),
  weeks: calc(6048e5),
  months: calc(26298e5),
  years: calc(315576e5),
} as const;
