import {
  BoltIcon,
  CheckCircleIcon,
  ExclamationCircleIcon,
  InformationCircleIcon,
} from '@heroicons/react/20/solid';
import clsx from 'clsx';
import * as React from 'react';

const ICON_MAP: Record<AlertVariant, typeof ExclamationCircleIcon> = {
  error: ExclamationCircleIcon,
  warning: BoltIcon,
  info: InformationCircleIcon,
  success: CheckCircleIcon,
} as const;

type AlertElement = React.ElementRef<'div'>;

type AlertVariant = 'error' | 'warning' | 'info' | 'success';

interface AlertProps extends React.ComponentPropsWithoutRef<'div'> {
  variant?: AlertVariant;
  title?: string;
}

const Alert = React.forwardRef<AlertElement, AlertProps>(
  ({ children, title, variant = 'info', ...rest }, forwardedRef) => {
    const Icon = ICON_MAP[variant];

    return (
      <div
        className={clsx('rounded-md p-4', {
          'bg-critical-light': variant === 'error',
          'bg-warning-light': variant === 'warning',
          'bg-info-light': variant === 'info',
          'bg-positive-light': variant === 'success',
        })}
        {...rest}
        ref={forwardedRef}
      >
        <div
          className={clsx('flex', {
            'text-critical-dark': variant === 'error',
            'text-warning-dark': variant === 'warning',
            'text-info-dark': variant === 'info',
            'text-positive-dark': variant === 'success',
          })}
        >
          <div className="flex-shrink-0">
            <Icon className="h-5 w-5" aria-hidden="true" />
          </div>
          <div className="ml-3">
            {title && <h3 className="text-sm font-medium">{title}</h3>}
            {children && <div className="mt-2 text-sm">{children}</div>}
          </div>
        </div>
      </div>
    );
  },
);

Alert.displayName = 'Alert';

export { Alert, type AlertProps };
