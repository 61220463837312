const ALPHANUMERIC_MATCHER = /[^a-z0-9]/gi;

const getFirstDelimiter = (format: string) => {
  // For simplicity, assumes that the delimiter is non-alphanumeric
  // and is the only type of delimiter in the string. If we want to get
  // fancy in the future and accept multiple delimiters, we could return
  // them all instead.
  const possibleDelimiters = format.match(ALPHANUMERIC_MATCHER);
  return possibleDelimiters ? possibleDelimiters[0] : '';
};

const splitValueIntoFormatGroups = (
  value: string,
  format: string,
  delimiter: string,
): string[] => {
  let numCharsFormatted = 0;

  return format.split(delimiter).map((group) => {
    const numCharsInGroup = group.length;
    const startIndex = numCharsFormatted;
    const endIndex = startIndex + numCharsInGroup;
    numCharsFormatted += numCharsInGroup;

    return value.slice(startIndex, endIndex);
  });
};

/**
 * Formats a value into the provided format. Note that it does not differentiate
 * between letters and numbers.
 *
 * @param {string} value unformatted health card number
 * @param {string} format
 * @returns {string} formatted value. If the number of characters in {value} is greater than
 * the number of characters in the format, returns all alphanumeric characters in the string.
 */
export const formatStringTo = (value: string, format: string): string => {
  const delimiter = getFirstDelimiter(format);
  const maxNumChars = format.replaceAll(delimiter, '').length;
  const cannotFormat = value.length > maxNumChars;

  if (cannotFormat) {
    return value;
  }

  const splitValue = splitValueIntoFormatGroups(value, format, delimiter);

  return splitValue.join(delimiter).trim();
};

/**
 * Strips all non-alphanumeric characters from the provided value
 *
 * @param {string} value
 * @returns {string} value, stripped of non-alphanumeric characters
 */
export const stripNonAlphanumericsFrom = (value = ''): string =>
  value.replace(ALPHANUMERIC_MATCHER, '');

/**
 * Checks if a string is empty, null, undefined or equal to No
 *
 * @param {string} value
 * @returns {boolean} true value is No or has no value
 */
export const isNegativeResponse = (
  value?: string | null | undefined,
): boolean => {
  if (value === null || value === undefined) {
    return true;
  }
  if (value === 'No' || value === '') {
    return true;
  }
  return false;
};

export const getNegativeResponse = (
  value?: string | null,
  fallback?: string,
) => (isNegativeResponse(value) ? fallback : value || fallback);
