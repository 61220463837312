import { ComponentPropsWithoutRef, useEffect, useRef } from 'react';

interface ScrollToProps extends ComponentPropsWithoutRef<'div'> {
  when: boolean;
  watch?: string;
  options?: ScrollIntoViewOptions;
}

export const ScrollTo = ({ when, watch, options, ...props }: ScrollToProps) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (when && containerRef.current) {
      const scrollOptions = options ?? {
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      };

      containerRef.current.scrollIntoView({ ...scrollOptions });
    }
  }, [options, when, watch]);

  return <div {...props} ref={containerRef} />;
};
