import { ElementRef, forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import { inputBase } from '../base';
import { type InputFieldProps } from '../types';
import { useId } from 'react';

const textarea = 'min-h-[120px] max-h-[400px]';

type TextareaElement = ElementRef<'textarea'>;
export type TextareaFieldProps = React.HTMLProps<HTMLTextAreaElement> &
  Omit<InputFieldProps, 'size'> &
  InputFieldProps & {
    name: string;
    id?: string;
    onChange?: (value: string) => void;
  };

export const TextareaField = forwardRef<TextareaElement, TextareaFieldProps>(
  (
    { id, name, intent, value, disabled, placeholder, onChange, ...restProps },
    forwardedRef,
  ) => {
    const generatedId = useId();
    const textareaStyles = twMerge(inputBase({ intent }), textarea);

    return (
      <textarea
        {...restProps}
        name={name}
        id={id || generatedId}
        ref={forwardedRef}
        aria-disabled={disabled}
        aria-invalid={intent === 'error'}
        className={textareaStyles}
        disabled={disabled}
        placeholder={placeholder}
        onChange={(event) => onChange && onChange(event.target.value)}
        value={value}
      />
    );
  },
);

TextareaField.displayName = 'TextareaField';
