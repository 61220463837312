import { Fragment, FunctionComponent, PropsWithChildren } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { PatientSummary } from './PatientSummary';

export type ModalProps = {
  patientId?: string;
  onDismiss: (value: boolean) => void;
  ariaLabel: string;
  isOpen: boolean;
  className?: string;
  narrow?: boolean;
};

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  children,
  patientId,
  onDismiss,
  ariaLabel,
  isOpen,
  className,
  narrow,
}) => {
  const computedClassName = clsx(
    'relative bg-white rounded-xl mx-auto max-h-screen overflow-y-auto',
    narrow ? 'max-w-md' : 'max-w-7xl p-6',
    narrow && patientId && 'first:[&>*]:pb-0 [&>*:nth-child(2)]:pt-0',
    className,
  );

  return (
    <Transition as={Fragment} appear show={isOpen}>
      {/* FIXME: There are no focusable elements when all content is loading.
      See: https://headlessui.com/react/dialog#managing-initial-focus */}
      <Dialog
        onClose={onDismiss}
        className="fixed inset-0 z-20"
        aria-label={ariaLabel}
      >
        <Dialog.Panel>
          <div className="flex min-h-screen items-center justify-center">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
            </Transition.Child>
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
              className="mx-6 w-screen sm:w-auto sm:min-w-[500px]"
            >
              <div className={computedClassName}>
                {patientId && <PatientSummary patientId={patientId} />}
                {children}
              </div>
            </Transition.Child>
          </div>
        </Dialog.Panel>
      </Dialog>
    </Transition>
  );
};
