import { useLocationReturnTo } from '../../hooks/use-location-return-to/use-location-return-to';
import { BackArrow } from '../BackArrow';

export function RouteLayoutWithBackLink({
  backTo = '..',
  children,
}: {
  backTo?: string;
  children?: React.ReactNode;
}) {
  const to = useLocationReturnTo({ fallback: backTo });

  return (
    <div className="flex flex-col space-y-5">
      <BackArrow lastPath={to} />
      {children}
    </div>
  );
}
