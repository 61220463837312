import { ValidationError } from '@pm/graphql';
import invariant from 'tiny-invariant';

/**
 * A generic error that can be thrown when a GraphQL mutation fails with errors in the response.
 * This error is used to differentiate between errors that are caused by the user and errors that
 * are caused by the system.
 *
 * @example Response
 * {
 *  "data": {
 *   "updatePatient": {
 *     "errors": ['First name is required', 'Last name is required'],
 *   },
 * },
 */
export class UserError extends Error {
  private errors: string[] | ValidationError[];

  constructor({ errors }: { errors: string[] | ValidationError[] }) {
    super('Errors encountered');
    this.name = 'UserError';
    this.errors = errors;
  }
}

/**
 * A function that detects user errors in a GraphQL mutation response and throws if present
 * @param responseData The response data from a GraphQL mutation
 * @param errorGetter A function that takes the response data and returns an array of errors
 */
export function detectUserErrors<T extends { [key: string]: unknown }>(
  responseData?: T | null,
  errorGetter: (
    res: T,
  ) => string[] | ValidationError[] | undefined | null = () => undefined,
): asserts responseData is NonNullable<typeof responseData> {
  invariant(responseData, 'No data from server.');

  const errors = errorGetter(responseData);
  if (errors && errors.length > 0) {
    throw new UserError({ errors });
  }
}
