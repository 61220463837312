import { Blob, DirectUpload } from '@pm/upload';

export class Uploader {
  private directUpload: DirectUpload;
  private setProgress?: (progress: number) => void;

  constructor(
    file: File,
    URL: string,
    setProgress?: (progress: number) => void,
  ) {
    this.directUpload = new DirectUpload(file, URL, this);
    this.setProgress = setProgress;
  }

  upload(callback: (blob: Blob) => void) {
    this.directUpload.create((error, blob) => {
      if (!error) callback(blob);
    });
  }

  directUploadWillStoreFileWithXHR(request: XMLHttpRequest) {
    request.upload.addEventListener(
      'progress',
      (event) =>
        this.setProgress && this.setProgress(event.loaded / event.total),
    );
  }
}
