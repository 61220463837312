import * as DialogPrimitive from '@radix-ui/react-dialog';
import { MouseEventHandler, ReactNode, createContext, useContext } from 'react';
import { Drawer } from 'vaul';
import { Icon } from '../Icon/Icon';
import { button } from '../Button/Button';

export type BottomDrawerProps = {
  children?: ReactNode;
  title?: string;
};

const interactiveOutlineClassName =
  'rounded-xs hover:cursor-pointer focus-visible:outline-none focus-visible:ring focus-visible:ring-inset';

const bottomDrawerContentClassName = `bg-surface flex flex-col fixed bottom-0 left-0 right-0 rounded-t-pill pt-xxs pb-s px-xxs max-h-[90vh] border ${interactiveOutlineClassName}`;

const BottomDrawerPropsContext = createContext<{
  title?: string;
}>({
  title: '',
});

export const BottomDrawer = ({ children, title }: BottomDrawerProps) => {
  return (
    <BottomDrawerPropsContext.Provider value={{ title }}>
      <Drawer.Root>{children}</Drawer.Root>
    </BottomDrawerPropsContext.Provider>
  );
};

const BottomDrawerTrigger = ({
  children,
}: DialogPrimitive.DialogTriggerProps) => {
  return (
    <Drawer.Trigger aria-label="Open Bottom Drawer Menu" asChild>
      {children}
    </Drawer.Trigger>
  );
};

const BottomDrawerContent = (props: DialogPrimitive.DialogContentProps) => {
  const { title } = useContext(BottomDrawerPropsContext);

  const { children, onAnimationEnd, ...restProps } = props;

  return (
    <Drawer.Portal>
      <Drawer.Overlay
        aria-label="Bottom Drawer Overlay"
        className="fixed inset-0 bg-overlay/40"
      />
      <Drawer.Content
        aria-modal
        aria-label="Bottom Drawer Content"
        className={bottomDrawerContentClassName}
        {...restProps}
      >
        <div
          className={`flex items-center w-full ${
            title ? 'justify-between' : 'justify-end'
          }`}
        >
          {title && (
            <Drawer.Title
              aria-label="Bottom Drawer Title"
              className="text-heading-s pl-m"
            >
              {title}
            </Drawer.Title>
          )}
          <Drawer.Close
            aria-label="Close Bottom Drawer"
            className={button({ intent: 'ghost', size: 'small' })}
          >
            <Icon name="close" size="large" />
          </Drawer.Close>
        </div>
        <div className="overflow-y-auto">{children}</div>
      </Drawer.Content>
    </Drawer.Portal>
  );
};

const BottomDrawerItem = (
  props: React.HTMLAttributes<HTMLDivElement | HTMLButtonElement> & {
    closeOnClick?: boolean;
    onClick?: MouseEventHandler;
  },
) => {
  const { closeOnClick, ...restProps } = props;
  const itemClassName = `font-normal w-full px-m py-s text-left`;
  const interactiveClassName = `hover:bg-hover-opacity active:bg-active-opacity ${interactiveOutlineClassName}`;

  return closeOnClick ? (
    <BottomDrawer.Close
      {...restProps}
      className={`${itemClassName} ${interactiveClassName}`}
    />
  ) : (
    <div {...restProps} className={itemClassName} />
  );
};

const BottomDrawerClose = (props: DialogPrimitive.DialogCloseProps) => {
  return <Drawer.Close className={interactiveOutlineClassName} {...props} />;
};

BottomDrawer.Trigger = BottomDrawerTrigger;
BottomDrawer.Content = BottomDrawerContent;
BottomDrawer.Item = BottomDrawerItem;
BottomDrawer.Close = BottomDrawerClose;
