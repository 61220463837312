import { getWindow } from './window';

/**
 * Used for mocking the window object.
 */
export interface CustomGtagWindow {
  gtag: (
    command: 'get',
    targetId: string,
    fieldName: string,
    callback?: (field: string | undefined) => void,
  ) => void;
}

export const GtagHandlerStatus = {
  Initialized: 'initialized',
  Loaded: 'loaded',
  Failed: 'failed',
} as const;

export type SupportedGtagAttribute =
  | 'client_id'
  | 'session_id'
  | 'session_number';

type HandlerStatus = (typeof GtagHandlerStatus)[keyof typeof GtagHandlerStatus];

/**
 * The GtagHandler is used to get the clientId from google tag manager.
 */
export class GtagHandler {
  /**
   * The target is the name of the Google Analytics property that you want to get the clientId for.
   */
  public measurementId: string | null;
  private attributes: Record<SupportedGtagAttribute, string | null> = {
    /**
     * The clientId is the unique identifier for the user.
     */
    client_id: null,
    /**
     * The sessionId is the unique identifier for the session.
     */
    session_id: null,
    /**
     * The sessionNumber is the number of the session.
     */
    session_number: null,
  };

  public status: HandlerStatus = GtagHandlerStatus.Initialized;
  public statuses: Record<SupportedGtagAttribute, HandlerStatus> = {
    client_id: GtagHandlerStatus.Initialized,
    session_id: GtagHandlerStatus.Initialized,
    session_number: GtagHandlerStatus.Initialized,
  };

  constructor(measurementId?: string) {
    this.measurementId = measurementId || null;

    // If the measurementId is not provided, the GtagHandler will not be able to get the clientId.
    if (!measurementId) {
      this.statuses = {
        client_id: GtagHandlerStatus.Failed,
        session_id: GtagHandlerStatus.Failed,
        session_number: GtagHandlerStatus.Failed,
      };
      return;
    }

    this.obtainAllGtagAttributes();
  }

  public get gtagAttributes() {
    return this.attributes;
  }

  private async obtainAllGtagAttributes() {
    this.obtainGtagClientId();
    this.obtainGtagSessionId();
    this.obtainGtagSessionNumber();
  }

  private markStatusAs(
    attribute: SupportedGtagAttribute,
    status: HandlerStatus,
  ) {
    this.statuses[attribute] = status;
  }

  private obtainGtagClientId() {
    this.obtainGtagAttribute('client_id');
  }

  private obtainGtagSessionId() {
    this.obtainGtagAttribute('session_id');
  }

  private obtainGtagSessionNumber() {
    this.obtainGtagAttribute('session_number');
  }

  private obtainGtagAttribute(attribute: SupportedGtagAttribute) {
    if (!this.measurementId) {
      return;
    }

    getWindow().gtag('get', this.measurementId, attribute, (value) => {
      const hasValue = !!value;
      this.markStatusAs(
        attribute,
        hasValue ? GtagHandlerStatus.Loaded : GtagHandlerStatus.Failed,
      );

      if (
        (hasValue && typeof value === 'string') ||
        typeof value === 'number'
      ) {
        this.attributes[attribute] = value;
      }
    });
  }
}
