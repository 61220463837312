import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  as?: 'div' | 'p';
  className?: string;
};

export const CardText: FunctionComponent<PropsWithChildren<Props>> = ({
  as: Component = 'div',
  children,
  className,
}) => (
  <Component className={clsx(className, 'text-grey-500')}>{children}</Component>
);
