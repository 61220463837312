import clsx from 'clsx';
import {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useId,
  useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';

import { selectStyles } from '../forms/shared-input-styles';
import HookFormBoolDropdown from './HookFormBoolDropdown';

export interface DropdownProps {
  name: string;
  label?: string;
  placeholder?: string;
  className?: string;
  options: DropdownOption[];
  disabled?: boolean;
  required?: boolean;
}

export type DropdownOption = {
  key: string;
  displayValue: string;
};

export const toDropdownOptions = (
  options: Record<string, string>,
): DropdownOption[] => {
  const dropdownOptions: DropdownOption[] = [];
  Object.entries(options).forEach(([key, value]) => {
    dropdownOptions.push({ key, displayValue: value });
  });

  return dropdownOptions;
};

const Wrapper: FunctionComponent<PropsWithChildren<DropdownProps>> = ({
  name,
  label,
  placeholder,
  className,
  options,
  required = false,
  disabled,
  children,
}) => {
  const id = useId();
  const NO_VALUE = '';

  const {
    field,
    fieldState: { error, isTouched },
  } = useController({ name });

  // FIXME: We should be using field to set the value, but this causes issues since we're manually managing
  // so much of the form when we shouldn't. We can't re-write this since it would cascade cause issues to all
  // consuming forms.
  const { setValue } = useFormContext();
  const { value } = field;

  // FIXME: Not sure why we need local state here since we can get the value from useController
  const [selectedValue, setSelectedValue] = useState(
    (options.length && value) ?? options[0].key,
  );

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleChange = (event: { target: { value: string } }): void => {
    setSelectedValue(event.target.value);
    setValue(name, event.target.value, {
      shouldTouch: true,
      shouldDirty: true,
      shouldValidate: true,
    });
  };

  const showError = error !== undefined && isTouched;

  return (
    <div className="space-y-2">
      <label
        htmlFor={id}
        className={clsx('text-grey-500 mb-0 text-sm font-medium', {
          "after:ml-1 after:text-red-500 after:content-['*']": required,
        })}
      >
        {label}
      </label>
      <select
        {...field}
        id={id}
        aria-invalid={showError ? true : undefined}
        className={clsx(selectStyles, className)}
        onChange={handleChange}
        value={selectedValue}
        disabled={disabled}
      >
        {placeholder && (
          <option disabled value={NO_VALUE}>
            {placeholder}
          </option>
        )}
        {children}
      </select>
      {showError && (
        <p
          className={clsx(
            'text-critical-dark mb-3 mt-1 border-none text-sm font-medium',
          )}
        >
          {error.message}
        </p>
      )}
      <div className="mb-4"></div>
    </div>
  );
};

export const HookFormDropdown: FunctionComponent<DropdownProps> = (
  props: DropdownProps,
) => {
  return (
    <Wrapper {...props}>
      {props.options.map(({ key, displayValue }) => (
        <option key={key} value={key}>
          {displayValue}
        </option>
      ))}
    </Wrapper>
  );
};

export { Wrapper, HookFormBoolDropdown };
