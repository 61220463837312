import { formatStringTo, stripNonAlphanumericsFrom } from './formatter';

export const HEALTH_CARD_FORMATS: Record<string, string> = {
  // Alberta
  1: '00000-0000',
  // British Columbia
  2: '0000 000 000',
  // Manitoba
  3: '000000',
  // New Brunswick
  4: '000-000-000',
  // Newfoundland and Labrador
  5: '000 000 000 000',
  // Northwest Territories
  6: 'X0000000',
  // Nova Scotia
  7: '0000 000 000',
  // Nunavut
  8: '000000000',
  // Ontario
  9: '0000 000 000 XX',
  // PEI
  10: '00000000',
  // Quebec
  11: 'XXXX 0000 0000',
  // Saskatchewan
  12: '000 000 000',
  // Yukon
  13: '000-000-000',
};

export const MAX_HEALTH_CARE_NUMBER_LENGTH = Math.floor(
  Math.max(...Object.values(HEALTH_CARD_FORMATS).map((word) => word.length)) *
    1.25,
);

/**
 * Formats health card number based on province of coverage.
 *
 * @param {string} healthCardNumber unformatted health card number
 * @param {string | null | undefined} provinceOfCoverageId province of coverage ID, used to determine
 * what format to apply to the health card number
 * @returns {string} formatted health card number. If no matching format is found, returns all alphanumeric
 * characters with no format.
 */
export const formatHealthCardNumber = (
  healthCardNumber = '',
  format = '',
): string => {
  const alphanumericHealthCardNumber =
    stripNonAlphanumericsFrom(healthCardNumber);

  return formatStringTo(alphanumericHealthCardNumber, format);
};

/**
 * Retrieves the health card number format for the provided province.
 *
 * @param {string} provinceOfCoverageId province of coverage ID
 * @returns {string} health card number for the provided province.
 * If the province of coverage is an empty string, returns an empty string.
 */
export const healthCardNumberFormatForProvince = (
  provinceOfCoverageId: string | null | undefined,
): string => {
  if (provinceOfCoverageId == null) {
    return '';
  }

  return HEALTH_CARD_FORMATS[provinceOfCoverageId] ?? '';
};
