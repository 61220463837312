import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Modal, Text, useIsMobile } from '@pm/ui';
import { ModalContent } from './ModalContent';
import { TreatmentCardItem } from '../../hooks/additional-treatments';
import AdditionalTreatmentCTA from '../../TreatmentOverview/AdditionalTreatmentCTA';

type DoxyPepInfoModal = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  treatment: TreatmentCardItem;
};

export const DoxyPepInfoModal = ({
  open,
  onOpenChange,
  treatment,
}: DoxyPepInfoModal) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'DoxyPepTreatment.Info',
  });
  const navigate = useNavigate();
  const { isMobile } = useIsMobile();

  const handleModalClose = () => {
    navigate('/');
    onOpenChange(false);
  };

  return (
    <Modal.Root open={open} onOpenChange={handleModalClose}>
      <Modal.Content title={t('HeaderTitle')}>
        <Modal.Body>
          <ModalContent />
        </Modal.Body>

        <Modal.Actions>
          {treatment.getStarted && (
            <>
              <div
                className={
                  isMobile
                    ? 'order-last m-auto py-xs'
                    : 'absolute m-auto w-1/2 left-[16px] bottom-[16px]'
                }
              >
                <Text color="contentSubdued">{t('FreeShippingFooter')}</Text>
              </div>
              <AdditionalTreatmentCTA
                treatment={treatment}
                buttonWidth="full"
              />
            </>
          )}
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  );
};
