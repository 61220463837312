import { createContext, useContext } from 'react';
import * as Menubar from '@radix-ui/react-menubar';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import { useIsMobile } from '../../hooks/useIsMobile';
import { OverflowMenu } from '../OverflowMenu';
import { BottomDrawer, BottomDrawerProps } from '../BottomDrawer/BottomDrawer';

const ResponsiveMenuContext = createContext<{ isMobile: boolean }>({
  isMobile: false,
});

export const ResponsiveMenu = (
  props: Menubar.MenubarProps | BottomDrawerProps,
) => {
  const { isMobile } = useIsMobile();

  return (
    <ResponsiveMenuContext.Provider value={{ isMobile }}>
      {isMobile ? <BottomDrawer {...props} /> : <OverflowMenu {...props} />}
    </ResponsiveMenuContext.Provider>
  );
};

const ResponsiveMenuTrigger = (
  props: Menubar.MenubarTriggerProps | DialogPrimitive.DialogTriggerProps,
) => {
  const { isMobile } = useContext(ResponsiveMenuContext);
  return isMobile ? (
    <BottomDrawer.Trigger {...props} />
  ) : (
    <OverflowMenu.Trigger {...props} />
  );
};

const ResponsiveMenuContent = (
  props: Menubar.MenubarContentProps | DialogPrimitive.DialogContentProps,
) => {
  const { isMobile } = useContext(ResponsiveMenuContext);
  return isMobile ? (
    <BottomDrawer.Content {...props} />
  ) : (
    <OverflowMenu.Content {...props} />
  );
};

const ResponsiveMenuItem = (
  props: Menubar.MenubarItemProps & { closeOnClick?: boolean },
) => {
  const { isMobile } = useContext(ResponsiveMenuContext);
  const { onSelect, ...drawerProps } = props;
  const { closeOnClick, ...menuProps } = props;

  return isMobile ? (
    <BottomDrawer.Item {...drawerProps} />
  ) : (
    <OverflowMenu.Item {...menuProps} />
  );
};

const ResponsiveMenuSeparator = (props: Menubar.MenubarSeparatorProps) => {
  const { isMobile } = useContext(ResponsiveMenuContext);
  return isMobile ? (
    <>{props.children}</>
  ) : (
    <OverflowMenu.Separator {...props} />
  );
};

ResponsiveMenu.Trigger = ResponsiveMenuTrigger;
ResponsiveMenu.Content = ResponsiveMenuContent;
ResponsiveMenu.Item = ResponsiveMenuItem;
ResponsiveMenu.Separator = ResponsiveMenuSeparator;
