import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { cva, type VariantProps } from 'class-variance-authority';
import { WithChildren } from '../../../types/component.types';

const radioGroupRoot = cva('', {
  variants: {
    space: {
      xxs: 'space-y-xxs',
      s: 'space-y-s',
      m: 'space-y-m',
    },
  },
  defaultVariants: {
    space: 's',
  },
});

type CombinedProps = WithChildren &
  VariantProps<typeof radioGroupRoot> &
  Omit<RadioPrimitive.RadioGroupProps, 'className'>;

export type RadioGroupProps = CombinedProps & {
  defaultValue?: string;
  onChange?: (value: string) => void;
};

export const RadioGroup = ({
  defaultValue,
  children,
  onChange,
  value,
  space = 's',
  ...primitiveProps
}: RadioGroupProps) => {
  if (!primitiveProps['aria-label'] && !primitiveProps['aria-labelledby']) {
    throw new Error(
      'RadioGroup requires an aria-label or aria-labelledby prop.',
    );
  }

  return (
    <RadioPrimitive.Root
      {...primitiveProps}
      value={value}
      className={radioGroupRoot({ space })}
      defaultValue={defaultValue}
      onValueChange={(value) => onChange?.(value)}
    >
      {children}
    </RadioPrimitive.Root>
  );
};
