import { cva } from 'class-variance-authority';

export const inlineMesasageStyles = cva(
  'rounded-s text-body-l flex box-border gap-x-s pl-m pr-xxs',
  {
    variants: {
      intent: {
        default: 'bg-neutral-subtle',
        success: 'bg-positive-subtle',
        warning: 'bg-warning-subtle',
        error: 'bg-negative-subtle',
        info: 'bg-info-subtle',
      },
    },
    defaultVariants: {
      intent: 'default',
    },
  },
);
