import { ReactNode } from 'react';
import { type ChipProps } from './Chip';

type ChipWrapperProps = Omit<ChipProps, 'text' | 'selected'> & {
  className: string;
  children: ReactNode;
};

/**
 * Wraps Chip children based on the pattern specified, where 'clickable' Chips are buttons.
 */
export const ChipWrapper = ({
  pattern,
  children,
  className,
  onClick,
}: ChipWrapperProps) => {
  switch (pattern) {
    case 'clickable':
      return (
        <button onClick={onClick} type="button" className={className}>
          {children}
        </button>
      );
    case 'deletable':
      return <div className={className}>{children}</div>;
  }
};
