import clsx from 'clsx';
import React from 'react';
import { useCarousel } from './CarouselContext';

type CarouselIndicatorsElement = React.ElementRef<'div'>;
type CarouselIndicatorsProps = React.ComponentPropsWithoutRef<'div'>;

export const CarouselIndicators = React.forwardRef<
  CarouselIndicatorsElement,
  CarouselIndicatorsProps
>((props, forwardedRef) => {
  const { index, internal_itemsCount, setIndex } = useCarousel();

  return (
    <div {...props} className="text-grey-400 flex gap-1" ref={forwardedRef}>
      {Array.from({ length: internal_itemsCount }).map((_, i) => {
        const isCurrent = i === index;
        return (
          <button
            className={clsx('transition-colors', {
              'text-brand-primary-500': isCurrent,
            })}
            onClick={() => setIndex(i)}
            type="button"
            key={i}
            aria-label={isCurrent ? 'Current' : `Go to ${i + 1}`}
          >
            {isCurrent ? '●' : '○'}
          </button>
        );
      })}
    </div>
  );
});

CarouselIndicators.displayName = 'CarouselIndicators';
