import { FunctionComponent, PropsWithChildren, useId } from 'react';
import { useFormContext } from 'react-hook-form';
import clsx from 'clsx';
import { checkboxStyles } from '../forms/shared-input-styles';

type Props = {
  name: string;
  className?: string;
  value?: string;
};

export const HookFormCheckbox: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  name,
  className,
  value,
}) => {
  const { register } = useFormContext();
  const id = useId();

  return (
    <div className="flex items-center gap-4">
      <input
        {...register(name)}
        id={id}
        type="checkbox"
        className={clsx(checkboxStyles, className)}
        value={value}
      />
      <label htmlFor={id} className="text-grey-500">
        {children}
      </label>
    </div>
  );
};
