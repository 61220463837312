import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type LoadingSpinnerProps = {
  size?: SizeProp;
};

export const LoadingSpinner = ({ size = '6x' }: LoadingSpinnerProps) => {
  return (
    <FontAwesomeIcon
      icon={faCircleNotch}
      spin
      size={size}
      aria-label="loading"
    />
  );
};
