import { type QuestionnaireTextInputQuestion as GQLQuestionnaireTextQuestion } from '@pm/graphql';
import { CardText } from '../CardText';
import { HookFormFlowbiteField } from '../StyledField/HookFormFlowbiteField';
import { QuestionnaireSection } from './QuestionnaireSection';
import { QuestionnaireMarkdown } from '../QuestionnaireMarkdown/QuestionnaireMarkdown';

type QuestionnaireTextInputQuestionProps = GQLQuestionnaireTextQuestion;

export const QuestionnaireTextInputQuestion = ({
  id,
  title,
  description,
  maxLength,
}: QuestionnaireTextInputQuestionProps) => (
  <QuestionnaireSection>
    <CardText className="text-grey-900 text-xl">{title}</CardText>
    {description && (
      <CardText>
        <QuestionnaireMarkdown>{description}</QuestionnaireMarkdown>
      </CardText>
    )}
    <HookFormFlowbiteField
      name={id}
      bottomMargin={false}
      characterCount={maxLength}
    />
  </QuestionnaireSection>
);
