export const keyBy = <Item, Key = keyof Item>(
  arr: Item[],
  key: Key,
): Record<string, Item> =>
  arr.reduce(
    (acc, item) => {
      // @ts-expect-error - we know that the key exists on the item
      const keyValue = item[key];
      if (keyValue) {
        acc[keyValue] = item;
      }

      return acc;
    },
    {} as Record<string, Item>,
  );

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<Item>(arr: Item[], fn: (item: Item) => any) {
  return arr.reduce<Record<string, Item[]>>((prev, curr) => {
    const groupKey = fn(curr);
    const group = prev[groupKey] || [];
    group.push(curr);
    return { ...prev, [groupKey]: group };
  }, {});
}

/**
 * Returns true if the subsetArr is a subset of the superset
 */
export const isSubset = <T>(subsetArr: T[], supersetArr: T[]): boolean =>
  subsetArr.every((value) => supersetArr.includes(value));

/**
 * Returns true if the subsetArr is a partial subset of the superset
 */
export const isPartialSubset = <T>(subsetArr: T[], supersetArr: T[]): boolean =>
  subsetArr.some((value) => supersetArr.includes(value));
