import * as ExpandableList from '../ListGroup';
import { LoadingSpinner } from '../LoadingSpinner';
import { InView } from 'react-intersection-observer';
import { formatDateToLongFormYear } from '../../utilities/dateUtilities';
import { useTranslation } from 'react-i18next';
import { SupportedLanguage } from '../../types';
import { LabRequisitionAttachments } from '@pm/core';

export type LabRequisitionItem = {
  id: string;
  readByPatientAt: string | null;
  requisitionType: string | null;
  sentAt: Date | null;
  notes: string | null;
  attachments: Array<{
    id: string;
    downloadUrl: string;
    filename: string;
  }>;
};

interface Props {
  labRequisitions: LabRequisitionItem[];
  loading: boolean;
  hasNextPage: boolean;
  onLoadMore: () => void;
}

export const LabRequisitionsList = ({
  labRequisitions,
  loading,
  hasNextPage,
  onLoadMore,
}: Props) => {
  const { i18n } = useTranslation();
  const { t } = useTranslation('lab-requisitions', {
    keyPrefix: 'LabRequisitions',
  });

  if (labRequisitions.length === 0) {
    return null;
  }

  return (
    <>
      <ExpandableList.ListGroup divided>
        {labRequisitions.map(
          ({
            id,
            requisitionType,
            sentAt,
            notes,
            attachments,
            readByPatientAt,
          }) => (
            <ExpandableList.ListGroupItemExpandable key={id}>
              <ExpandableList.ListGroupItemExpandableTrigger>
                {requisitionType && (
                  <h3 className="text-grey-900 font-medium">
                    {t(`RequisitionTypes.${requisitionType}`, {
                      defaultValue: requisitionType,
                    })}
                  </h3>
                )}
                {sentAt && (
                  <div className="text-grey-500">
                    {formatDateToLongFormYear(
                      sentAt,
                      i18n.language as SupportedLanguage,
                    )}
                  </div>
                )}
              </ExpandableList.ListGroupItemExpandableTrigger>
              {notes && (
                <ExpandableList.ListGroupItemExpandableContent>
                  <div className="text-grey-500 space-y-4">{notes}</div>
                </ExpandableList.ListGroupItemExpandableContent>
              )}
              <ExpandableList.ListGroupItemExpandableContent>
                <div className="flex justify-end gap-2">
                  <LabRequisitionAttachments
                    attachments={attachments}
                    hasBeenViewed={!!readByPatientAt}
                    labReqId={id}
                    variant="link"
                  />
                </div>
              </ExpandableList.ListGroupItemExpandableContent>
            </ExpandableList.ListGroupItemExpandable>
          ),
        )}
      </ExpandableList.ListGroup>
      {hasNextPage && (
        <InView
          as="div"
          className="text-brand-primary-500 flex justify-center pt-4"
          onChange={(inView) => {
            if (inView) {
              onLoadMore();
            }
          }}
        >
          {loading && <LoadingSpinner size="2x" />}
        </InView>
      )}
    </>
  );
};
