import { type ReactNode } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import React from 'react';

const sidePanelContent = cva('w-full text-body-l p-xl');

type PanelContentElement = React.ElementRef<'div'>;
type SidePanelContentProps = VariantProps<typeof sidePanelContent> & {
  children: ReactNode;
  shouldFillContainer?: boolean;
};

/**
 * Wrapper for the content section of SidePanel.
 */
export const Content = React.forwardRef<
  PanelContentElement,
  SidePanelContentProps
>(({ shouldFillContainer = true, ...props }, forwardedRef) => {
  return (
    <div
      {...props}
      className={twMerge(
        sidePanelContent(),
        shouldFillContainer && 'flex-1 overflow-y-auto',
      )}
      ref={forwardedRef}
    />
  );
});

Content.displayName = 'SidePanelContent';
