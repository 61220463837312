import { FunctionComponent, PropsWithChildren } from 'react';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, type ButtonProps } from '../Button';
import { useFormContext } from 'react-hook-form';
import { hasOnlyServerError, hasServerError } from '../../utilities/errors';

type Props = Pick<
  ButtonProps,
  'variant' | 'className' | 'disabled' | 'wide'
> & {
  loading?: boolean; // in case you want to control loading state from the parent
  disableAfterSubmit?: boolean;
};

export const HookFormSubmitButton: FunctionComponent<
  PropsWithChildren<Props>
> = ({
  disabled,
  className,
  variant,
  wide,
  loading,
  disableAfterSubmit = false,
  children,
}) => {
  const {
    formState: { isSubmitting, isValid, isSubmitSuccessful, errors },
  } = useFormContext();
  const isSuccessfullySubmitted = isSubmitSuccessful && !hasServerError(errors);

  const allFieldsValid = isValid || hasOnlyServerError(errors);

  const isDisabled =
    isSubmitting ||
    !allFieldsValid ||
    disabled ||
    (disableAfterSubmit && isSuccessfullySubmitted);

  const isLoading = loading ?? isSubmitting;

  return (
    <Button
      type="submit"
      className={className}
      title={!isValid ? 'Please fix the errors shown above' : undefined}
      data-cy="submit"
      data-testid="submit"
      disabled={isDisabled}
      variant={variant}
      wide={wide}
    >
      {isLoading ? (
        <FontAwesomeIcon icon={faCircleNotch} spin className="h-6 w-6" />
      ) : (
        children
      )}
    </Button>
  );
};
