import { useRef } from 'react';
import { useToggle } from '@pm/core';

/**
 * Utility hook to show a confirmation modal and wait for the user to confirm or cancel.
 *
 * @example
 * ```
 * const { confirm } = useConfirmationModal();
 *
 * const handleConfirmation = async () => {
 *  const isConfirmed = await confirm();
 *
 *  if (isConfirmed) {
 *    // do something
 *  } else {
 *    // do something else
 *  }
 * }
 * ```
 */
export const useConfirmationModal = () => {
  const [showConfirmationModal, handlers] = useToggle();
  const resolveConfirmationPromise = useRef<(value: boolean) => void>();
  const confirmPromise = useRef<Promise<boolean>>();

  const confirm = () => {
    handlers.on();
    confirmPromise.current = new Promise((resolve) => {
      resolveConfirmationPromise.current = resolve;
    });

    return confirmPromise.current;
  };

  const handleConfirm = () => {
    resolveConfirmationPromise.current?.(true);
    handlers.off();
  };

  const handleClose = () => {
    resolveConfirmationPromise.current?.(false);
    handlers.off();
  };

  const handleState = () => {
    handlers.toggle();
  };

  return {
    confirm,
    handleConfirm,
    handleClose,
    showConfirmationModal,
    handleState,
  };
};
