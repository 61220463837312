import * as React from 'react';

/* -------------------------------------------------------------------------------------------------
 * Well
 * -----------------------------------------------------------------------------------------------*/

type WellElement = React.ElementRef<'div'>;
type WellProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'className'>;

export const Well = React.forwardRef<WellElement, WellProps>(
  (props, forwardedRef) => {
    return (
      <div
        {...props}
        className="border-grey-300 flex flex-col gap-6 rounded-xl border p-4 bg-white"
        ref={forwardedRef}
      />
    );
  },
);

Well.displayName = 'Well';
