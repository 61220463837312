import { useTranslation } from 'react-i18next';
import { Button, Modal } from '@pm/ui';

type BackButtonModalProps = {
  showConfirmationModal: boolean;
  handleState: () => void;
  handleClose: () => void;
  handleConfirm: () => void;
};

export const BackButtonModal = ({
  showConfirmationModal,
  handleState,
  handleClose,
  handleConfirm,
}: BackButtonModalProps) => {
  const { t } = useTranslation(['global', 'treatments']);

  return (
    <Modal.Root open={showConfirmationModal} onOpenChange={handleState}>
      <Modal.Content
        title={t('treatments:PrepTreatment.Confirmation.Title')}
        aria-label="Confirm change of treatment plan"
      >
        <Modal.Body>
          {t('treatments:PrepTreatment.Confirmation.Text')}
        </Modal.Body>
        <Modal.Actions>
          <Button
            intent="secondary"
            width="full"
            aria-label="No"
            onClick={handleClose}
          >
            {t('global:No')}
          </Button>
          <Button width="full" aria-label="Yes" onClick={handleConfirm}>
            {t('global:Yes')}
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal.Root>
  );
};
