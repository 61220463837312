import {
  ElementRef,
  ComponentPropsWithoutRef,
  forwardRef,
  useEffect,
} from 'react';
import { useAffirm } from './useAffirm';
import clsx from 'clsx';

type AffirmElement = ElementRef<'p'>;
interface AffirmProps extends ComponentPropsWithoutRef<'p'> {
  intent: 'affirm-as-low-as' | 'affirm-site-modal' | 'affirm-product-modal';
  pageType:
    | 'product'
    | 'category'
    | 'payment'
    | 'banner'
    | 'marketplace'
    | 'cart'
    | 'search'
    | 'homepage'
    | 'landing'
    | 'other';
  amountInCents: number;
  learnMore: boolean;
}

export const Affirm = forwardRef<AffirmElement, AffirmProps>(
  ({ pageType, amountInCents, intent, learnMore, ...props }, forwardedRef) => {
    const { refresh } = useAffirm();

    useEffect(() => {
      refresh();
    }, [refresh, pageType, amountInCents]);

    return (
      <p
        {...props}
        className={clsx(
          intent,
          !learnMore && '[&>a.affirm-modal-trigger]:hidden',
          'text-sm',
        )}
        data-page-type={pageType}
        data-amount={amountInCents}
        data-learnmore-show={true}
        ref={forwardedRef}
      />
    );
  },
);

Affirm.displayName = 'Affirm';
