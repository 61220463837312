import { QuestionnaireQuestionResource } from '@pm/graphql';
import { Modal } from '@pm/ui';

export const QuestionnaireResourcesModal = ({
  resource,
  showModal,
  setShowModal,
}: {
  resource: QuestionnaireQuestionResource;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
}) => {
  return (
    <Modal.Root open={showModal} onOpenChange={setShowModal}>
      <Modal.Content title={resource.title ?? ''} showXinDialog={true}>
        <Modal.Body>{resource.value}</Modal.Body>
      </Modal.Content>
    </Modal.Root>
  );
};
