import { QuestionnaireQuestionFragment } from '@pm/graphql';
import { checkboxQuestionSchema } from './checkboxQuestionSchema';
import { dateInputQuestionSchema } from './dateInputQuestionSchema';
import { numberInputQuestionSchema } from './numberInputQuestionSchema';
import { radioQuestionSchema } from './radioQuestionSchema';
import { textAreaQuestionSchema } from './textAreaQuestionSchema';
import { textInputQuestionSchema } from './textInputQuestionSchema';
import * as Yup from 'yup';

const SCHEMAS: Partial<
  Record<
    NonNullable<QuestionnaireQuestionFragment['__typename']>,
    (question: $TSFixMe) => Yup.AnySchema | undefined
  >
> = {
  QuestionnaireRadioQuestion: radioQuestionSchema,
  QuestionnaireTextInputQuestion: textInputQuestionSchema,
  QuestionnaireNumberInputQuestion: numberInputQuestionSchema,
  QuestionnaireTextAreaQuestion: textAreaQuestionSchema,
  QuestionnaireDateInputQuestion: dateInputQuestionSchema,
  QuestionnaireCheckboxQuestion: checkboxQuestionSchema,
};

export const questionSchemas = (questions: QuestionnaireQuestionFragment[]) => {
  const schemas: Record<string, Yup.AnySchema> = {};

  questions.forEach((question) => {
    if (question.__typename === undefined) {
      return;
    }

    const schema = SCHEMAS[question.__typename]?.(question);

    if (schema !== undefined) {
      schemas[question.id] = schema;
    }
  });

  return schemas;
};
