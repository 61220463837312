import { useGetPlatformSettingsQuery } from '@pm/graphql';

export const usePlatformSettings = () => {
  const { data: platformSettingsData, loading } = useGetPlatformSettingsQuery();

  const platformSettings = platformSettingsData?.platformSettings;

  return {
    loading: loading || !platformSettingsData,
    cancellationThresholdHours: platformSettings?.cancellationThresholdHours,
    rescheduleThresholdHours: platformSettings?.rescheduleThresholdHours,
  } as const;
};
