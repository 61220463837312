import clsx from 'clsx';

export const focusStyles = clsx(
  'focus:ring-brand-primary-200 focus:outline-none focus:ring-2 focus:border-grey-400',
);

/**
 * Used for text, textarea and select inputs
 */
export const baseFormControlStyles = clsx([
  'appearance-none w-full rounded-lg p-2.5 border transition-colors',
  'disabled:bg-grey-200 disabled:text-grey-600 disabled:border-grey-400 disabled:cursor-not-allowed',
  'aria-invalid:border-critical-dark aria-invalid:pr-10 aria-invalid:focus:border-critical-dark aria-invalid:focus:ring-critical-light opacity-100',
  focusStyles,
]);

export const baseTextStyles = clsx(
  baseFormControlStyles,
  'border-grey-400',
  'placeholder:text-grey-500 placeholder:truncate',
  'read-only:bg-grey-200 read-only:text-grey-600 read-only:border-grey-400',
);

export const textInputStyles = clsx(baseTextStyles, 'truncate');

export const selectStyles = clsx(
  baseFormControlStyles,
  'border-grey-400',
  '[&:has(>option[disabled]:checked)]:text-grey-500',
);

export const checkboxAndRadioStyles = clsx(
  'transition-all border-grey-400 text-brand-primary-500',
  'disabled:bg-grey-300 checked:disabled:bg-grey-400 disabled:text-grey-600 disabled:border-grey-400 disabled:cursor-not-allowed',
);

export const checkboxStyles = clsx(
  checkboxAndRadioStyles,
  'form-checkbox rounded h-5 w-5',
  focusStyles,
);

export const radioStyles = clsx(
  checkboxAndRadioStyles,
  'form-radio h-4 w-4',
  focusStyles,
);
