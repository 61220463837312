import { useState } from 'react';
import { StarIcon as StarSolid } from '@heroicons/react/20/solid';
import { StarIcon as StarOutline } from '@heroicons/react/24/outline';
import clsx from 'clsx';
import { useFormContext } from 'react-hook-form';

export const StarField = () => {
  const { register, setValue } = useFormContext();
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  return (
    <div className="flex flex-row">
      {[...Array(5)].map((star, i) => {
        const ratingValue = i + 1;
        let solid = false;
        if (hover >= ratingValue) {
          solid = true;
        }

        if (!hover && rating >= ratingValue) {
          solid = true;
        }

        return (
          <label key={ratingValue}>
            <input
              type="radio"
              value={ratingValue}
              className="hidden"
              {...register('rating')}
            />
            <button
              type="button"
              className={clsx(
                'cursor-pointer transition md:mx-1',
                rating >= ratingValue && !hover && 'text-warning-dark',
                hover >= ratingValue && 'text-warning-dark',
              )}
              onClick={(e) => {
                e.preventDefault();
                setRating(ratingValue);
                setValue('rating', ratingValue, {
                  shouldTouch: true,
                  shouldValidate: true,
                  shouldDirty: true,
                });
              }}
              onMouseEnter={() => setHover(ratingValue)}
              onMouseLeave={() => setHover(0)}
              title={`${ratingValue} / 5`}
            >
              {solid ? (
                <StarSolid className="h-11 md:h-9" />
              ) : (
                <StarOutline className="h-11 md:h-9" />
              )}
            </button>
          </label>
        );
      })}
    </div>
  );
};
