import { Icon, IconProps } from '../Icon/Icon';
import { InlineMessageIntentType } from './types';

type InlineMessageIconProps = { intent?: InlineMessageIntentType };

export const InlineMessageIcon = ({
  intent = 'default',
}: InlineMessageIconProps) => {
  const iconForIntent: Record<InlineMessageIntentType, IconProps> = {
    default: {
      name: 'info',
      color: 'text-content-default',
    },
    info: {
      name: 'lightbulb',
      color: 'text-content-info',
    },
    success: {
      name: 'check_circle',
      color: 'text-content-positive',
    },
    warning: {
      name: 'warning',
      color: 'text-content-warning',
    },
    error: {
      name: 'cancel',
      color: 'text-content-negative',
    },
  };

  return <Icon {...iconForIntent[intent]} />;
};

InlineMessageIcon.displayName = 'InlineMessageIcon';
