import React, { useEffect } from 'react';
import { useCarousel } from './CarouselContext';

type CarouselItemsElement = React.ElementRef<'div'>;
type CarouselItemsProps = React.ComponentPropsWithoutRef<'div'>;

export const CarouselItems = React.forwardRef<
  CarouselItemsElement,
  CarouselItemsProps
>((props, forwardedRef) => {
  const { index, internal_itemsCount, internal_setItemsCount } = useCarousel();
  const childrenArray = React.Children.toArray(props.children);
  const currentIndexChild = childrenArray[index];

  useEffect(() => {
    if (childrenArray.length !== internal_itemsCount) {
      internal_setItemsCount(childrenArray.length);
    }
  }, [childrenArray, internal_itemsCount, internal_setItemsCount]);

  return (
    <div
      {...props}
      className="flex items-center justify-center"
      ref={forwardedRef}
    >
      {currentIndexChild}
    </div>
  );
});

CarouselItems.displayName = 'CarouselItems';
