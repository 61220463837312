import { QuestionnaireTextAreaQuestion } from '@pm/graphql';
import * as Yup from 'yup';
import i18n from 'i18next';

export const textAreaQuestionSchema = ({
  maxLength,
  minLength,
}: QuestionnaireTextAreaQuestion) =>
  Yup.string()
    .max(maxLength, () =>
      i18n.t('Validations.String.Maximum', {
        ns: 'global',
        count: maxLength,
      }),
    )
    .min(minLength, () =>
      i18n.t('Validations.String.Minimum', {
        ns: 'global',
        count: minLength,
      }),
    );
