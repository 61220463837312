import { PropsWithChildren } from 'react';
import clsx from 'clsx';

type Props = {
  className?: string;
};

export const ModalFooter = ({
  children,
  className,
}: PropsWithChildren<Props>) => {
  const computedClassName = clsx(
    'flex items-stretch space-x-2 rounded-b border-t border-grey-200 px-6 py-4',
    className,
  );
  return <div className={computedClassName}>{children}</div>;
};
