import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { ErrorMessage, Field, useField } from 'formik';
import { FC, HTMLProps, PropsWithChildren, useId } from 'react';
import {
  baseFormControlStyles,
  selectStyles,
} from '../forms/shared-input-styles';

export interface StyledFieldProps {
  name: string;
  id?: string;
  type?: string;
  label?: string;
  labelStyle?: string;
  description?: string;
  placeholder?: string;
  large?: boolean;
  borderVariant?: 'light' | 'dark';
  inverted?: boolean;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  as?: string;
  inputStyle?: string;
  onClick?: () => void;
}

export const StyledField: FC<
  PropsWithChildren<StyledFieldProps & HTMLProps<HTMLInputElement>>
> = ({
  label,
  id: idProp,
  description,
  placeholder,
  type,
  large = false,
  borderVariant = 'dark',
  inverted = false,
  children,
  labelStyle = '',
  hideErrorMessage = false,
  disabled = false,
  inputStyle = 'mb-3',
  onClick,
  className,
  ...props
}) => {
  const _id = useId();
  const id = idProp || _id;
  const [field, meta] = useField(props.name);
  const showError = !!meta.error && meta.touched;

  return (
    <div className="flex flex-grow flex-col gap-2">
      {label && (
        <label
          htmlFor={id}
          className={
            labelStyle ? labelStyle : 'text-grey-500 block text-sm leading-5'
          }
        >
          {label}
        </label>
      )}
      <div className="relative">
        <Field
          {...field}
          {...props}
          id={id}
          checked={type === 'checkbox' && field.value}
          disabled={disabled}
          type={type ? type : 'text'}
          className={clsx(
            baseFormControlStyles,
            type === 'select' ? selectStyles : inputStyle,
            type === 'checkbox' ? 'w-auto' : 'w-full',
            borderVariant === 'dark' ? 'border-grey-400' : 'border-grey-200',
            large && 'text-lg',
            label ? 'mt-1.5' : 'mt-0',
            className,
          )}
          placeholder={type !== 'select' ? placeholder : undefined}
          onClick={onClick}
          aria-invalid={showError}
          aria-describedby={`${field.name}-error`}
        >
          {children}
        </Field>
        <div className="pointer-events-none absolute inset-y-0 right-0 top-4 flex items-center pr-3">
          {showError && (
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-critical-dark"
            />
          )}
        </div>
      </div>
      {description && (
        <div className="mt-2 w-full">
          <p className="text-grey-400 text-xs italic">{description}</p>
        </div>
      )}
      {!hideErrorMessage && (
        <ErrorMessage
          name={field.name}
          className={clsx(
            'mb-2 text-sm',
            inverted ? 'text-white' : 'text-critical-dark',
          )}
          component="p"
        />
      )}
    </div>
  );
};
