import * as LabelPrimitive from '@radix-ui/react-label';
import * as Dropdown from '../Dropdown';

export type DatePartDropdownProps = {
  label?: string;
  id: string;
  options: {
    text: string;
    value: number;
  }[];
  value?: string;
  name?: string;
  disabled?: boolean;
  onChange: (value: number) => void;
};

export const DatePartDropdown = (props: DatePartDropdownProps) => {
  const { label, onChange, id, ...restProps } = props;
  return (
    <div>
      {label && id && (
        <LabelPrimitive.Label htmlFor={id} className="text-label text-content">
          {label}
        </LabelPrimitive.Label>
      )}
      <Dropdown.Root
        ariaLabel={label}
        onChange={(value: string) => onChange(Number(value))}
        id={id}
        {...restProps}
      >
        {props.options.map((option) => (
          <Dropdown.Item
            key={option.value}
            value={`${option.value}`}
            text={`${option.text}`}
          />
        ))}
      </Dropdown.Root>
    </div>
  );
};
