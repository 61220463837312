import clsx from 'clsx';
import { CoreConversationFieldsFragment } from '@pm/graphql';
import { TableCell } from '../Table/TableCell';
import { DateTime } from '../DateTime';
import { useMessageName } from '../../hooks/useMessageName';

type MessageListRowProps = {
  conversation: CoreConversationFieldsFragment;
  brand: string;
};

export const MessageListRow = ({
  conversation,
  brand,
}: MessageListRowProps) => {
  const message = conversation.lastMessage;
  let otherUser = message.sender;

  if (message.recipient.role.code !== 'PATIENT') {
    otherUser = message.recipient;
  }

  const otherUserDisplayName = useMessageName(brand, otherUser);

  const path = `/conversations/${conversation.id}`;

  return (
    <tr
      className={clsx(
        'hover:bg-grey-50',
        !conversation.readByCurrentUser && 'font-bold',
      )}
    >
      {message.sender.role.code === 'PATIENT' ? (
        <TableCell to={path}>
          {message.sender.preferredName ||
            `${message.sender.firstName} ${message.sender.lastName}`}
        </TableCell>
      ) : (
        <TableCell to={path}>{otherUserDisplayName}</TableCell>
      )}
      <TableCell to={path}>{conversation.subject}</TableCell>
      <TableCell to={path}>
        <DateTime value={conversation.lastMessageSentAt} format="Pp" />
      </TableCell>
    </tr>
  );
};
