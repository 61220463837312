import { FunctionComponent, ReactNode, useState } from 'react';
import { useController } from 'react-hook-form';
import { ToggleButton } from '../ToggleButton';
import { StyledInput } from '../forms/StyledInput';

export type FormattedOption = {
  text: ReactNode;
  value: string;
  selected?: boolean;
};

export type ComboRadioFieldProps = {
  name: string;
  options: string[] | FormattedOption[];
  freeTextButton?: string;
  freeTextPlaceholder?: string;
  cols?: number;
};

const optionIsSelected = (option: string, selectedOptions: string) =>
  selectedOptions.includes(option);

const getFormattedOptions = (
  optionsToFormat: string[] | FormattedOption[],
  fieldValue: string,
): FormattedOption[] => {
  if (typeof optionsToFormat[0] === 'string') {
    return (optionsToFormat as string[]).map((option) => ({
      text: option,
      value: option,
      selected: optionIsSelected(option, fieldValue),
    }));
  }

  return (optionsToFormat as FormattedOption[]).map((option) => {
    return {
      ...option,
      selected: optionIsSelected(option.value, fieldValue),
    };
  });
};

/**
 * @deprecated
 * Please use the `HookFormMultiSelectCheckbox` component instead.
 */
export const ComboRadio: FunctionComponent<ComboRadioFieldProps> = ({
  name,
  options,
  freeTextButton,
  freeTextPlaceholder,
  cols = 2,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name });
  const [freeTextSelected, setFreeTextSelected] = useState(false);
  const [freeTextValue, setFreeTextValue] = useState('');

  const fieldValue = field.value ?? '';
  const formattedOptions = getFormattedOptions(options, fieldValue);

  const updateFieldValue = (
    updatedOptions: FormattedOption[],
    freeTextFieldValue = '',
  ) => {
    const selectedValues = updatedOptions
      .filter((option) => option.selected && option.value !== freeTextButton)
      .map((option) => option.value);

    if (freeTextFieldValue !== '') {
      selectedValues.push(freeTextFieldValue);
    }

    field.onChange(selectedValues.join(','));
  };

  const handleOptionClick = (
    option: FormattedOption,
    freeTextInputValue: string,
  ) => {
    const index = formattedOptions.indexOf(option);
    const updatedOptions = Array.from(formattedOptions);
    updatedOptions[index].selected = !option.selected;

    const currentOption = updatedOptions.find(
      (optionState) => optionState.value === option.value,
    );

    if (!currentOption) {
      return;
    }

    updateFieldValue(updatedOptions, freeTextInputValue);
  };

  const handleFreeTextChange = (updatedValue: string) => {
    const updatedOptions = Array.from(formattedOptions);
    updateFieldValue(updatedOptions, updatedValue);
    setFreeTextValue(updatedValue);
  };

  return (
    <div className="w-full">
      <div
        role="radiogroup"
        className={`mx-auto grid grid-cols-1 gap-x-4 gap-y-2 sm:grid-cols-${cols}`}
      >
        {formattedOptions.map((option, index) =>
          option.value === freeTextButton ? (
            <ToggleButton
              vertical
              key={index + name}
              text={option.text}
              selected={freeTextSelected}
              onClick={() => {
                handleOptionClick(option, '');
                setFreeTextSelected(!freeTextSelected);
                setFreeTextValue('');
              }}
            />
          ) : (
            <ToggleButton
              vertical
              key={index + name}
              text={option.text}
              selected={option.selected ?? false}
              onClick={() => handleOptionClick(option, freeTextValue)}
            />
          ),
        )}
        {freeTextSelected && (
          <StyledInput
            placeholder={freeTextPlaceholder}
            value={freeTextValue}
            onChange={(event) => handleFreeTextChange(event.target.value)}
            data-testid="freeTextInput"
          />
        )}
      </div>

      {error && <p className="text-critical-dark mt-2">{error.message}</p>}
    </div>
  );
};
