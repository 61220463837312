import clsx from 'clsx';
import * as React from 'react';

type HeadingElement = React.ElementRef<'h1'>;
interface HeadingProps
  extends Omit<React.ComponentPropsWithoutRef<'h1'>, 'className'> {
  level: 1 | 2;
}

const HeadingV2 = React.forwardRef<HeadingElement, HeadingProps>(
  ({ level = 2, ...props }, forwardedRef) => {
    const HeadingComponent: Extract<keyof JSX.IntrinsicElements, `h${number}`> =
      `h${level}`;

    const headingClasses = clsx('text-grey-900 font-medium', {
      'text-2xl leading-[30px]': level === 1,
      'text-xl leading-relaxed': level === 2,
    });

    return (
      <HeadingComponent
        {...props}
        className={headingClasses}
        ref={forwardedRef}
      />
    );
  },
);

HeadingV2.displayName = 'HeadingV2';

export { HeadingV2 };
