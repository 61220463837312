import { Icon, Text } from '@pm/ui';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

type AppointmentDateProps = {
  date: Date;
  size?: ComponentProps<typeof Text>['size'];
  withIcon?: boolean;
};

export const AppointmentDate = ({
  date,
  size = 'headingXS',
  // Temporary while we still have the existing UI when booking a new appointment
  // Remove this when https://github.com/purposemed/core/issues/12048 is done.
  withIcon = false,
}: AppointmentDateProps) => {
  const { t } = useTranslation('appointments', {
    keyPrefix: 'Appointment.Summary',
  });

  return (
    <div className="flex space-x-s items-center">
      {withIcon && <Icon name="calendar_today" size="xl" />}
      <Text
        size={size}
        as="time"
        role="time"
        dateTime={date.toDateString()}
        title={date.toDateString()}
      >
        {t('AppointmentDate', { date })}
      </Text>
    </div>
  );
};
