import * as RadioPrimitive from '@radix-ui/react-radio-group';
import { useId } from 'react';
import { twMerge } from 'tailwind-merge';
import { TextFieldProps } from '../TextField/TextField';
import { Selector } from './Selector';

type SingleSelectProps = RadioPrimitive.RadioGroupItemProps & {
  body: string;
  header?: string;
  disabled?: boolean;
  freeText?: TextFieldProps;
};

export const SingleSelect = ({
  disabled,
  value,
  required,
  header,
  body,
  freeText,
  onClick,
  ...props
}: SingleSelectProps) => {
  const id = useId();

  return (
    <Selector
      disabled={disabled}
      header={header}
      body={body}
      childId={id}
      freeText={freeText}
    >
      <RadioPrimitive.Item
        {...props}
        id={id}
        aria-disabled={disabled}
        value={value}
        required={required}
        className={twMerge(
          'w-[20px] h-[20px] shrink-0 rounded-circle outline-none cursor-default border-2 border-strong',
          disabled ? 'cursor-not-allowed' : 'rdx-state-checked:border-accent',
          header && 'mb-auto mt-[2px]',
        )}
        onClick={disabled ? (e) => e.preventDefault() : onClick}
      >
        <RadioPrimitive.Indicator
          className={twMerge(
            'flex items-center w-full h-full justify-center relative after:content-[""] after:block after:w-[8px] after:h-[8px] after:rounded-[50%]',
            disabled ? 'after:bg-content-disabled' : 'after:bg-primary',
          )}
        />
      </RadioPrimitive.Item>
    </Selector>
  );
};
