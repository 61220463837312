import { useGetMessageQuery } from '@pm/graphql';
import { Navigate, useParams } from 'react-router-dom';
import { LoadingPage } from './LoadingPage';

export const ConversationRedirectPage = () => {
  const { messageId } = useParams();
  const { data: message } = useGetMessageQuery({
    variables: {
      id: messageId ?? '',
    },
  });

  if (message?.message) {
    console.log('Redirecting from message: ', messageId);
    console.log(
      'Redirecting to conversation: ',
      message.message.conversationId,
    );
    return <Navigate to={`/conversations/${message.message.conversationId}`} />;
  }

  return <LoadingPage />;
};
