import { ApolloError } from '@apollo/client/index.js';
import { FieldErrors, FieldValues } from 'react-hook-form';

const SERVER_ERROR = 'root.serverError';

/**
 * Utility for determining if an error is a result of a record not being found.
 */
export function isApollo404Error(error: ApolloError) {
  return error.graphQLErrors.some(
    (err) => err.extensions?.code === 'not_found',
  );
}

/**
 * Utility for converting an error message to a server error type
 */
export const toServerError = (message?: string) =>
  [
    SERVER_ERROR,
    {
      type: 'server',
      message,
    },
  ] as const;

export const hasServerError = (errors?: FieldErrors<FieldValues>) => {
  const formHasServerError = errors?.root?.serverError !== undefined;
  return formHasServerError;
};

export const hasOnlyServerError = (errors: FieldErrors<FieldValues> = {}) => {
  const errorFieldsNames = Object.keys(errors);
  return errorFieldsNames.length === 1 && hasServerError(errors);
};
