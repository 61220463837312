import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

export type variants =
  | 'bigGreen'
  | 'bigBoldGreen'
  | 'baseGreen'
  | 'baseGray'
  | 'baseBlack'
  | 'mediumBlack'
  | 'muted'
  | 'smallBlack'
  | 'smallGrey';

type Props = {
  id?: string;
  label?: string;
  className?: string;
  variant?: variants;
};

const variantStyles: Record<string, string> = {
  bigGreen: 'text-content-positive mb-6 text-2xl font-semibold sm:text-3xl',
  bigBoldGreen: 'text-content-positive text-2xl font-bold',
  baseGreen: 'text-content-positive text-lg font-bold',
  baseGray: 'mb-2 text-lg text-grey-900',
  baseBlack: 'text-xl text-grey-800 font-bold',
  mediumBlack: 'text-lg font-medium',
  muted: 'text-xl text-tertiary',
  smallBlack: 'text-sm font-medium text-grey-900',
  smallGrey: 'text-sm font-medium text-grey-500',
};

export const QuestionnaireLabel: FunctionComponent<
  PropsWithChildren<Props>
> = ({ label, children, className, variant = 'bigGreen', id }) => (
  <div id={id} className={clsx(variantStyles[variant], className)}>
    {children ? children : label}
  </div>
);
