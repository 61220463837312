import { useState } from 'react';
import { Text } from '@pm/ui';
import { useTranslation } from 'react-i18next';
import { TreatmentCardItem } from '../hooks/additional-treatments';
import { AdditionalTreatmentCard } from './AdditionalTreatmentCard';

type ExploreTreatmentsProps = {
  treatmentCards: TreatmentCardItem[];
};

export const ExploreTreatments = ({
  treatmentCards,
}: ExploreTreatmentsProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview.ExploreTreatments',
  });
  const [showAll, _] = useState(false);

  if (!treatmentCards.length) {
    return null;
  }

  const treatmentsToShow = showAll
    ? treatmentCards
    : treatmentCards.slice(0, 3);

  return (
    <div className="flex-col space-y-l">
      <div className="flex justify-between w-full">
        <div className="flex-col space-y-xs">
          <Text size="headingS" aria-label={t('Title')}>
            {t('Title')}
          </Text>
          <Text aria-label={t('Body')}>{t('Body')}</Text>
        </div>
        {/* Button to show more treatments temporarily removed */}
        {/* {treatmentCards.length > 3 && (
          <div>
            <Button
              intent="ghost"
              size="small"
              aria-label={showAll ? t('ViewLess') : t('ViewMore')}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? t('ViewLess') : t('ViewMore')}
            </Button>
          </div>
        )} */}
      </div>
      <div className="flex-col space-y-m">
        {treatmentsToShow.map((treatment: TreatmentCardItem | undefined) => {
          if (treatment && (treatment.getStarted || treatment.infoModal)) {
            return (
              <AdditionalTreatmentCard
                key={treatment.page}
                treatment={treatment}
              />
            );
          } else {
            return null;
          }
        })}
      </div>
    </div>
  );
};
