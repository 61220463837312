// This date picker is used for scenarios where we want to provide a
// date input and calendar picker. This has not yet been approved by
// the design team, and does not match the HookFormDatepicker we use
// elsewhere in our apps.
import { useController } from 'react-hook-form';
import { DateInput } from './DateInput';

type HookFormDateInputProps = React.HTMLProps<HTMLInputElement> & {
  name: string;
  label?: string;
  description?: string;
  helpText?: string;
  required?: boolean;
};

export const HookFormDateInput = (props: HookFormDateInputProps) => {
  const { name } = props;
  const { field, fieldState } = useController({ name });
  const { error } = fieldState;

  return <DateInput {...props} {...field} error={error?.message} />;
};
