/// <reference types="tsconfig/google-analytics" />

type TrackPageViewOptions =
  | {
      type: 'google';
      /**
       * Full path of the page.
       * @example window.location.href
       */
      location: string;
      /**
       * Path segment of the page.
       * @example window.location.pathname
       */
      pathname: string;
    }
  | {
      type: 'segment';
      location?: string;
      pathname?: string;
    };

/**
 * For tracking page views.
 */
export function trackPageView({
  location,
  pathname,
  type,
}: TrackPageViewOptions) {
  if (!window.gtag || !window.analytics) {
    return;
  }

  if (type === 'google') {
    window.gtag('event', 'page_view', {
      page_location: location,
      page_path: pathname,
    });

    return;
  }

  window.analytics.page();
}
