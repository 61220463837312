import { QuestionnaireCheckboxQuestion } from '@pm/graphql';
import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from './sharedSchemas';

export const checkboxQuestionSchema = ({
  minSelections,
}: QuestionnaireCheckboxQuestion) =>
  Yup.array()
    .of(Yup.string())
    .when([], {
      is: () => minSelections > 0,
      then: (schema) =>
        schema.test(
          'has-min-num-selections',
          // TODO: Add a translation for min number of selections
          REQUIRED_MESSAGE,
          (selections) =>
            selections != null && selections.length >= minSelections,
        ),
    });
