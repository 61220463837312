import {
  FC,
  HTMLProps,
  PropsWithChildren,
  RefCallback,
  useId,
  HTMLInputTypeAttribute,
} from 'react';
import clsx from 'clsx';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { baseTextStyles, textInputStyles } from '../forms/shared-input-styles';

export interface StyledFieldProps {
  name: string;
  type?: HTMLInputTypeAttribute;
  label?: string;
  labelStyle?: string;
  description?: string;
  placeholder?: string;
  large?: boolean;
  inverted?: boolean;
  hideErrorMessage?: boolean;
  disabled?: boolean;
  as?: string;
  inputStyle?: string;
  onClick?: () => void;
  updateRef?: RefCallback<HTMLInputElement | HTMLTextAreaElement>;
}

export const HookFormStyledField: FC<
  PropsWithChildren<StyledFieldProps & HTMLProps<HTMLInputElement>>
> = ({
  label,
  description,
  placeholder,
  type,
  large = false,
  inverted = false,
  children,
  labelStyle = '',
  hideErrorMessage = false,
  disabled = false,
  inputStyle,
  onClick,
  updateRef,
  rows,
  ...props
}) => {
  const _id = useId();
  const id = props.id || _id;

  const { register, getValues, getFieldState, formState, trigger } =
    useFormContext();
  const { error, isTouched } = getFieldState(props.name, formState);
  const { errors } = formState;
  const showError = !!error && isTouched;

  const { ref: hookFormRef, ...hookFormProps } = register(props.name, {
    onChange: (e) => {
      trigger(props.name);
      props.onChange?.(e);
    },
    onBlur: (e) => {
      props.onBlur?.(e);
      trigger(props.name);
    },
  });

  return (
    <div className="flex-grow text-black">
      <div className="relative rounded-md">
        {label && (
          <label
            htmlFor={id}
            className={
              labelStyle
                ? labelStyle
                : 'text-content-positive block text-xl font-semibold leading-5'
            }
          >
            {label}
          </label>
        )}
        {type !== 'textarea' && props.as !== 'textarea' ? (
          <input
            {...props}
            id={id}
            checked={type === 'checkbox' && getValues(props.name)}
            disabled={disabled}
            type={type ? type : 'text'}
            className={clsx(
              'mb-3',
              textInputStyles,
              inputStyle,
              type === 'checkbox' ? '!w-auto' : 'w-full',
              large && 'text-lg',
              labelStyle ? 'mt-2' : 'mt-4',
            )}
            placeholder={placeholder}
            onClick={onClick}
            aria-invalid={showError}
            aria-describedby={`${props.name}-error`}
            {...hookFormProps}
            ref={(e) => {
              hookFormRef(e);
              updateRef?.(e);
            }}
          >
            {children}
          </input>
        ) : (
          <textarea
            disabled={disabled}
            id={id}
            value={props.value}
            className={clsx(
              baseTextStyles,
              inputStyle,
              large && 'text-lg',
              labelStyle ? 'mt-2' : 'mt-4',
            )}
            placeholder={placeholder}
            onClick={onClick}
            rows={rows}
            aria-invalid={showError}
            aria-describedby={`${props.name}-error`}
            {...hookFormProps}
            ref={(e) => {
              hookFormRef(e);
              updateRef?.(e);
            }}
          />
        )}
        {showError && (
          <div
            className={clsx(
              'pointer-events-none absolute right-0 flex items-center pr-3',
              label ? 'top-[2.875rem] mt-0.5' : 'inset-y-0',
            )}
          >
            <FontAwesomeIcon
              icon={faExclamationCircle}
              className="text-critical-dark"
            />
          </div>
        )}
      </div>
      {description && (
        <div className="mt-2 w-full">
          <p className="text-grey-400 text-xs italic">{description}</p>
        </div>
      )}
      {!hideErrorMessage && showError && (
        <ErrorMessage
          name={props.name}
          errors={errors}
          className={clsx(
            'mb-2 text-sm',
            inverted ? 'text-white' : 'text-critical-dark',
          )}
          as="p"
        />
      )}
    </div>
  );
};
