import { useController, useFormContext } from 'react-hook-form';
import {
  PhoneNumberInputImpl,
  yupPhoneNumberValidationSchema,
} from './PhoneNumberInputImpl';

type HookFormPhoneNumberInputProps = Omit<
  React.HTMLProps<HTMLInputElement>,
  'value' | 'defaultValue'
> & {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  dataCy?: string;
};

export const HookFormPhoneNumberInput = ({
  name,
  ...props
}: HookFormPhoneNumberInputProps) => {
  const { setValue } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({ name });

  return (
    <PhoneNumberInputImpl
      {...props}
      {...field}
      onChange={(value) =>
        // We shouldn't be doing this, but alas here we are. This is due to all the other components not being able
        // to handle the ability to have the form influence the validation triggering based on "mode" and "revalidationMode"
        setValue(name, value, {
          shouldValidate: true,
          shouldDirty: true,
          shouldTouch: true,
        })
      }
      error={error?.message}
    />
  );
};

HookFormPhoneNumberInput.yupValidationSchema = yupPhoneNumberValidationSchema;
