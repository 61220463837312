import { useState } from 'react';

type UploadingProgressBarProps = {
  fileName: string;
  progress: number;
};
export const UploadingProgressBar = ({
  fileName,
  progress,
}: UploadingProgressBarProps) => {
  // Progress jumps back and fourth, so we need to keep track of the highest
  const [currentProgress, setProgress] = useState(0);

  if (progress > currentProgress) {
    setProgress(progress);
  }

  if (currentProgress === 100) {
    return null;
  }

  return (
    <progress
      aria-label={`Uploading ${fileName}`}
      className="[&::-webkit-progress-bar]:rounded-lg [&::-webkit-progress-value]:rounded-lg [&::-webkit-progress-bar]:bg-grey-300 [&::-webkit-progress-value]:bg-brand-primary-500 [&::-moz-progress-bar]:bg-primary-500"
      max="100"
      value={currentProgress}
    />
  );
};
