import {
  QuestionnaireAnswerFragment,
  QuestionnaireQuestionFragment,
} from '@pm/graphql';

const DEFAULT_FORMAT = (value: string) => [value];

const DATA_FORMATS: Record<
  NonNullable<QuestionnaireQuestionFragment['__typename']>,
  (value: $TSFixMe) => string[]
> = {
  QuestionnaireRadioQuestion: DEFAULT_FORMAT,
  QuestionnaireTextInputQuestion: DEFAULT_FORMAT,
  QuestionnaireNumberInputQuestion: (value: number) => [String(value)],
  QuestionnaireTextAreaQuestion: DEFAULT_FORMAT,
  QuestionnaireDateInputQuestion: DEFAULT_FORMAT,
  QuestionnaireCheckboxQuestion: (value: string[]) => value,
};
export const formatAnswerForDataType = (
  answer: string | number | string[],
  typename: QuestionnaireQuestionFragment['__typename'],
): string[] => {
  if (typename === undefined) {
    return [''];
  }

  const formatter = DATA_FORMATS[typename];

  return formatter(answer);
};

export const formatAnswerForInputType = (
  answer: QuestionnaireAnswerFragment | undefined,
  typename: QuestionnaireQuestionFragment['__typename'],
): string[] | string => {
  if (typename === 'QuestionnaireCheckboxQuestion') {
    return answer?.values ?? [];
  }

  return answer?.values.join(',') || '';
};
