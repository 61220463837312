export const profileRoutes = {
  profile: {
    path: '/profile',
  },
  settings: {
    path: '/profile/settings',
  },
  shipping: {
    path: '/profile/shipping',
  },
  newShipping: {
    path: '/profile/shipping/new',
  },
  updateShipping: {
    path: '/profile/shipping/:id',
    build({ id }: { id: string }) {
      return this.path.replace(':id', id);
    },
  },
  insurance: {
    path: '/profile/insurance',
  },
  insuranceType: {
    path: '/profile/insurance-type',
  },
  newInsurance: {
    path: '/profile/insurance/new',
  },
  updateInsurance: {
    path: '/profile/insurance/:id',
    build({ id }: { id: string }) {
      return this.path.replace(':id', id);
    },
  },
  updateIdentification: {
    path: '/profile/identification/update',
  },
  verifyIdentification: {
    path: '/profile/identification/update/verify',
  },
  successfulIdentification: {
    path: '/profile/identification/successful',
  },
  payments: {
    path: '/profile/payments',
  },
  newPaymentMethod: {
    path: '/profile/payments/new',
  },
} as const;
