import { TreatmentFragment, TreatmentType } from '@pm/graphql';

export const getTreatmentByType = ({
  treatments,
  type,
}: {
  treatments: TreatmentFragment[];
  type: TreatmentType;
}) => {
  return treatments?.find((t) => t?.type === type) ?? null;
};
