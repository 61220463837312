import clsx from 'clsx';
import { ReactNode } from 'react';

type CardProps = {
  children?: ReactNode;
  className?: string;
  variant?: 'white' | 'purposemed';
  padding?: 'standard' | 'medium' | 'none';
};

export const Card = ({
  children,
  className,
  variant = 'white',
  padding = 'standard',
}: CardProps) => {
  const computedClassName = clsx(
    'border rounded-lg w-full',
    padding === 'standard' && 'p-6',
    padding === 'medium' && 'p-4',
    variant === 'white' && 'bg-white border-grey-200 shadow-md',
    variant === 'white' && padding === 'standard' && 'sm:p-10',
    variant === 'purposemed' && 'border-grey-300',
    className && className,
  );

  return <div className={computedClassName}>{children}</div>;
};
