import * as Popover from '@radix-ui/react-popover';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Chip } from '../../Chip/Chip';
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from '../../Command/Command';
import { Icon } from '../../Icon/Icon';
import { inputBase } from '../base';
import { InputLabel } from '../InputLabel';

export type ComboboxOption = {
  value: string;
  label: string;
  selected: boolean;
};

export type ComboboxProps = {
  id?: string;
  searchInputPlaceholder?: string;
  options: Array<ComboboxOption>;
  appendSelectedOption: (option: ComboboxOption) => void;
  deselectOption: (option: ComboboxOption) => void;
  onSelect?: (option: ComboboxOption) => void;
  onDeselect?: (option: ComboboxOption) => void;
  displaySearchAndChip?: boolean;
  closeOnSelect?: boolean;
  optional?: boolean;
};

export const Combobox = ({
  id,
  searchInputPlaceholder = 'Search',
  options,
  appendSelectedOption,
  deselectOption,
  onSelect,
  onDeselect,
  displaySearchAndChip = true,
  closeOnSelect = true,
  optional = false,
}: ComboboxProps) => {
  const [open, setOpen] = useState(false);
  const selectedOptions = options.filter((option) => option.selected);
  const selectedOptionsCount = selectedOptions.length;
  const selectedCountPlaceholder =
    selectedOptionsCount > 0
      ? `${selectedOptionsCount} selected`
      : 'Select all that apply';
  const placeholder = displaySearchAndChip
    ? 'Select'
    : selectedCountPlaceholder;

  const popoverContentWidth = displaySearchAndChip
    ? 'w-[240px]'
    : 'min-w-[400px]';

  return (
    <Popover.Root open={open} onOpenChange={(open) => setOpen(open)}>
      <Popover.Trigger asChild>
        <button
          id={id}
          type="button"
          className={twMerge(
            inputBase(),
            'flex justify-between items-center py-0 text-left text-content-subdued',
          )}
          onClick={() => setOpen(!open)}
        >
          {placeholder}
          <Icon name="expand_more" size="large" />
        </button>
      </Popover.Trigger>
      {displaySearchAndChip && (
        <div className="pt-xs flex flex-wrap gap-xs">
          {selectedOptions.map((option) => (
            <Chip
              pattern="deletable"
              key={option.value}
              text={option.label}
              onDelete={() => {
                deselectOption(option);
                onDeselect?.(option);
              }}
              selected
            />
          ))}
        </div>
      )}
      <InputLabel>
        {optional && <span className="text-content-subdued">Optional</span>}
      </InputLabel>
      <Popover.Portal>
        <Popover.Content
          align="start"
          className={`bg-surface-strong shadow-s rounded-s ${popoverContentWidth} max-h-[266px] overflow-y-scroll`}
          sideOffset={5}
        >
          <Command>
            {displaySearchAndChip && (
              <div className="fixed w-full bg-surface-strong p-xxs">
                <CommandInput placeholder={searchInputPlaceholder} />
              </div>
            )}
            <CommandEmpty className="p-s text-center text-content-subdued mt-[45px]">
              No options found.
            </CommandEmpty>
            <CommandGroup
              className={`p-xxs ${displaySearchAndChip && 'mt-[45px]'}`}
            >
              {options.map((option) => (
                <CommandItem
                  key={option.value}
                  value={option.label}
                  onSelect={() => {
                    appendSelectedOption(option);
                    closeOnSelect && setOpen(false);
                    if (option.selected) {
                      deselectOption(option);
                      onDeselect?.(option);
                    }
                    onSelect?.(option);
                  }}
                >
                  <button
                    type="button"
                    className="flex items-center w-full p-xs rounded-s focus:bg-primary focus:text-content-inverse hover:bg-primary hover:text-content-inverse truncate text-ellipsis"
                  >
                    {option.selected && (
                      <div className="w-xl h-xl">
                        <Icon name="check" size="small" />
                      </div>
                    )}
                    {option.label}
                  </button>
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </Popover.Content>
      </Popover.Portal>
    </Popover.Root>
  );
};

export const useCombobox = (initialOptions: ComboboxOption[]) => {
  const [options, setSelectedOptions] = useState<Array<ComboboxOption>>(
    () => initialOptions || [],
  );

  const setSelectedOption = (option: ComboboxOption) => {
    setSelectedOptions(
      options.map((selectedOption) =>
        selectedOption.value === option.value
          ? {
              ...selectedOption,
              selected: !selectedOption.selected,
            }
          : selectedOption,
      ),
    );
  };

  const appendSelectedOption = (option: ComboboxOption) => {
    const selectedOption = options.find(
      (selectedOption) => selectedOption.value === option.value,
    );

    if (selectedOption && !selectedOption.selected) {
      setSelectedOption(selectedOption);
    }
  };

  const deselectOption = (option: ComboboxOption) =>
    setSelectedOptions(
      options.map((selectedOption) =>
        selectedOption.value === option.value
          ? {
              ...selectedOption,
              selected: false,
            }
          : selectedOption,
      ),
    );

  const resetAllOptions = () => {
    setSelectedOptions(
      options.map((selectedOption) => ({
        ...selectedOption,
        selected: false,
      })),
    );
  };

  const refreshOptions = (newOptions: ComboboxOption[]) => {
    setSelectedOptions(newOptions || []);
  };

  return {
    options,
    appendSelectedOption,
    deselectOption,
    resetAllOptions,
    refreshOptions,
    selectedOptions: options.filter((option) => option.selected),
    setSelectedOption,
  };
};
