import { ActionVariantEnum, QuestionnaireOutcomeFragment } from '@pm/graphql';
import { ButtonLink, ButtonProps, Outcome } from '@pm/ui';

type OutcomeAction = {
  label: string;
  variant: ActionVariantEnum;
  to: string;
};

type StepProps<T> = {
  step: T;
  actions: OutcomeAction[];
};

export const actionVariantToIntent: Record<
  ActionVariantEnum,
  ButtonProps['intent']
> = {
  [ActionVariantEnum.Primary]: 'primary',
  [ActionVariantEnum.Secondary]: 'secondary',
  [ActionVariantEnum.Ghost]: 'ghost',
};

export const OutcomePage = ({
  step,
  actions,
}: StepProps<QuestionnaireOutcomeFragment>) => {
  const { title, description } = step;

  return (
    <Outcome.Root>
      {title && <Outcome.Heading>{title}</Outcome.Heading>}
      {description && <Outcome.Body>{description}</Outcome.Body>}
      <Outcome.Actions>
        {actions.map(({ label, variant, to }) => (
          <ButtonLink
            key={label}
            to={to}
            intent={actionVariantToIntent[variant]}
            external={to.startsWith('https')}
          >
            {label}
          </ButtonLink>
        ))}
      </Outcome.Actions>
    </Outcome.Root>
  );
};
