import { ReactNode, forwardRef } from 'react';
import clsx from 'clsx';
import { ButtonContent } from './ButtonContent';
import { ButtonVariantProps, buttonClasses } from './Button.styles';

/* -------------------------------------------------------------------------------------------------
 * Button
 * -----------------------------------------------------------------------------------------------*/

type ButtonElement = React.ElementRef<'button'>;

export interface ButtonProps
  extends React.ComponentPropsWithoutRef<'button'>,
    ButtonVariantProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
}

export const Button = forwardRef<ButtonElement, ButtonProps>(
  (
    { className, type = 'button', leftIcon, rightIcon, children, ...props },
    forwardedRef,
  ) => {
    const contentProps = { rightIcon, leftIcon, children };
    const { wide: _, ...buttonProps } = props;

    return (
      <button
        {...buttonProps}
        // eslint-disable-next-line react/button-has-type
        type={type}
        className={clsx(buttonClasses(props), className)}
        aria-disabled={buttonProps.disabled ? true : undefined}
        data-variant={props.variant}
        ref={forwardedRef}
      >
        <ButtonContent {...contentProps} />
      </button>
    );
  },
);

Button.displayName = 'Button';
