import clsx from 'clsx';
import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  width?: string;
  variant?: 'uppercaseGray' | 'gray900' | 'white';
  className?: string;
};

export const TableHeadingCell: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  width,
  variant = 'uppercaseGray',
  className,
}) => (
  <th
    className={clsx(
      className,
      'border-b text-left font-medium',
      variant === 'white' && 'border-grey-200 text-grey-900 py-3 pl-6 text-sm',
      variant === 'gray900' &&
        `border-grey-300 bg-grey-50 text-grey-900 py-3 pl-6 text-sm ${width}`,
      variant === 'uppercaseGray' &&
        `border-grey-200 bg-grey-50 text-grey-500 px-2 py-3 text-xs uppercase leading-4 tracking-wider ${width}`,
    )}
  >
    {children}
  </th>
);
