import { useState, useEffect } from 'react';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media && media.matches !== matches) {
      setMatches(media.matches);
      setLoading(false);
    } else {
      setLoading(false);
    }
    const listener = () => setMatches(media.matches);
    window.addEventListener('resize', listener);
    return () => window.removeEventListener('resize', listener);
  }, [matches, query]);

  return { matches, loading };
};
