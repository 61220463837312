import React from 'react';

type CarouselOptions = {
  index: number;
  next: () => void;
  previous: () => void;
  setIndex: (index: number) => void;
  internal_setItemsCount: (count: number) => void;
  internal_itemsCount: number;
};

export const CarouselContext = React.createContext<CarouselOptions>({
  index: 0,
  next: () => {},
  previous: () => {},
  setIndex: () => {},
  internal_setItemsCount: () => {},
  internal_itemsCount: 0,
});

export const useCarousel = () => React.useContext(CarouselContext);
