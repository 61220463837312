interface LabURLs {
  [key: string]: string | undefined;
}

export const prepLabURLs: LabURLs = {
  en: 'https://www.gofreddie.com/prep-labs',
  fr: 'https://fr.gofreddie.com/prep-labs',
};

export const bookingPageURL = 'http://app.gofreddie.com/call';
