import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LayoutWithBackButton } from '@pm/core';
import { ButtonLink, Text } from '@pm/ui';
import { PrepTreatmentRouteMap } from '../route-map';

export const ClinicalRecommendationsPage = () => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'PrepTreatment.ClinicalRecommendations',
  });
  const { action } = useParams<{ action: 'stop' | 'pause' }>();
  const navigate = useNavigate();
  const nextPath =
    action === 'stop'
      ? PrepTreatmentRouteMap.stopTreatment.build({
          treatmentType: 'prep',
        })
      : PrepTreatmentRouteMap.pauseTreatment.build({ treatmentType: 'prep' });

  return (
    <LayoutWithBackButton onBackButtonClick={() => navigate(-1)}>
      <div className="space-y-xxl">
        <div className="space-y-s">
          <Text size="headingS">{t('Title')}</Text>
          <Text size="bodyL">
            {t(action === 'stop' ? 'BodyStop' : 'BodyPause')}
          </Text>
        </div>
        <ButtonLink to={nextPath}>{t('Next')}</ButtonLink>
      </div>
    </LayoutWithBackButton>
  );
};
