import { SingleSelect } from '@pm/ui';
import { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

type CancellationReason =
  | 'Scheduling conflict'
  | 'Financing concerns'
  | 'Switching providers'
  | 'Other';

export const useAppointmentCancellationReasons = ({
  availableReasons = [
    'Scheduling conflict',
    'Financing concerns',
    'Switching providers',
    'Other',
  ],
}: {
  availableReasons?: CancellationReason[];
}) => {
  const { t } = useTranslation('appointments', {
    keyPrefix: 'ManageAppointment.Cancel',
  });

  const reasons: Record<
    CancellationReason,
    ComponentProps<typeof SingleSelect>
  > = {
    'Scheduling conflict': {
      body: t('Reasons.SchedulingConflict'),
      value: 'Scheduling conflict',
    },
    'Financing concerns': {
      body: t('Reasons.FinancingConcerns'),
      value: 'Financing concerns',
    },
    'Switching providers': {
      body: t('Reasons.SwitchingProviders'),
      value: 'Switching providers',
    },
    Other: {
      body: t('Reasons.Other'),
      value: 'Other',
      freeText: { label: t('ReasonLabel') },
    },
  };

  const cancellationReasons = availableReasons.map((reason) => reasons[reason]);

  return {
    reasons: cancellationReasons,
    freeTextReasons: cancellationReasons.filter(
      (reason) => reason.freeText !== undefined,
    ),
    freeTextDelimiter: ': ',
  };
};
