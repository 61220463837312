import {
  AttachmentFragment,
  useUpdateLabRequisitionViewedAtMutation,
} from '@pm/graphql';
import { Card, IconLink } from '@pm/ui';
import {
  detectUserErrors,
  stripDispositionFromUrl,
  useEnvironment,
} from '@pm/core';
import * as Sentry from '@sentry/react';

export const LabRequisitionAttachmentCard = ({
  attachment,
  labReqId,
  hasBeenViewed,
}: {
  attachment: AttachmentFragment;
  labReqId: string;
  hasBeenViewed: boolean;
}) => {
  const [updateLabRequisitionViewedAt, { loading }] =
    useUpdateLabRequisitionViewedAtMutation();
  const { apiHost } = useEnvironment();

  const toLabRequisitionUrl = (downloadUrl: string) =>
    downloadUrl.startsWith('/lab-requisitions')
      ? downloadUrl
      : stripDispositionFromUrl(`${apiHost}${downloadUrl}`);

  const markAsViewed = async () => {
    try {
      const { data: updateLabRequisitionData } =
        await updateLabRequisitionViewedAt({
          variables: {
            input: { id: labReqId, viewedAt: new Date().toISOString() },
          },
        });
      detectUserErrors(
        updateLabRequisitionData,
        (res) => res.updateLabRequisitionViewedAt?.errors,
      );
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  const handleLinkClick = async () => {
    if (!loading && !hasBeenViewed) {
      markAsViewed();
    }
  };
  return (
    <Card>
      <div className="flex flex-row justify-between">
        {attachment.filename}
        <IconLink
          icon="download"
          to={toLabRequisitionUrl(attachment.downloadUrl)}
          onClick={handleLinkClick}
          target="_blank"
        />
      </div>
    </Card>
  );
};
