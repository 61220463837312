import { QuestionnaireDateInputQuestion } from '@pm/graphql';
import { isFuture, isPast, isToday, isValid, parseISO } from 'date-fns';
import i18n from 'i18next';
import { REQUIRED_MESSAGE } from './sharedSchemas';
import * as Yup from 'yup';

export const dateInputQuestionSchema = ({
  allowsFutureDates,
  allowsPastDates,
  required,
}: QuestionnaireDateInputQuestion) =>
  Yup.string()
    .when([], {
      is: () => required,
      then: (schema) => schema.required(REQUIRED_MESSAGE),
    })
    .when([], {
      is: () => !allowsFutureDates,
      then: (schema) =>
        schema.test(
          'no-future',
          () => i18n.t('Validations.Date.InvalidFuture'),
          (date) =>
            date != null &&
            (!isFuture(parseISO(date)) || isToday(parseISO(date))),
        ),
    })
    .when([], {
      is: () => !allowsPastDates,
      then: (schema) =>
        schema.test(
          'no-past',
          () => i18n.t('Validations.Date.InvalidPast'),
          (date) =>
            date != null &&
            (!isPast(parseISO(date)) || isToday(parseISO(date))),
        ),
    })
    .test(
      'valid-date',
      () => i18n.t('Validations.Date.Invalid'),
      (date) => {
        const hasDate = date != null && date !== '';
        if (hasDate) {
          return isValid(parseISO(date));
        }

        return true;
      },
    );
