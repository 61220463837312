import clsx from 'clsx';
import { ReactNode } from 'react';

type QuestionnaireSectionProps = {
  children?: ReactNode;
  className?: string;
};

export const QuestionnaireSection = ({
  children,
  className,
}: QuestionnaireSectionProps) => (
  <section className={clsx('space-y-l', className)}>{children}</section>
);
