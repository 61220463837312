import {
  ComponentPropsWithoutRef,
  forwardRef,
  ElementRef,
  ReactNode,
} from 'react';

type RadioRowContainerElement = ElementRef<'div'>;
interface RadioRowContainerProps extends ComponentPropsWithoutRef<'div'> {
  label: ReactNode;
}

const RadioRowContainer = forwardRef<
  RadioRowContainerElement,
  RadioRowContainerProps
>(({ children, label }, forwardedRef) => {
  return (
    <fieldset>
      <legend className="sr-only">{label}</legend>
      <div
        className="divide-grey-200 divide-y overflow-hidden rounded-lg bg-white"
        ref={forwardedRef}
      >
        {children}
      </div>
    </fieldset>
  );
});

RadioRowContainer.displayName = 'RadioRowContainer';

export { RadioRowContainer };
