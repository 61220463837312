import { VariantProps, cva } from 'class-variance-authority';
import { NonNullableVariantProps } from '../../types';

export type ButtonVariantProps = NonNullableVariantProps<
  VariantProps<typeof buttonClasses>
>;

export const buttonClasses = cva(
  [
    'inline-flex items-center justify-center rounded-lg border text-center font-medium',
    'transition-all ease-in-out',
    'focus:outline-none focus:ring-2',
    'aria-disabled:bg-grey-200 aria-disabled:text-grey-700 aria-disabled:cursor-not-allowed aria-disabled:border-grey-200',
  ],
  {
    variants: {
      size: {
        standard: 'px-4 py-2.5 text-base',
        small: 'p-2.5 text-sm',
      },
      variant: {
        primary:
          'bg-brand-primary-500 hover:bg-brand-primary-400 active:bg-brand-primary-600 border-brand-primary-500 text-white',
        secondary:
          'border-grey-700 hover:bg-grey-100 focus:bg-grey-200 bg-white aria-pressed:bg-brand-primary-500 aria-pressed:text-white',
        tertiary:
          'text-grey-700 hover:bg-grey-100 hover:text-grey-700 focus:bg-grey-200 border-transparent',
        whiteToGrey:
          'border-grey-300 text-grey-500 focus:bg-grey-200 focus:text-grey-700 bg-white aria-pressed:bg-grey-500 aria-pressed:text-white',
        critical:
          'bg-white text-critical-dark border-critical-dark hover:bg-critical-light focus:bg-critical-light focus:ring-critical-light',
      },
      wide: {
        true: 'w-full',
      },
    },
    compoundVariants: [
      {
        variant: ['primary', 'secondary', 'tertiary', 'whiteToGrey'],
        class: 'focus:ring-brand-primary-200',
      },
    ],
    defaultVariants: {
      size: 'standard',
      variant: 'primary',
      wide: true,
    },
  },
);
