import { VariantProps, cva } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { useIsMobile } from '../../hooks/useIsMobile';
import { WithChildren } from '../../types/component.types';
import { Text } from '../Text/Text';
import { QuestionnaireMarkdown } from '@pm/core';

/* -------------------------------------------------------------------------------------------------
 * Root
 * -----------------------------------------------------------------------------------------------*/
const outcome = cva('flex flex-col');

type OutcomeProps = VariantProps<typeof outcome> & WithChildren;

/**
 * Component for wrapping outcome content (for example, questionnaire outcome pages).
 *
 * @example
 * <Outcome.Root>
 *   <Outcome.Heading>Outcome heading</Outcome.Heading>
 *   <Outcome.Body>Outcome body</Outcome.Body>
 *   <Outcome.Actions>
 *     <ButtonLink to="/page-1" intent="primary">Go to page 1</ButtonLink>
 *     <ButtonLink to="/page-2" intent="primary">Go to page 2</ButtonLink>
 *   </Outcome.Actions>
 * </Outcome.Root>
 */
const Outcome = ({ children }: OutcomeProps) => {
  const { isMobile } = useIsMobile();

  return (
    <div
      className={twMerge(
        outcome(),
        isMobile ? 'mx-auto' : 'max-w-[624px] justify-center',
      )}
    >
      {children}
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Heading
 * -----------------------------------------------------------------------------------------------*/

const outcomeHeading = cva('pt-l px-l');

type OutcomeHeadingProps = {
  children: string;
};

const OutcomeHeading = ({ children }: OutcomeHeadingProps) => {
  return (
    <div className={outcomeHeading()}>
      <Text as="h1" size="headingM">
        {children}
      </Text>
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Body
 * -----------------------------------------------------------------------------------------------*/

const outcomeBody = cva('pt-m px-l whitespace-pre-wrap');

type OutcomeBodyProps = {
  children: string;
};

const OutcomeBody = ({ children }: OutcomeBodyProps) => {
  return (
    <div className={outcomeBody()}>
      <Text size="bodyL" as="div">
        <QuestionnaireMarkdown>{children}</QuestionnaireMarkdown>
      </Text>
    </div>
  );
};

/* -------------------------------------------------------------------------------------------------
 * Actions
 * -----------------------------------------------------------------------------------------------*/

const outcomeActions = cva('space-y-m px-l py-xxxl');
type OutcomeActionsProps = WithChildren;

const OutcomeActions = ({ children }: OutcomeActionsProps) => (
  <div className={outcomeActions()}>{children}</div>
);

export {
  OutcomeActions as Actions,
  OutcomeBody as Body,
  OutcomeHeading as Heading,
  Outcome as Root,
};
