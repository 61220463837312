import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Text, Button } from '@pm/ui';
import { DoxyPepInfoModal } from '../DoxyPepTreatment/InfoModal/DoxyPepInfoModal';
import { TreatmentCardItem } from '../hooks/additional-treatments';
import AdditionalTreatmentCTA from './AdditionalTreatmentCTA';

type SharedProps = {
  treatment: TreatmentCardItem;
};

export const AdditionalTreatmentCard = ({ treatment }: SharedProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview.ExploreTreatments',
  });
  const [doxyPepModalOpen, setDoxyPepModalOpen] = useState(false);

  return (
    <Card>
      <div
        className="flex-col space-y-l p-s"
        aria-label={`${treatment.externalValue} card`}
      >
        <div className="space-y-xs">
          <Text size="headingS">{treatment.label}</Text>
          <Text>{treatment.description}</Text>
        </div>
        <div className="flex gap-l">
          {treatment.getStarted && (
            <AdditionalTreatmentCTA treatment={treatment} buttonWidth="fit" />
          )}
          {treatment.infoModal && (
            <Button
              intent="ghost"
              size="small"
              width="fit"
              aria-label={`Learn more about ${treatment.externalValue}`}
              onClick={() => setDoxyPepModalOpen(true)}
            >
              {t('LearnMore')}
            </Button>
          )}
        </div>
      </div>

      {doxyPepModalOpen && (
        <DoxyPepInfoModal
          open={doxyPepModalOpen}
          onOpenChange={setDoxyPepModalOpen}
          treatment={treatment}
        />
      )}
    </Card>
  );
};
