import { faCopy, IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FunctionComponent, PropsWithChildren } from 'react';
import toast from 'react-hot-toast';

type Props = {
  text: string | (() => string);
  icon?: IconDefinition;
  className?: string;
  /** Callback after the text is successfully copied */
  onCopy?: () => void;
};

export const copyToClipboard = ({
  text,
  onCopy,
}: {
  text: string | (() => string);
  onCopy?: () => void;
}) => {
  const textContent = typeof text === 'function' ? text() : text;

  navigator.clipboard
    .writeText(textContent)
    .then(() => {
      toast.success('Copied');
      onCopy?.();
    })
    .catch(() => toast.error('Copy failed'));
};

export const ClipboardButton: FunctionComponent<PropsWithChildren<Props>> = ({
  text,
  icon = faCopy,
  className = 'cursor-pointer text-grey-400 hover:text-grey-500',
  onCopy,
}) => (
  <button
    type="button"
    aria-label="Copy to clipboard"
    onClick={() => copyToClipboard({ text, onCopy })}
  >
    <FontAwesomeIcon icon={icon} className={className} title="Copy" />
  </button>
);
