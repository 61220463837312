import { Card } from '.';
import { CardText } from '../CardText';
import { FunctionComponent, PropsWithChildren, ReactElement } from 'react';
import { Spacer } from '../Spacer';
import { ExclamationCircleIcon } from '@heroicons/react/20/solid';

interface ErrorCardProps {
  title: string;
  message?: string;
  children?: ReactElement;
}

export const ErrorCard: FunctionComponent<
  PropsWithChildren<ErrorCardProps>
> = ({ title, message, children }) => {
  return (
    <Card className="border-freddie-red text-freddie-red border shadow-none">
      <div className="flex flex-row">
        <ExclamationCircleIcon className="text-freddie-red mr-3 mt-1 h-5" />
        <div className="text-lg font-bold">{title}</div>
      </div>
      <Spacer size={4} />
      {message && <CardText className="text-freddie-red">{message}</CardText>}
      {children}
    </Card>
  );
};
