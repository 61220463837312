import {
  hasOnlyServerError,
  hasServerError,
} from '@pm/core/src/utilities/errors';
import { Button, ButtonProps, Icon } from '@pm/ui';
import { useFormContext } from 'react-hook-form';

type FormSubmitButtonProps = ButtonProps & {
  loading?: boolean;
  disableAfterSubmit?: boolean;
};

export const FormSubmitButton = ({
  disabled,
  loading,
  disableAfterSubmit = false,
  children,
  ...restProps
}: FormSubmitButtonProps) => {
  const {
    formState: { isSubmitting, isValid, isSubmitSuccessful, errors },
  } = useFormContext();

  const isSuccessfullySubmitted = isSubmitSuccessful && !hasServerError(errors);
  const allFieldsValid = isValid || hasOnlyServerError(errors);
  const isLoading = loading ?? isSubmitting;

  const isDisabled =
    isLoading ||
    !allFieldsValid ||
    disabled ||
    (disableAfterSubmit && isSuccessfullySubmitted);

  return (
    <Button type="submit" disabled={isDisabled} {...restProps}>
      <span className="flex gap-x-s">
        {isLoading && (
          <Icon
            name="progress_activity"
            size="large"
            animation="animate-spin"
          />
        )}
        {children}
      </span>
    </Button>
  );
};
