import { cloneElement } from 'react';
import { ButtonProps } from '../Button/Button';
import { ButtonLinkProps } from '../ButtonLink/ButtonLink';

// FIXME: This doesn't seem to prevent non-Button or non-ButtonLink children
export type InlineMessageActionType = React.ReactElement<
  ButtonProps | ButtonLinkProps
>;

type InlineMessageActionProps = {
  children: InlineMessageActionType;
};

const styles: ButtonProps = {
  intent: 'ghost',
  size: 'small',
  width: 'fit',
};

export const InlineMessageAction = ({ children }: InlineMessageActionProps) =>
  cloneElement(children, { ...styles });

InlineMessageAction.displayName = 'InlineMessageAction';
