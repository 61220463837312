import clsx from 'clsx';
import { ComponentPropsWithoutRef, forwardRef, ElementRef, useId } from 'react';

type RadioRowElement = ElementRef<'input'>;
interface RadioRowProps
  extends Omit<ComponentPropsWithoutRef<'input'>, 'type'> {
  label: string;
  helpText?: string;
  showRadio?: boolean;
  icon?: React.ReactNode;
  checked: boolean;
}

const RadioRow = forwardRef<RadioRowElement, RadioRowProps>(
  (
    { helpText, icon, label, children, showRadio = true, ...props },
    forwardedRef,
  ) => {
    const baseId = useId();
    const labelId = `${baseId}-label`;
    const helpTextId = `${baseId}-help`;

    return (
      <div>
        <label className="hover:bg-brand-primary-50 active:bg-brand-primary-100 border-grey-200 relative flex cursor-pointer p-6 focus:outline-none sm:p-10">
          <input
            {...props}
            type="radio"
            className={clsx(
              'text-brand-primary-500 border-grey-300 mt-1 shrink-0 cursor-pointer',
              showRadio
                ? ' focus:ring-brand-primary-500 h-4 w-4 opacity-100 focus:opacity-80 focus:ring-offset-2'
                : 'h-0 w-0 opacity-0',
            )}
            aria-labelledby={labelId}
            aria-describedby={helpText ? helpTextId : undefined}
            ref={forwardedRef}
          />
          <span className={clsx('flex flex-col', showRadio && 'ml-4')}>
            <span className="flex items-center gap-4">
              {icon && <span className="w-6">{icon}</span>}
              <span
                id={labelId}
                className={clsx(
                  'text-grey-900 block text-lg font-medium leading-tight',
                )}
              >
                {label}
              </span>
            </span>

            {helpText && (
              <span
                id={helpTextId}
                className="text-grey-600 mt-3 block text-sm"
              >
                {helpText}
              </span>
            )}
          </span>
        </label>
        {props.checked ? children : null}
      </div>
    );
  },
);

RadioRow.displayName = 'RadioRow';

export { RadioRow };
