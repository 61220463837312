import { Icon, Text } from '@pm/ui';
import { useTranslation } from 'react-i18next';

type AppointmentDurationProps = {
  durationInMinutes: number;
};

export const AppointmentDuration = ({
  durationInMinutes,
}: AppointmentDurationProps) => {
  const { t } = useTranslation('appointments', {
    keyPrefix: 'Appointment.Summary',
  });

  const appointmentDuration = t('AppointmentDuration', {
    durationInMinutes,
  });

  return (
    <div className="flex space-x-s items-center">
      <Icon name="schedule" size="xl" />
      <Text size="bodyL" title={appointmentDuration}>
        {appointmentDuration}
      </Text>
    </div>
  );
};
