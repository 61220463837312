import { MouseEventHandler } from 'react';
import { cva, type VariantProps } from 'class-variance-authority';
import { twMerge } from 'tailwind-merge';
import { type WithIcon } from '../../types/component.types';
import { ChipWrapper } from './ChipWrapper';
import { Icon } from '../Icon/Icon';

export const chip = cva(
  'items-center rounded-s p-xs text-base gap-x-xs inline-flex max-h-xxl border border-border-default text-content-subdued',
  {
    variants: {
      pattern: {
        clickable: '',
        deletable: 'bg-accent-subtle hover:bg-accent-subtle',
      },
    },
    defaultVariants: {
      pattern: 'clickable',
    },
  },
);

export type ChipProps = VariantProps<typeof chip> &
  WithIcon & {
    text: string;
    selected?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    onDelete?: () => void;
  };

/**
 * Binary control that can be selected and deselected. Chip text should not exceed 20 characters, and if it does, the text will be truncated with an ellipses.
 *
 * @param pattern - The functional mode of the chip.
 *        - "clickable" (default) - Can be selected and deselected. Cannot be dismissed or changed.
 *        - "deletable" - Displayed based on a secondary control (ie. a menu) and are displayed until dismissed.
 * @param selected - Whether the chip is selected or not. Only used when pattern is 'clickable'.
 * @param onClick - Callback function that is called when the chip is clicked. Only used when pattern is 'clickable'.
 * @param onDelete - Callback function that is called when the chip is removed. Only used when pattern is 'deletable'.
 *
 * @example
 * <Chip pattern="deletable" leadingIcon={<Icon name="groups" />}>Contacts</Chip>
 */
export const Chip = ({
  selected,
  leadingIcon,
  trailingIcon,
  text,
  onClick,
  onDelete,
  pattern = 'clickable',
}: ChipProps) => {
  const truncate = text.length > 20;

  return (
    <ChipWrapper
      pattern={pattern}
      onClick={onClick}
      className={twMerge(
        chip({ pattern }),
        selected
          ? 'bg-accent-subtle hover:bg-accent-subtle-hover'
          : 'bg-surface-default hover:bg-surface-strong',
      )}
    >
      {leadingIcon}
      <span
        // TODO: show @pm/ui Tooltip on hover with full text
        className={
          truncate ? 'truncate inline-block text-ellipses w-[127px]' : ''
        }
      >
        {text}
      </span>
      {trailingIcon}
      {pattern === 'deletable' && (
        <button
          aria-label={`Delete ${text} chip`}
          type="button"
          onClick={onDelete}
        >
          <Icon name="close" size="large" />
        </button>
      )}
    </ChipWrapper>
  );
};
