import { useMemo } from 'react';
import { useScript } from '../../hooks/useScript';
import { getAffirmConfig } from './affirmConfig';

export function useAffirm() {
  const { publicKey, scriptUrl } = getAffirmConfig();

  window._affirm_config = {
    public_api_key: publicKey,
  };

  const { sdk } = useScript<typeof window.affirm>(scriptUrl, 'affirm');

  return useMemo(
    () => ({
      refresh() {
        sdk?.ui.refresh();
      },
    }),
    [sdk],
  );
}
