import { useEffect, useState } from 'react';
import { DatePartDropdown } from './DatePartDropdown';
import { getDaysInMonth, isSameDay, isValid } from 'date-fns';
import { MonthName } from './months';

const defaultMonthsNames = Array.from(
  { length: 12 },
  (_, index) => `${index + 1}`,
);

const defaultYearTo = new Date().getFullYear();

export type DropdownDatePickerProps = {
  disabled?: boolean;
  monthsNames?: MonthName[];
  value?: Date;
  yearFrom: number;
  yearTo?: number;
  onChange: (date: Date | null) => void;
};

const buildNumericOptions = ({
  size,
  start = 1,
  textOptions,
}: {
  size: number;
  start?: number;
  textOptions?: string[];
}) => {
  return Array.from({ length: size }, (_, index) => ({
    value: index + start,
    text: (textOptions && textOptions[index]) || index + start + '',
  }));
};

const defaultDayOptions = buildNumericOptions({ size: 31 });

export const DropdownDatePicker = ({
  disabled,
  monthsNames,
  value,
  yearFrom,
  yearTo = defaultYearTo,
  onChange,
}: DropdownDatePickerProps) => {
  const [dateValue, setDateValue] = useState<Date | null>(value || null);
  const [day, setDay] = useState<number | null>(value?.getDate() || null);
  const [month, setMonth] = useState<number | null>(value?.getMonth() || null);
  const [year, yetYear] = useState<number | null>(value?.getFullYear() || null);
  const [hadValidDate, setHadValidDate] = useState<boolean>(!!value);

  const [dayOptions, setDayOptions] = useState(defaultDayOptions);
  const monthsOptions = buildNumericOptions({
    size: 12,
    textOptions: monthsNames ?? defaultMonthsNames,
    start: 0,
  });
  const yearOptions = buildNumericOptions({
    size: yearTo - yearFrom + 1,
    start: yearFrom,
  });

  useEffect(() => {
    if (!year || month === null || !day) {
      return setDateValue(null);
    }

    const newDate = new Date(year, month, day);
    if (isValid(newDate) && newDate.getDate() === Number(day)) {
      setHadValidDate(true);
      setDateValue(newDate);
    } else {
      setDay(null);
    }
  }, [year, month, day]);

  useEffect(() => {
    if (!year || !month) {
      return;
    }

    const newDaysNumber = getDaysInMonth(new Date(year, month));
    setDayOptions(buildNumericOptions({ size: newDaysNumber }));
  }, [year, month]);

  useEffect(() => {
    if (
      (!value && dateValue) ||
      (value && dateValue && !isSameDay(value, dateValue)) ||
      (!dateValue && hadValidDate)
    ) {
      onChange(dateValue);
    }
  }, [dateValue, onChange, value, hadValidDate]);

  return (
    <div className="grid grid-cols-3 grid-rows-1 place-content-center gap-m">
      <DatePartDropdown
        id="month"
        label="Month"
        disabled={disabled}
        options={monthsOptions}
        value={`${month}`}
        onChange={setMonth}
      />
      <DatePartDropdown
        id="day"
        label="Day"
        disabled={disabled}
        options={dayOptions}
        value={`${day}`}
        onChange={setDay}
      />
      <DatePartDropdown
        id="year"
        label="Year"
        disabled={disabled}
        options={yearOptions}
        value={`${year}`}
        onChange={yetYear}
      />
    </div>
  );
};
