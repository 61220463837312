import {
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  SetStateAction,
} from 'react';
import { Dialog as HeadlessDialog } from '@headlessui/react';
import clsx from 'clsx';

export type DialogProps = {
  open: boolean;
  onClose: Dispatch<SetStateAction<boolean>>;
  className?: string;
  fullWidth?: boolean;
};

export const Dialog: FunctionComponent<PropsWithChildren<DialogProps>> = ({
  open,
  onClose,
  className,
  fullWidth = false,
  children,
}) => {
  return (
    <HeadlessDialog
      open={open}
      onClose={onClose}
      as="div"
      className={clsx(
        'fixed inset-0 flex items-center justify-center overflow-y-auto',
        className,
      )}
    >
      <HeadlessDialog.Overlay className="bg-grey-600 fixed inset-0 opacity-50" />

      <div
        className={clsx(
          'text-grey-900 relative flex flex-col rounded-lg bg-white p-6',
          fullWidth ? 'w-full' : 'w-512',
        )}
      >
        {children}
      </div>
    </HeadlessDialog>
  );
};
