import { formatDateOfBirth } from '../../utilities/dateUtilities';
import { ElementRef, ComponentPropsWithoutRef, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useEnvironment } from '../../hooks/env';

type DateOfBirthDisplayElement = ElementRef<'span'>;
interface DateOfBirthDisplayProps extends ComponentPropsWithoutRef<'span'> {
  dateOfBirth?: string | null;

  fallbackMessage?: string;
}

export const DateOfBirthDisplay = forwardRef<
  DateOfBirthDisplayElement,
  DateOfBirthDisplayProps
>(
  (
    { dateOfBirth, fallbackMessage = '(Not provided)', ...props },
    forwardedRef,
  ) => {
    const { i18n } = useTranslation();
    const { formattingContext } = useEnvironment();

    return (
      <span {...props} ref={forwardedRef}>
        {dateOfBirth
          ? formatDateOfBirth(dateOfBirth, formattingContext, i18n.language)
          : fallbackMessage}
      </span>
    );
  },
);

DateOfBirthDisplay.displayName = 'DateOfBirthDisplay';
