import clsx from 'clsx';
import { FunctionComponent } from 'react';

type Props = {
  text: string;
  variant?: string;
  className?: string;
};

export const QuestionnaireDescription: FunctionComponent<Props> = ({
  text,
  variant = 'noStyle',
  className,
}) => (
  <div
    className={clsx(
      className,
      variant === 'noStyle' && 'mb-6',
      variant === 'grey' && 'text-grey-500 text-base font-normal',
    )}
  >
    {text}
  </div>
);
