import { cva, type VariantProps } from 'class-variance-authority';

export const cardBase = cva('flex-col rounded-m border text-left w-full', {
  variants: {
    size: { small: 'p-s space-y-xs', large: 'p-m space-y-s' },
    state: {
      warning: 'bg-warning-subtle border-0',
      neutral: 'bg-neutral-subtle',
      surface: 'bg-surface',
    },
  },
  defaultVariants: { size: 'small', state: 'surface' },
});

export type CardBaseProps = VariantProps<typeof cardBase> & {
  children: React.ReactNode;
  title?: string;
};
