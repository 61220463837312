import { Icon } from '../Icon/Icon';
import { IconName } from '../Icon/icons';
import { Text } from '../Text/Text';

export type EmptyStateProps = {
  iconName?: IconName;
  header: string;
  body: string;
  children?: React.ReactNode;
};

export const EmptyState = ({
  iconName,
  header,
  body,
  children,
}: EmptyStateProps) => {
  return (
    <div className="space-y-l">
      {iconName && (
        <div className="w-[100px] h-[100px] flex items-center justify-center mx-auto">
          <Icon name={iconName} size="xxxl" color="text-content-subdued" />
        </div>
      )}
      <div className="flex align-items-center justify-center">
        <div className="flex flex-col text-center max-w-sm">
          <Text
            as="h2"
            size="headingS"
            color="contentSubdued"
            className="mb-xs"
          >
            {header}
          </Text>
          <Text size="bodyL" color="contentSubdued">
            {body}
          </Text>
          {children && <div className="mt-l">{children}</div>}
        </div>
      </div>
    </div>
  );
};
