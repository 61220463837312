import { VariantProps, cva } from 'class-variance-authority';

/**
 * Base styling for all input borders excluding radios and checkbox
 */
export const inputBorderBase =
  'border border-default bg-surface text-content rounded-s focus:border-accent focus-within:border-accent disabled:border-strong disabled:bg-surface-disabled disabled:text-content-disabled hover:border-strong focus-within:hover:border-accent';

/**
 * Base error styling for inputs excluding radios and checkbox
 */
export const inputErrorBase =
  'border-negative hover:border-negative focus:border-negative focus-within:hover:border-negative focus-within:border-negative';

/**
 * Base styling for all input fields excluding radios and checkbox
 */
export const inputBase = cva(
  `${inputBorderBase} py-s px-s w-full focus:ring-0`,
  {
    variants: {
      intent: {
        default: 'hover:border-strong',
        error: inputErrorBase,
        headless: 'border-none',
      },
      size: {
        sm: 'h-s text-body-s',
        lg: 'h-l text-body-l',
      },
    },
    defaultVariants: {
      size: 'sm',
      intent: 'default',
    },
  },
);

export type InputBaseProps = VariantProps<typeof inputBase> & {};
