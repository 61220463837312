import { type VariantProps, cva } from 'class-variance-authority';
import {
  type ReactNode,
  type HTMLAttributes,
  type TdHTMLAttributes,
  type ThHTMLAttributes,
  forwardRef,
} from 'react';

/** @example TODO */

/* -------------------------------------------------------------------------------------------------
 * Table
 * -----------------------------------------------------------------------------------------------*/

const TABLE_NAME = 'Table';

const table = cva('w-full');

type TableProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLTableElement> &
  VariantProps<typeof table>;

const Table = forwardRef<HTMLTableElement, TableProps>(({ children }, ref) => {
  return (
    <table ref={ref} className={table()}>
      {children}
    </table>
  );
});
Table.displayName = TABLE_NAME;

/* -------------------------------------------------------------------------------------------------
 * Table Header
 * -----------------------------------------------------------------------------------------------*/

const tableHeader = cva('py-l px-m text-content-subdued text-label border-b');

type TableHeaderProps = HTMLAttributes<HTMLTableSectionElement> &
  VariantProps<typeof tableHeader>;

const TABLE_HEADER = 'TableHeader';

const TableHeader = forwardRef<HTMLTableSectionElement, TableHeaderProps>(
  ({ ...props }, ref) => (
    <thead ref={ref} className={tableHeader()} {...props} />
  ),
);
TableHeader.displayName = TABLE_HEADER;

/* -------------------------------------------------------------------------------------------------
 * Table Head
 * -----------------------------------------------------------------------------------------------*/

const tableHead = cva('text-left bg-surface cursor-default px-m py-l');

type TableHeadProps = ThHTMLAttributes<HTMLTableCellElement> &
  VariantProps<typeof tableHead>;

const TABLE_HEAD_NAME = 'TableHead';

const TableHead = forwardRef<HTMLTableCellElement, TableHeadProps>(
  ({ ...props }, ref) => <th ref={ref} className={tableHead()} {...props} />,
);
TableHead.displayName = TABLE_HEAD_NAME;

/* -------------------------------------------------------------------------------------------------
 * Table Title
 * -----------------------------------------------------------------------------------------------*/

const tableTitle = cva('text-left text-heading-m gap-xs px-l py-l pb-0');

type TableTitleProps = HTMLAttributes<HTMLHeadingElement> &
  VariantProps<typeof tableTitle>;

const TABLE_TITLE_NAME = 'TableTitle';

const TableTitle = forwardRef<HTMLHeadingElement, TableTitleProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref} className={tableTitle()} {...props}>
      {children}
    </div>
  ),
);
TableTitle.displayName = TABLE_TITLE_NAME;

/* -------------------------------------------------------------------------------------------------
 * Table CTA
 * -----------------------------------------------------------------------------------------------*/

const tableCTA = cva('flex flex-row gap-s pb-s pl-s pr-l pt-xxs border-b');

type TableCTAProps = {
  children: ReactNode;
} & HTMLAttributes<HTMLTableSectionElement> &
  VariantProps<typeof tableCTA>;

const TABLE_CTA_NAME = 'TableCTA';

const TableCTA = forwardRef<HTMLTableSectionElement, TableCTAProps>(
  ({ children, ...props }, ref) => (
    <div ref={ref} className={tableCTA()} {...props}>
      {children}
    </div>
  ),
);
TableCTA.displayName = TABLE_CTA_NAME;

/* -------------------------------------------------------------------------------------------------
 * Table Row
 * -----------------------------------------------------------------------------------------------*/

const tableRow = cva('border-b max-h-[60px] transition-colors', {
  variants: {
    surface: {
      default: 'bg-surface text-content',
      strong: 'bg-surface-strong text-content-subdued',
    },
    state: {
      enabled: '',
      disabled:
        'bg-surface-disabled hover:cursor-auto text-content-disabled hover:bg-surface-disabled active:bg-surface-disabled',
    },
    clickable: {
      true: 'cursor-pointer hover:bg-surface-hover active:bg-surface-active',
      false: 'cursor-auto',
    },
  },
  defaultVariants: {
    surface: 'default',
    state: 'enabled',
  },
});

type TableRowProps = {
  disabled?: boolean;
  onRowClick?: () => void;
} & HTMLAttributes<HTMLTableRowElement> &
  VariantProps<typeof tableRow>;

const TABLE_ROW_NAME = 'TableRow';

const TableRow = forwardRef<HTMLTableRowElement, TableRowProps>(
  ({ surface, onRowClick, disabled = false, ...props }, ref) => {
    const className = tableRow({
      surface,
      state: disabled ? 'disabled' : 'enabled',
      clickable: !!onRowClick,
    });

    return (
      <tr ref={ref} onClick={onRowClick} className={className} {...props} />
    );
  },
);
TableRow.displayName = TABLE_ROW_NAME;

/* -------------------------------------------------------------------------------------------------
 * Table Cell
 * -----------------------------------------------------------------------------------------------*/

const tableCell = cva('text-left text-ellipses px-m py-l');

type TableCellProps = TdHTMLAttributes<HTMLTableCellElement> &
  VariantProps<typeof tableCell>;

const TABLE_CELL_NAME = 'TableCell';

const TableCell = forwardRef<HTMLTableCellElement, TableCellProps>(
  ({ children, ...props }, ref) => (
    <td ref={ref} className={tableCell()} {...props}>
      {children}
    </td>
  ),
);
TableCell.displayName = TABLE_CELL_NAME;

/* -------------------------------------------------------------------------------------------------
 * Table Body
 * -----------------------------------------------------------------------------------------------*/

const tableBody = cva('overflow-y-auto');

type TableBodyProps = HTMLAttributes<HTMLTableSectionElement> &
  VariantProps<typeof tableBody>;

const TABLE_BODY_NAME = 'TableBody';

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(
  ({ ...props }, ref) => <tbody ref={ref} className={tableBody()} {...props} />,
);
TableBody.displayName = TABLE_BODY_NAME;

export {
  type TableProps,
  Table as Root,
  TableHead as Head,
  TableHeader as Header,
  TableTitle as Title,
  TableCTA as Actions,
  TableBody as Body,
  TableRow as Row,
  TableCell as Cell,
};
