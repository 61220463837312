import { useState, useEffect, useMemo } from 'react';
import { useIsMounted } from '../use-is-mounted/useIsMounted';

export const useToggle = (initialState = false) => {
  const isMounted = useIsMounted();
  const [state, setState] = useState(initialState);

  const handlers = useMemo(
    () => ({
      on() {
        isMounted() && setState(true);
      },
      off() {
        isMounted() && setState(false);
      },
      toggle() {
        isMounted() && setState((prevState) => !prevState);
      },
      reset() {
        isMounted() && setState(initialState);
      },
    }),
    [initialState, isMounted],
  );

  useEffect(() => {
    setState(initialState);
  }, [initialState]);

  return [state, handlers] as const;
};
