import clsx from 'clsx';
import { ElementRef, forwardRef, ReactNode, HTMLProps } from 'react';

type Props = HTMLProps<HTMLButtonElement> & {
  selected: boolean;
  text: ReactNode;
  onClick: () => void;
  vertical?: boolean;
  disabled?: boolean;
  className?: string;
};

type ToggleButtonElement = ElementRef<'button'>;

export const ToggleButton = forwardRef<ToggleButtonElement, Props>(
  (
    {
      selected,
      text,
      onClick,
      className,
      vertical = false,
      disabled = false,
      ...props
    },
    forwardedRef,
  ) => (
    <button
      {...props}
      type="button"
      ref={forwardedRef}
      onClick={onClick}
      disabled={disabled}
      className={clsx(
        'border-grey-500 w-full rounded-lg border px-6 py-3.5 transition duration-200 ease-in-out',
        selected ? 'bg-grey-600 text-white' : 'text-grey-600 bg-white',
        vertical ? 'block' : '',
        className,
      )}
      role="checkbox"
      aria-checked={selected ? 'true' : 'false'}
    >
      {text}
    </button>
  ),
);

ToggleButton.displayName = 'ToggleButton';
