import * as ProgressPrimitive from '@radix-ui/react-progress';
import { type VariantProps, cva } from 'class-variance-authority';
import invariant from 'tiny-invariant';

const progressBar = cva('h-xs bg-positive-subtle');

export type ProgressBarProps = VariantProps<typeof progressBar> &
  ProgressPrimitive.ProgressProps & {
    progress: number; // <= 100
    label?: string;
  };

export const ProgressBar = ({
  progress,
  label,
  ...props
}: ProgressBarProps) => {
  invariant(
    progress >= 0 && progress <= 100,
    'The progress prop must be greater than or equal to 0 and less than or equal to 100',
  );

  return (
    <ProgressPrimitive.Root
      {...props}
      value={progress}
      aria-label="Progress bar"
      aria-busy={progress < 100 && 'true'}
      aria-describedby={props['aria-describedby'] ?? 'Progress bar'}
      className="flex flex-col gap-xxs text-center"
    >
      <ProgressPrimitive.Indicator className={progressBar()}>
        <div
          className="h-xs bg-positive transition-all ease-in-out duration-300"
          style={{ width: `${progress}%` }}
        />
      </ProgressPrimitive.Indicator>
      {label && <div className="text-label text-content-subdued">{label}</div>}
    </ProgressPrimitive.Root>
  );
};
