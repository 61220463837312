import { PropsWithChildren } from 'react';
import clsx from 'clsx';

type CardTitleProps = {
  as?: 'h1' | 'h2' | 'h3';
  applyMarginBottom?: boolean;
  variant?: '2xl' | 'xl' | 'lg' | 'sm';
  className?: string;
  branded?: boolean;
};

export const CardTitle = ({
  as: Component = 'h1',
  children,
  className,
  variant = '2xl',
  branded = false,
  applyMarginBottom = true,
}: PropsWithChildren<CardTitleProps>) => (
  <Component
    className={clsx(
      'text-grey-900 font-medium',
      applyMarginBottom && 'mb-4',
      branded && 'text-brand-primary-600',
      variant === '2xl' && 'text-2xl',
      variant === 'xl' && 'text-xl',
      variant === 'lg' && 'text-lg',
      variant === 'sm' && 'text-small',
      className,
    )}
  >
    {children}
  </Component>
);
