import { To, useLocation } from 'react-router-dom';

export const LOCATION_STATE_RETURN_TO_KEY = 'returnTo';

export function useLocationReturnTo({
  fallback,
}: {
  fallback: string;
}): Exclude<To, 'string'> {
  const location = useLocation();
  const returnTo: To = location.state?.[LOCATION_STATE_RETURN_TO_KEY] || null;

  if (!returnTo) {
    return {
      pathname: fallback,
      search: '',
      hash: '',
    };
  }

  const [pathname, search, hash] =
    typeof returnTo === 'string'
      ? [returnTo, '', '']
      : [returnTo.pathname, returnTo.search, returnTo.hash];

  return {
    pathname,
    search,
    hash,
  };
}

type LocationReturnToState = {
  [LOCATION_STATE_RETURN_TO_KEY]: To;
};

export function useBuildLocationReturnToState(): LocationReturnToState {
  const location = useLocation();

  return {
    [LOCATION_STATE_RETURN_TO_KEY]: {
      pathname: location.pathname,
      search: location.search,
      hash: location.hash,
    },
  };
}
