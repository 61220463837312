export const DottedVerticalLine = () => {
  return (
    <div className="text-content-positive mx-4 my-1 space-y-1">
      {Array(11)
        .fill(null)
        .map((_, index) => (
          <svg
            key={index}
            className="h-0.5 -translate-x-1/2 transform fill-current"
            viewBox="0 0 1 1"
          >
            <circle cx="50%" cy="50%" r="50%" />
          </svg>
        ))}
    </div>
  );
};
