import { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  topMargin?: number;
  tableType?: string;
  testId?: string;
};

export const Table: FunctionComponent<PropsWithChildren<Props>> = ({
  topMargin = 6,
  children,
  testId,
  tableType = 'auto',
}) => (
  <div className={`mt-${topMargin} flex flex-col`}>
    <div className="-my-2 py-2 sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
      <div className="border-grey-200 inline-block min-w-full border align-middle shadow sm:rounded-lg">
        <table className={`w-full table-${tableType}`} data-testid={testId}>
          {children}
        </table>
      </div>
    </div>
  </div>
);
