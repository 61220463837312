import { Phone } from '@pm/graphql';
import {
  formatPhoneNumber,
  getPhoneNumberForInput,
} from '../../utilities/phoneNumberUtilities';

type PhoneNumberDisplayProps = {
  phone: Pick<Phone, 'e164' | 'rawNumber'> | null;
  notProvidedMessage?: string | null;
};

export function PhoneNumberDisplay({
  phone = null,
  notProvidedMessage = null,
}: PhoneNumberDisplayProps) {
  if (!phone) {
    return <>{notProvidedMessage}</>;
  }
  const number = getPhoneNumberForInput(phone);
  const parsedPhoneNumber = formatPhoneNumber(number);

  if (!parsedPhoneNumber) {
    return <>{notProvidedMessage}</>;
  }

  return <>{parsedPhoneNumber}</>;
}
