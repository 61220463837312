import clsx from 'clsx';
import { FunctionComponent } from 'react';

type Props = {
  value: boolean;
  label: string;
  onChange: () => void;
};

export const Toggle: FunctionComponent<Props> = ({
  value,
  label,
  onChange,
}) => {
  return (
    <button
      type="button"
      className="mr-2 flex cursor-pointer items-center"
      onClick={onChange}
    >
      <div className="text-md mr-2">{label}</div>
      <div className="relative w-10">
        <div className="bg-grey-200 absolute top-1/2 h-5 w-full -translate-y-1/2 transform rounded-full shadow-inner"></div>
        <div
          className={clsx(
            'absolute top-1/2 h-6 w-6 -translate-y-1/2 transform rounded-full shadow transition-all duration-500 ease-in-out',
            value
              ? 'bg-brand-primary-500 left-full -translate-x-full'
              : 'bg-grey-400 left-0',
          )}
        ></div>
      </div>
    </button>
  );
};
