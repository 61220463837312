import { cva, type VariantProps } from 'class-variance-authority';
import { type WithIcon } from '../../types/component.types';

const tag = cva(
  'rounded-circle items-center inline-flex truncate text-ellipsis',
  {
    variants: {
      intent: {
        neutral: 'bg-neutral-subtle text-content-subdued',
        info: 'bg-info-subtle text-info',
        positive: 'bg-positive-subtle text-positive',
        warning: 'bg-warning-subtle text-warning',
        negative: 'bg-negative-subtle text-negative',
      },
      size: {
        large: 'py-s px-s text-body-s gap-x-xs max-h-xxl',
        small: 'py-xs px-xs text-label gap-x-xxs max-h-xl',
      },
    },
    defaultVariants: {
      intent: 'neutral',
      size: 'large',
    },
  },
);

type TagProps = VariantProps<typeof tag> &
  WithIcon & {
    children: string;
  };

/**
 * Tags are used to highlight or categorize items.
 *
 * @example
 * <Tag intent="warning">Danger! Are you sure you want to do this???</Tag>
 */
export function Tag({
  intent,
  size,
  leadingIcon,
  trailingIcon,
  children,
}: TagProps) {
  return (
    <div className={tag({ intent, size })}>
      {leadingIcon}
      {children}
      {trailingIcon}
    </div>
  );
}
