import { FC } from 'react';
import {
  formatHealthCardNumber,
  healthCardNumberFormatForProvince,
} from '../../utilities/healthCardNumberFormatter';

type Props = {
  healthCardNumber?: string | null;
  provinceOfCoverageId?: string | null;
  notProvidedText?: string;
};

export const HealthCardNumber: FC<Props> = ({
  healthCardNumber,
  provinceOfCoverageId,
  notProvidedText = '',
}) => {
  const healthCardNumberIsEmpty =
    healthCardNumber == null || healthCardNumber === '';

  if (healthCardNumberIsEmpty) {
    return <>{notProvidedText}</>;
  }

  const formattedHealthCardNumber = formatHealthCardNumber(
    healthCardNumber,
    healthCardNumberFormatForProvince(provinceOfCoverageId),
  );

  return <>{formattedHealthCardNumber}</>;
};
