import { ReactNode } from 'react';

type MessagesMenuBadgeProps = {
  text: ReactNode;
};

const MessagesMenuBadge = ({ text }: MessagesMenuBadgeProps) => {
  return (
    <span className="bg-messages-badge-background text-messages-badge-foreground flex h-5 content-center items-center justify-center rounded-full p-3 text-center text-sm font-medium">
      {text}
    </span>
  );
};

export default MessagesMenuBadge;
