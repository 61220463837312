type TreatmentManagerRouteParams = { treatmentType: string };

export const PrepTreatmentRouteMap = {
  treatmentManager: {
    path: '/treatments/:treatmentType',
    build({ treatmentType }: TreatmentManagerRouteParams) {
      return this.path.replace(':treatmentType', treatmentType);
    },
  },

  restartTreatment: {
    path: '/treatments/:treatmentType/restart',
    build({ treatmentType }: TreatmentManagerRouteParams) {
      return this.path.replace(':treatmentType', treatmentType);
    },
  },

  pauseTreatment: {
    path: '/treatments/:treatmentType/pause',
    build({ treatmentType }: TreatmentManagerRouteParams) {
      return this.path.replace(':treatmentType', treatmentType);
    },
  },

  stopTreatment: {
    path: '/treatments/:treatmentType/stop',
    build({ treatmentType }: TreatmentManagerRouteParams) {
      return this.path.replace(':treatmentType', treatmentType);
    },
  },

  clinicalRecommendations: {
    path: '/treatments/:treatmentType/:action/clinical-recommendations',
    build({
      treatmentType,
      action,
    }: TreatmentManagerRouteParams & { action: 'stop' | 'pause' }) {
      return this.path
        .replace(':treatmentType', treatmentType)
        .replace(':action', action);
    },
  },
};
