import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client/index.js';
import { setContext } from '@apollo/client/link/context';
import { relayStylePagination } from '@apollo/client/utilities';
import possibleTypes from '@pm/graphql/src/possible-types.json';
import { Brand } from '../types';
import { errorLink, renewLink } from '../utilities/apolloLinks';

export const toInMemoryCacheOptions = (): Partial<InMemoryCache['config']> => ({
  typePolicies: {
    Query: {
      fields: {
        conversations: relayStylePagination(['filter']),
        conversationsNoMerging: { merge: false, keyArgs: false },
        users: relayStylePagination(['filter']),
        workflowSteps: relayStylePagination(['filter']),
        postConsultActions: relayStylePagination(['filter']),
      },
    },
    User: {
      fields: {
        prescriptions: relayStylePagination(),
        labRequisitions: relayStylePagination(['filter']),
      },
    },
  },
  possibleTypes,
});

export const toApolloClient = ({
  apiHost,
  brand,
}: {
  apiHost: string;
  brand: Brand;
}) => {
  // setup auth and the base url in graphql
  const httpLink = createHttpLink({ uri: `${apiHost}/graphql` });

  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('jwt');

    // return the headers to the context so httpLink can read them
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        brand,
      },
    };
  });

  const inMemoryCacheOptions = toInMemoryCacheOptions();

  const client = new ApolloClient({
    link: ApolloLink.from([authLink, renewLink, errorLink, httpLink]),
    cache: new InMemoryCache(inMemoryCacheOptions),
  });

  return client;
};
