import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, ButtonLink, ButtonProps, useToast } from '@pm/ui';
import { trackEvent } from '@pm/analytics';
import { useQuestionnaire } from '../../../components/Questionnaire/use-questionnaire';
import { TreatmentCardItem } from '../hooks/additional-treatments';
import { QuestionnaireDefinition } from '../../../types';
import {
  TreatmentType,
  useOnboardAdditionalTreatmentMutation,
} from '@pm/graphql';
import invariant from 'tiny-invariant';

type AdditionalTreatmentCTAProps = {
  treatment: TreatmentCardItem;
  buttonWidth?: ButtonProps['width'];
};

const AdditionalTreatmentCTA = ({
  treatment,
  buttonWidth,
}: AdditionalTreatmentCTAProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview.ExploreTreatments',
  });

  if (treatment.formRoute) {
    return (
      <ButtonLink
        to={treatment.formRoute() ?? '/404'}
        width={buttonWidth}
        size="small"
        aria-label={`Get started with ${treatment.externalValue}`}
        onClick={() =>
          trackEvent('Additional Treatment Survey Started', {
            treatment: treatment.externalValue,
          })
        }
      >
        {t('GetStarted')}
      </ButtonLink>
    );
  } else if (treatment.questionnaire) {
    return (
      <StartAdditionalTreatmentVocative
        questionnaireDefinition={treatment.questionnaire}
        buttonWidth={buttonWidth}
        type={treatment.type}
      />
    );
  } else {
    return null;
  }
};

type StartAdditionalTreatmentVocativeProps = {
  questionnaireDefinition: QuestionnaireDefinition;
  buttonWidth?: ButtonProps['width'];
  type?: TreatmentType;
};

export const StartAdditionalTreatmentVocative = ({
  questionnaireDefinition,
  buttonWidth,
  type,
}: StartAdditionalTreatmentVocativeProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview',
  });
  const navigate = useNavigate();
  const { negative } = useToast();
  let workflowStepId = '';
  invariant(questionnaireDefinition, 'Questionnaire definition is required');
  invariant(type, 'Treatment type is required');
  const [onboardTreatment] = useOnboardAdditionalTreatmentMutation({
    onError: () => {
      negative({
        title: t('Errors.Generic.Title'),
        content: t('Errors.Generic.Message'),
      });
    },
  });

  const { startQuestionnaire } = useQuestionnaire({
    definitionName: questionnaireDefinition,
    workflowStepId: workflowStepId,
    onSuccess: (responseId: string) => navigate(`/responses/${responseId}`),
    onError: () =>
      negative({
        title: t('Errors.Generic.Title'),
        content: t('Errors.Generic.Message'),
      }),
  });

  const handleClick = async () => {
    const response = await onboardTreatment({
      variables: {
        input: {
          treatmentType: type,
        },
      },
    });
    if (response.data?.onboardAdditionalTreatment) {
      workflowStepId =
        response.data.onboardAdditionalTreatment.questionnaireStepId ?? '';
      if (workflowStepId) {
        startQuestionnaire({
          variables: {
            input: {
              workflowStepId: workflowStepId,
              definitionName: questionnaireDefinition,
            },
          },
        });
      }
    }
  };

  return (
    <Button width={buttonWidth} size="small" onClick={() => handleClick()}>
      {t('ExploreTreatments.GetStarted')}
    </Button>
  );
};

export default AdditionalTreatmentCTA;
