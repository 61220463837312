import { FunctionComponent, PropsWithChildren } from 'react';

type GateLayoutProps = {
  brand: JSX.Element;
};

export const GateLayout: FunctionComponent<
  PropsWithChildren<GateLayoutProps>
> = ({ children, brand }) => {
  return (
    <div className="flex w-full flex-col">
      <nav className="flex h-16 items-center justify-center bg-white shadow-sm">
        {brand}
      </nav>
      <div className="flex-1 overflow-auto">
        <main className="flex flex-1 items-center justify-center overflow-auto p-4">
          <div className="max-w-512 space-y-4 w-full">{children}</div>
        </main>
      </div>
    </div>
  );
};
