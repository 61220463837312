import { AnchorHTMLAttributes } from 'react';

type Booleanish = boolean | 'true' | 'false';

export const dataAttr = (condition: boolean | undefined) =>
  (condition ? '' : undefined) as Booleanish;

export const composeExternalLinkAttributes = ({
  external,
  target: targetOption,
  rel: relOption,
}: Pick<AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel'> & {
  external?: boolean;
}) => {
  const target = external ? (targetOption ?? '_blank') : targetOption;
  const rel =
    external && !targetOption
      ? (relOption ?? 'noopener noreferrer')
      : relOption;

  return {
    target,
    rel,
  } as const;
};
