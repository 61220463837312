import { FunctionComponent, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone-esm';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { Blob } from '@pm/upload';
import clsx from 'clsx';
import { useEnvironment } from '../../hooks/env';
import { Uploader } from './Uploader';
import { UploadingProgressBar } from './UploadingProgressBar';

export interface UploadAttachmentType extends File {
  blob?: Blob;
  key?: string;
}

type UploadAttachmentProps = {
  attachment: UploadAttachmentType;
  onRemove: () => void;
  error?: string;
};

const UploadAttachment: FunctionComponent<UploadAttachmentProps> = ({
  attachment,
  onRemove,
  error,
}) => {
  const [progress, setProgress] = useState(0);
  const { directUploadsUrl } = useEnvironment();

  useEffect(() => {
    const uploader = new Uploader(attachment, directUploadsUrl, setProgress);
    uploader.upload((blob) => {
      attachment.blob = blob;
    });
  }, [attachment, directUploadsUrl]);

  return (
    <li
      className={clsx(
        'col-span-1 flex rounded-md shadow-sm',
        error && 'overflow-hidden ring-1 ring-red-600',
      )}
    >
      <div className="bg-grey-200 text-grey-700 mt-2 flex w-full justify-between rounded-md px-4 py-2">
        {attachment.name}
        <div className="flex justify-end items-center w-full gap-4">
          <UploadingProgressBar
            fileName={attachment.name}
            progress={progress * 100}
          />
          <button type="button" className="px-1" onClick={onRemove}>
            <XMarkIcon className="h-5" />
          </button>
        </div>
      </div>
    </li>
  );
};

type AttachmentUploaderProps = {
  name: string;
  label?: string;
  placeholder?: string;
  attachments: UploadAttachmentType[];
  setAttachments: (attachments: UploadAttachmentType[]) => void;
  uploadBoxClassName?: string;
};

export const HookFormAttachmentUploader: FunctionComponent<
  AttachmentUploaderProps
> = ({
  name,
  attachments,
  setAttachments,
  label,
  placeholder,
  uploadBoxClassName,
}) => {
  const { getFieldState, formState, setValue } = useFormContext();
  const { error } = getFieldState(name, formState);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: true,
    onDrop: async (acceptedFiles: UploadAttachmentType[]) => {
      acceptedFiles.forEach(
        (file) => (file.key = `${file.name}-${Date.now()}`),
      );
      const newAttachments = [...attachments, ...acceptedFiles];
      setAttachments(newAttachments);
      setValue(name, newAttachments);
    },
  });

  return (
    <div>
      {label && (
        <label
          htmlFor={name}
          className="text-grey-500 mb-0 text-sm font-medium"
        >
          {label}
        </label>
      )}
      <div
        {...getRootProps()}
        className={clsx(
          'border-grey-500 bg-grey-50 flex h-12 flex-col justify-center rounded-lg border-2 border-dashed p-1 text-center text-sm',
          uploadBoxClassName,
          isDragActive && 'border-green-500 text-green-500',
          error && 'border-red-600',
        )}
      >
        <div className="text-grey-700 text-sm font-medium">{placeholder}</div>
        <input {...getInputProps()} id="attachments" data-testid="dropzone" />
      </div>
      {error && <p className="text-red-600 mt-2 text-sm">{error.message}</p>}
      <ul>
        {attachments.map((attachment, index) => (
          <div key={index}>
            <UploadAttachment
              attachment={attachment}
              onRemove={() => {
                const newAttachments = attachments.filter(
                  (a) => a !== attachment,
                );
                setAttachments(newAttachments);
              }}
            />
          </div>
        ))}
      </ul>
    </div>
  );
};
