import { zodResolver } from '@hookform/resolvers/zod';
import {
  FormError,
  FormRadioGroup,
  FormSubmitButton,
  freeTextPrefixForOption,
} from '@pm/forms';
import {
  PatientAppointmentPartsFragment,
  PlatformSettingsFragment,
} from '@pm/graphql';
import { SingleSelect, Text, TextLink } from '@pm/ui';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { z } from 'zod';
import { ConfirmWithModal } from '../../../components/ConfirmWithModal/ConfirmWithModal';
import { useAppointmentCancellationReasons } from '../../../hooks/use-appointment-cancellation-reasons/use-appointment-cancellation-reasons';
import { dollars } from '../../../utilities/currency';
import { toServerError } from '../../../utilities/errors';
import { SubmitProps } from './cancel.types';

type CancelAppointmentFormProps = {
  appointment: PatientAppointmentPartsFragment;
  platformSettings: PlatformSettingsFragment;
  supportLink: string;
  onSubmit?: (props: SubmitProps) => Promise<void>;
};
export const CancelAppointmentForm = ({
  appointment,
  platformSettings,
  supportLink,
  onSubmit,
}: CancelAppointmentFormProps) => {
  const { t } = useTranslation(['appointments', 'global']);
  const {
    reasons: cancellationReasons,
    freeTextReasons,
    freeTextDelimiter,
  } = useAppointmentCancellationReasons({});

  const schema = z
    .object({
      reason: z.string().min(1, t('global:Required')),
    })
    .refine(
      ({ reason }) => {
        if (reason === '') {
          return false;
        }

        const hasEmptyFreeText = freeTextReasons.some(
          (option) =>
            reason.trim() ===
            freeTextPrefixForOption({ option, freeTextDelimiter }).trim(),
        );
        return !hasEmptyFreeText;
      },
      {
        message: t('global:Required'),
        path: ['reason'],
      },
    );

  const methods = useForm({
    resolver: zodResolver(schema),
    defaultValues: { reason: '' },
    mode: 'all',
  });

  const lateCancellationFeeInDollars = dollars.fromCents(
    appointment.lateCancellationPrice?.amountInCents ?? 0,
  );
  const hasLateCancellationFee = lateCancellationFeeInDollars !== 0;

  const handleSubmit: SubmitHandler<z.infer<typeof schema>> = async ({
    reason,
  }) => {
    await onSubmit?.({
      reason,
      onError: () =>
        methods.setError(...toServerError('Unable to cancel appointment')),
    });
  };

  const cancelKey = 'appointments:ManageAppointment.Cancel';

  return (
    <ConfirmWithModal
      ariaLabel={t(`${cancelKey}.ConfirmationDialog.DialogDescription`)}
      headingText={t(`${cancelKey}.ConfirmationDialog.Heading`)}
      bodyText={t(`${cancelKey}.ConfirmationDialog.LateFeeContext`, {
        fee: lateCancellationFeeInDollars,
        cancelThreshold: platformSettings.cancellationThresholdHours,
      })}
      affirmText={t(`${cancelKey}.ConfirmationDialog.Confirm`)}
      cancelText={t(`${cancelKey}.ConfirmationDialog.Cancel`)}
    >
      {({ confirm }) => (
        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(async (formValues) => {
              const confirmed = hasLateCancellationFee ? await confirm() : true;
              if (confirmed) {
                await handleSubmit(formValues);
              }
            })}
          >
            <div className="space-y-xxxl">
              <div className="space-y-l">
                <FormError
                  error={{
                    title: t('appointments:Appointment.Errors.Cancel'),
                    instructions: (
                      <Trans
                        ns="global"
                        i18nKey="Errors.ContactSupport.Instructions"
                      >
                        <TextLink to={supportLink} external />
                      </Trans>
                    ),
                  }}
                />
                <Text as="h1" size="headingS" id="cancellation-reason-label">
                  {t(`${cancelKey}.Heading`)}
                </Text>
                <FormRadioGroup
                  name="reason"
                  aria-labelledby="cancellation-reason-label"
                  space="m"
                  options={cancellationReasons}
                  freeTextDelimiter={freeTextDelimiter}
                  RadioComponent={SingleSelect}
                />
              </div>
              <div className="space-y-l">
                <FormSubmitButton width="full">
                  {t('appointments:ManageAppointment.Cancel.Actions.Confirm')}
                </FormSubmitButton>
                {hasLateCancellationFee && (
                  <Text size="label">
                    {t('ManageAppointment.Manage.Policies.LateCancelationFee', {
                      price: lateCancellationFeeInDollars,
                      cancelThreshold:
                        platformSettings.cancellationThresholdHours,
                    })}
                  </Text>
                )}
              </div>
            </div>
          </form>
        </FormProvider>
      )}
    </ConfirmWithModal>
  );
};
