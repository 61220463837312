import clsx from 'clsx';
import { ElementRef, forwardRef, useId } from 'react';
import { CardText } from '../CardText';
import { StyledInput } from '../forms/StyledInput';

type DateInputElement = ElementRef<'input'>;

type DateInputElementProps = React.HTMLProps<HTMLInputElement> & {
  label?: string;
  description?: string;
  helpText?: string;
  error?: string;
  value?: string;
};

export const DateInput = forwardRef<DateInputElement, DateInputElementProps>(
  (
    {
      label,
      description,
      helpText,
      error,
      value,
      required,
      disabled,
      id: idProp,
      ...props
    },
    forwardedRef,
  ) => {
    const _id = useId();
    const id = idProp || _id;

    return (
      <div className="space-y-2">
        <div className="space-y-4">
          {label && (
            <label
              htmlFor={id}
              className={clsx('text-gray-900', {
                "after:ml-1 after:text-red-500 after:content-['*']": required,
              })}
            >
              {label}
            </label>
          )}
          {description && <CardText>{description}</CardText>}
          <StyledInput
            {...props}
            type="date"
            defaultValue={value}
            id={id}
            disabled={disabled}
            aria-invalid={error != null}
            aria-disabled={disabled}
            ref={forwardedRef}
          />
          {helpText && (
            <CardText className="text-grey-500 text-xs font-medium">
              {helpText}
            </CardText>
          )}
        </div>
        {error && (
          <p className="text-critical-dark border-none text-sm font-medium">
            {error}
          </p>
        )}
      </div>
    );
  },
);

DateInput.displayName = 'DateInput';
