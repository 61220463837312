import clsx from 'clsx';
import { isValidPhoneNumber } from 'react-phone-number-input';
import * as yup from 'yup';
import { useInternalId } from '../../hooks/use-internal-id/useInternalId';
import { StyledInput } from '../forms/StyledInput';
import { forwardRef } from 'react';
import Input from 'react-phone-number-input/input';

const DEFAULT_COUNTRY_CODE = 'CA' as const;
type PhoneNumberInputProps = React.HTMLProps<HTMLInputElement> & {
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  dataCy?: string;
  error?: string;
  onChange?(value: string): void;
};

export const PhoneNumberInputImpl = forwardRef<
  HTMLInputElement,
  PhoneNumberInputProps
>(
  (
    {
      name,
      label,
      dataCy,
      autoComplete,
      required = false,
      id: idProp,
      error,
      ...props
    },
    ref,
  ) => {
    const id = useInternalId(idProp);

    return (
      <div className="space-y-2">
        {label && (
          <label
            htmlFor={id}
            className={clsx('text-grey-500 text-sm font-medium', {
              "after:ml-1 after:text-red-500 after:content-['*']": required,
            })}
          >
            {label}
          </label>
        )}
        {/* @ts-expect-error lib uses a branded type */}
        <Input
          {...props}
          onChange={(value) => props.onChange?.(value || '')}
          name={name}
          inputComponent={StyledInput}
          defaultCountry={DEFAULT_COUNTRY_CODE}
          useNationalFormatForDefaultCountryValue
          id={id}
          type="tel"
          required={required}
          autoComplete={autoComplete ?? 'tel-national'}
          data-cy={dataCy}
          aria-label={label ? undefined : props['aria-label']} // TODO: Remove this once we properly handle labels as name shouldn't be announced to screen readers
          aria-invalid={error ? true : undefined}
          ref={ref}
        />
        {error && (
          <p className="text-critical-dark border-none text-sm font-medium">
            {error}
          </p>
        )}
      </div>
    );
  },
);

PhoneNumberInputImpl.displayName = 'PhoneNumberInputImpl';

export const yupPhoneNumberValidationSchema = (translations: {
  required?: string | (() => string);
  invalid: string | (() => string);
}) => {
  const schema = yup
    .string()
    .transform((value) => (value ? value : ''))
    .test('valid-phone', translations.invalid, (value) => {
      return value ? isValidPhoneNumber(value, DEFAULT_COUNTRY_CODE) : true;
    });

  if (translations.required) {
    return schema.required(translations.required);
  }

  return schema;
};
