import { QuestionnaireNumberInputQuestion } from '@pm/graphql';
import i18n from 'i18next';
import * as Yup from 'yup';
import { REQUIRED_MESSAGE } from './sharedSchemas';

export const numberInputQuestionSchema = ({
  max,
  min,
  allowsDecimals,
  required,
}: QuestionnaireNumberInputQuestion) =>
  Yup.number()
    .transform((value, originalValue) => {
      if (originalValue === '') {
        return null;
      }

      return value;
    })
    .typeError(() => i18n.t('Validations.Number.Invalid', { ns: 'global' }))
    .nullable()
    .when([], {
      is: () => required,
      then: (schema) => schema.required(REQUIRED_MESSAGE),
    })
    .when([], {
      is: () => min != null,
      then: (schema) =>
        schema.min(Number(min), () =>
          i18n.t('Validations.Number.Minimum', { ns: 'global', number: min }),
        ),
    })
    .when([], {
      is: () => max != null,
      then: (schema) =>
        schema.max(Number(max), () =>
          i18n.t('Validations.Number.Maximum', { ns: 'global', number: max }),
        ),
    })
    .when([], {
      is: () => Boolean(!allowsDecimals),
      then: (schema) =>
        schema.integer(() =>
          i18n.t('Validations.Number.InvalidWhole', { ns: 'global' }),
        ),
    });
