import {
  StartQuestionnaireMutation,
  useStartQuestionnaireMutation,
} from '@pm/graphql';
import { ComponentProps } from 'react';
import { QuestionnaireWorkflowStepCard } from './QuestionnaireWorkflowStepCard';
import { MutationHookOptions } from '@apollo/client';
import { trackEvent } from '@pm/analytics';

export const useQuestionnaire = ({
  definitionName,
  workflowStepId,
  onError,
  onSuccess,
}: Pick<
  ComponentProps<typeof QuestionnaireWorkflowStepCard>,
  'workflowStepId' | 'definitionName'
> & {
  onError: MutationHookOptions<StartQuestionnaireMutation>['onError'];
  onSuccess: (patientResponseId: string) => void;
}) => {
  const [startQuestionnaire, { loading }] = useStartQuestionnaireMutation({
    variables: {
      input: { definitionName, workflowStepId },
    },
    onCompleted(data) {
      if (!data.startQuestionnaire?.patientResponse?.id) return;

      trackEvent(`${definitionName} Started`, {
        responseId: data.startQuestionnaire.patientResponse.id,
        workflowStepId: workflowStepId,
      });

      onSuccess?.(data.startQuestionnaire.patientResponse.id);
    },
    onError,
  });

  return { loading, startQuestionnaire };
};
