import { PropsWithChildren, ReactNode } from 'react';

type EmptyStateProps = PropsWithChildren<{
  heading: ReactNode;
  description?: ReactNode;
  icon?: ReactNode;
}>;

export function EmptyState({
  heading,
  description,
  icon,
  children,
}: EmptyStateProps) {
  return (
    <div className="flex flex-col items-center space-y-4 p-4 text-center">
      {icon && <div className="text-grey-500 h-14 w-14">{icon}</div>}
      <h3 className="text-lg font-medium">{heading}</h3>
      {description && <p className="text-grey-500">{description}</p>}
      {children}
    </div>
  );
}
