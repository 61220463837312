const stripSearchParamFromUrl = (url: string, searchParam: string) => {
  const strippedUrl = new URL(url);

  const searchParams = new URLSearchParams(strippedUrl.search);
  searchParams.delete(searchParam);
  strippedUrl.search = searchParams.toString();

  return strippedUrl.toString();
};

/**
 * Returns a URL with the disposition search parameter removed so that
 * the URL can be used to download content inline.
 *
 * @param {string} url URL to remove the search param from
 * @returns {string} URL with the disposition search param removed
 */
export const stripDispositionFromUrl = (url: string) =>
  stripSearchParamFromUrl(url, 'disposition');

/**
 * Builds a URL with query parameters added or updated.
 *
 * This function takes a base URL and an object containing key-value pairs
 * of query parameters. It then adds these parameters to the URL or updates
 * them if they already exist.
 *
 * @param {string} url - The base URL to which query parameters will be added.
 * @param {Record<string, string>} queryStrings - An object where keys are query parameter names and values are their corresponding values.
 * @returns {string} A new URL string with the specified query parameters added or updated.
 *
 * @example
 * const baseUrl = 'https://example.com/path';
 * const params = { key1: 'value1', key2: 'value2' };
 * const result = buildQueryParamUrl(baseUrl, params);
 * // result: 'https://example.com/path?key1=value1&key2=value2'
 */
export const buildQueryParamUrl = (
  url: string,
  queryStrings?: Record<string, string>,
) => {
  if (!queryStrings) {
    return url;
  }

  const urlObject = new URL(url);
  const searchParams = new URLSearchParams(urlObject.search);

  Object.entries(queryStrings).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  urlObject.search = searchParams.toString();
  return urlObject.toString();
};
