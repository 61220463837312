import { forwardRef } from 'react';
import { type VariantProps, cva } from 'class-variance-authority';
import * as Select from '@radix-ui/react-select';
import { Icon } from '../../Icon/Icon';

const dropdownItem = cva(
  'bg-surface-strong rounded-xs hover:bg-primary hover:text-content-inverse p-xs hover:cursor-pointer items-center outline-none flex p-xs gap-x-xs',
);

type DropdownItemProps = VariantProps<typeof dropdownItem> & {
  value: string;
  text: string;
  disabled?: boolean;
};

/**
 * To be used within a Dropdown or DropdownGroup component.
 *
 * @components
 * ItemText ==> Visual text part of the item. Should not be styled to ensure correct positioning.
 * ItemIndicator ==> Renders when the item is selected.
 */
const DropdownItem = forwardRef<HTMLDivElement, DropdownItemProps>(
  ({ text, ...props }, forwardedRef) => (
    <Select.Item ref={forwardedRef} className={dropdownItem()} {...props}>
      <div className="flex items-center justify-center">
        <Select.ItemIndicator className="inline-flex align-middle">
          <Icon name="check" size="small" />
        </Select.ItemIndicator>
      </div>
      <Select.ItemText>{text}</Select.ItemText>
    </Select.Item>
  ),
);
DropdownItem.displayName = 'DropdownItem';

export { DropdownItem };
