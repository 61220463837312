import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { trackPageView } from '@pm/analytics';

type AnalyticsPageTrackingProps = {
  enableGoogleAnalyticsTracking?: boolean;
};

export const AnalyticsPageTracking = ({
  enableGoogleAnalyticsTracking,
}: AnalyticsPageTrackingProps) => {
  const location = useLocation();

  useEffect(() => {
    if (enableGoogleAnalyticsTracking) {
      const url = new URL(window.location.href);
      url.pathname = location.pathname;
      trackPageView({
        type: 'google',
        location: url.href,
        pathname: url.pathname,
      });
    } else {
      trackPageView({ type: 'segment' });
    }
  }, [enableGoogleAnalyticsTracking, location]);

  return null;
};
