import { ApolloQueryResult } from '@apollo/client';
import {
  AppointmentFragment,
  GetMePatientQuery,
  useUpdatePatientMutation,
} from '@pm/graphql';
import { isBefore, parseISO, sub } from 'date-fns';
import { usePatientProfileContext } from '../../providers/PatientProfileProvider';
import { useProfileContext } from '../../providers/ProfileProvider';
import { BRAND_NAME } from '../../types';
import { nonNullable } from '../../utilities/boolean';

enum Role {
  Patient = 'PATIENT',
  Clinician = 'CLINICIAN',
  CX = 'CX',
  MOA = 'MOA',
  SuperUser = 'ADMIN',
  Pharmacy = 'PHARMACY',
}

export const useProfile = () => {
  const context = useProfileContext();

  if (!context) {
    throw 'This hook must be used inside a ProfileProvider';
  }

  const { profile, refetch, loading } = context;

  const providerRoles = [
    Role.Clinician,
    Role.CX,
    Role.MOA,
    Role.SuperUser,
    Role.Pharmacy,
  ];

  return {
    profile: profile,
    isPatient: profile?.role.code === Role.Patient,
    isClinician: profile?.role.code === Role.Clinician,
    isCx: profile?.role.code === Role.CX,
    isMoa: profile?.role.code === Role.MOA,
    isSuperuser: profile?.role.code === Role.SuperUser,
    isPharmacy: profile?.role.code === Role.Pharmacy,
    get isProvider() {
      return providerRoles.includes(profile?.role.code as Role);
    },
    get isNonClinicianProvider() {
      return this.isProvider && !this.isClinician;
    },
    userId: profile?.id,
    roleId: profile?.role.id,
    name: `${profile?.firstName} ${profile?.lastName}`,
    chosenName: profile?.preferredName,
    refetch,
    loading,
  };
};

export const usePatientProfile = () => {
  const context = usePatientProfileContext();

  if (!context) {
    throw 'This hook must be used inside a PatientProfileProvider';
  }

  const { profile, refetch, loading } = context;
  return {
    profile: profile,
    userId: profile?.id,
    name: `${profile?.firstName} ${profile?.lastName}`,
    province: profile?.profile?.province,
    chosenName: profile?.preferredName,
    isUsaPatient:
      profile?.currentBrandMembership?.name?.toLowerCase() ===
      BRAND_NAME.FreddieUSA,
    refetch,
    loading,
  };
};

const getUpcomingAppointments = (appointments: AppointmentFragment[]) => {
  const chronologicalDates = appointments.sort((a, b) =>
    (a.scheduledAt ?? '').localeCompare(b.scheduledAt ?? ''),
  );

  return chronologicalDates.filter((appointment) =>
    isBefore(
      sub(new Date(), { hours: 1 }),
      parseISO(appointment.scheduledAt ?? ''),
    ),
  );
};

export const useGetUpcomingAppointments = (): {
  nextUpcomingAppointment: AppointmentFragment | undefined;
  upcomingAppointments: AppointmentFragment[];
  refetch: () => Promise<ApolloQueryResult<GetMePatientQuery>>;
  loading: boolean;
} => {
  const context = usePatientProfile();
  const { profile, refetch, loading } = context;
  const appointments = profile?.appointments.nodes?.filter(nonNullable) || [];
  const upcomingAppointments = getUpcomingAppointments(appointments);
  const nextUpcomingAppointment = upcomingAppointments[0];

  return {
    nextUpcomingAppointment,
    upcomingAppointments,
    refetch,
    loading,
  };
};

export const useUpdatePatientProfile: typeof useUpdatePatientMutation = (
  baseOptions,
) => useUpdatePatientMutation(baseOptions);
