const ignoreErrors = [
  'Non-Error exception',
  'Loading chunk',
  'ResizeObserver loop limit exceeded',
  'Unexpected token < in JSON at position 0',
  'SecurityError: Blocked a frame with origin',
  'Type',
  'Illegal invocation',
  'Non-Error exception captured',
  'Non-Error promise rejection captured',
  'LaunchDarklyFlagFetchError',
  'Response not successful: Received status code 401',
  "Unexpected token '='",
  'A network error occurred.',
  "Can't find variable: __AutoFillPopupClose__",
  "Failed to read the 'localStorage' property from 'Window': Access is denied for this document",
];

export const config = {
  ignoreErrors,
};
