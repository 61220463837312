import { useTranslation } from 'react-i18next';
import { Text } from '@pm/ui';

type WelcomeMessageProps = {
  preferredName: string | null;
};

export const WelcomeMessage = ({ preferredName }: WelcomeMessageProps) => {
  const { t } = useTranslation('treatments', {
    keyPrefix: 'TreatmentOverview.WelcomeMessage',
  });

  return (
    <div className="flex-col space-y-xxxl">
      <Text size="headingL">{`${t('Title')}${preferredName ? `, ${preferredName}` : ''}`}</Text>
    </div>
  );
};
